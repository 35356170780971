import { useMemo } from 'react';

import HistoricPurchaseOrdersHeader from './components/HistoricPurchaseOrdersHeader';
import PurchaseOrdersGrid from './grids/PurchaseOrdersGrid';

function HistoricPurchaseOrders() {
  return (
    <>
      <HistoricPurchaseOrdersHeader />
      <PurchaseOrdersGrid
        overlayNoRowsTemplate="No purchase orders."
        sx={useMemo(() => ({ height: '100%' }), [])}
      />
    </>
  );
}

export default HistoricPurchaseOrders;
