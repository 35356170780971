import Box from '@mui/material/Box';

import type { DropdownOption } from 'shared/lib/types';

export default function renderOptionWithButton(
  buttonLabel: string,
  button: React.ReactNode,
) {
  function renderOption(
    renderProps: React.HTMLAttributes<HTMLLIElement>,
    option: DropdownOption<unknown>,
  ) {
    const { className, ...restProps } = renderProps;

    return option.label === buttonLabel ? (
      <Box
        key={option.label}
        component="li"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          pl: (theme) => `${theme.spacing(1)} !important`,
        }}
        {...restProps}
        className={className}
      >
        {button}
      </Box>
    ) : (
      <Box className={className} component="li" {...restProps}>
        {option.label}
      </Box>
    );
  }

  return renderOption;
}
