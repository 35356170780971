import { useEffect, useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getFxRatesTab } from 'routes';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import CurrencyDropdown from 'shared/lib/currency-dropdown/CurrencyDropdown';
import type { CurrencyCode, TraceId } from 'shared/lib/types';
import { CommentLocationType } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetRelevantFxCurrenciesQuery } from 'shared/api/rtkq/companies';

import CommentsButton from '../comments/CommentsButton';
import usePageCommentsCountLoader from '../comments/hooks/usePageCommentsCountLoader';
import TertiaryNavigationTabs from '../tabs/TertiaryNavigationTabs';
import FxRateGrid from './grids/FxRateGrid';
import { useGainLossEnabled } from './hooks/useFxRateColumnDefs';

type Props = {
  basePath: string;
  parentMenuItem?: TraceId;
};

function FxRate(props: Props) {
  const { basePath, parentMenuItem } = props;
  const navigate = useNavigate();
  const company = useSelector(selectCompany);
  const { currentData: currencyPairs } = useGetRelevantFxCurrenciesQuery(
    company.trace_id,
  );
  const { activeTab }: { activeTab?: CurrencyCode } = useParams();
  const showMonthEndRates = useGainLossEnabled();
  const [addedCurrencies, setAddedCurrencies] = useState<CurrencyCode[]>([]);

  const baseCurrencyTabs = Object.keys(currencyPairs ?? {}).map(
    (baseCurrency) => ({
      key: baseCurrency,
      label: baseCurrency,
    }),
  );

  useEffect(() => {
    // Set the active tab to the first base currency
    if (!activeTab && baseCurrencyTabs.length) {
      navigate(getFxRatesTab(basePath, baseCurrencyTabs[0].key));
    }
  }, [currencyPairs]);

  useEffect(() => {
    // Reset added currency columns when switching to a new base currency
    if (addedCurrencies.length) {
      setAddedCurrencies([]);
    }
  }, [activeTab, currencyPairs]);

  const currencyColumns = useMemo<CurrencyCode[]>(
    () =>
      activeTab
        ? [...(currencyPairs?.[activeTab] ?? []), ...addedCurrencies]
        : [],
    [currencyPairs, activeTab, addedCurrencies],
  );

  const handleAddCurrency = (currency: CurrencyCode) =>
    setAddedCurrencies((previousCurrencies) => [
      ...previousCurrencies,
      currency,
    ]);

  const generateUrl = (tabKey: string) => getFxRatesTab(basePath, tabKey);
  const disabledCurrencies = useMemo(
    () => [...(activeTab ? [activeTab] : []), ...currencyColumns],
    [currencyColumns, activeTab],
  );

  const gridSx = useMemo(() => ({ height: '100%', width: 'auto' }), []);
  const commentsCount = usePageCommentsCountLoader(CommentLocationType.FX_RATE);

  return (
    <PageContentLoadingContainer>
      <FlexGridContainer>
        <Stack flexDirection="row" justifyContent="space-between" mb={2}>
          <div>
            <Typography color="primary.main" variant="h4">
              FX Rates
            </Typography>
            <Typography
              alignItems="center"
              color="text.secondary"
              display="flex"
              variant="body1"
            >
              Add currencies and enter the FX rates you want to use for expense
              calculations. The average monthly rate is most commonly used.
              {showMonthEndRates && (
                <>
                  <br />
                  You may also add another rate for FX gain/loss calculation.
                  The month close rate is commonly used for this calculation.
                </>
              )}
            </Typography>
          </div>
          <CurrencyDropdown
            disabledCurrencies={disabledCurrencies}
            handleAddCurrency={handleAddCurrency}
          />
        </Stack>
        <TertiaryNavigationTabs
          activeTab={activeTab}
          generateUrl={generateUrl}
          tabs={baseCurrencyTabs}
        />
        <FxRateGrid
          baseCurrency={activeTab}
          inputCurrencies={currencyColumns}
          parentMenuItem={parentMenuItem}
          sx={gridSx}
        />
        <CommentsButton
          commentsCount={commentsCount}
          location={CommentLocationType.FX_RATE}
          commentsForPage
        />
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default FxRate;
