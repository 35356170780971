import { useMemo } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import Autocomplete from 'shared/ui/autocomplete/Autocomplete';
import renderOptionWithButton from 'shared/ui/autocomplete/renderOptionWithButton';
import Button, { buttonClasses } from 'shared/ui/button/Button';

import type {
  ContractCostCategoryInfoRequestParams,
  DropdownOption,
} from 'shared/lib/types';

import { NEW_GL_ACCOUNT_OPTION_VALUE } from './AccountingInfoForm';

type Props = {
  categoryType?: string;
  errors?: string;
  feesInfo: ContractCostCategoryInfoRequestParams | undefined;
  glAccountDropdownOptions: Array<DropdownOption<string>>;
  setShowNewGlAccountDialogFor: (category: string | null) => void;
  onGlAccountChange: (
    event: React.SyntheticEvent,
    value: DropdownOption<string> | null,
    category: string,
  ) => void;
};

function GlAccountSelector(props: Props) {
  const {
    glAccountDropdownOptions,
    feesInfo,
    errors,
    onGlAccountChange,
    setShowNewGlAccountDialogFor,
    categoryType,
  } = props;
  const renderOption = useMemo(
    () =>
      renderOptionWithButton(
        NEW_GL_ACCOUNT_OPTION_VALUE,
        <Button
          disabled={!categoryType}
          size="small"
          startIcon={<AddCircleOutlineIcon />}
          testId="add_gl_account"
          variant="text"
          sx={{
            [`&.${buttonClasses.root}`]: {
              justifyContent: 'start',
              py: (theme) => String(theme.spacing(2)),
              px: (theme) => String(theme.spacing(1)),
            },
          }}
          fullWidth
          onClick={() =>
            categoryType ? setShowNewGlAccountDialogFor(categoryType) : null
          }
        >
          Add G/L account
        </Button>,
      ),
    [categoryType, setShowNewGlAccountDialogFor],
  );

  return (
    <Autocomplete
      disabled={!categoryType}
      errorMsg={errors}
      label="G/L Account"
      options={glAccountDropdownOptions}
      renderOption={renderOption}
      value={
        glAccountDropdownOptions.find(
          (option) => option.value === feesInfo?.gl_account,
        ) ?? null
      }
      onChange={(event, value) =>
        categoryType ? onGlAccountChange(event, value, categoryType) : null
      }
    />
  );
}

export default GlAccountSelector;
