import { type ReactElement, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import LockGridReminder from 'shared/components/lock-grid/LockGridReminder';
import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import { getSiteLabCostMatrixTab } from 'routes';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import { LockGrid } from 'shared/lib/types';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import EditSite from './components/EditSite';
import SiteLabHeader from './components/SiteLabHeader';
import LabGrid from './grids/LabGrid';
import SiteGrid from './grids/SiteGrid';
import EditLab from './components/EditLab';

function SiteLabCostMatrixPage(): ReactElement {
  const { activeTab } = useParams();
  const sx = useMemo(() => ({ height: '100%' }), []);
  const tabContent = activeTab
    ? {
        sites: (
          <>
            <SiteGrid overlayNoRowsTemplate="No sites added." sx={sx} />
            <LockGridReminder grid={LockGrid.SITE_COST_MATRIX} />
          </>
        ),
        labs: (
          <>
            <LabGrid overlayNoRowsTemplate="No labs added." sx={sx} />
            <LockGridReminder grid={LockGrid.LAB_COST_MATRIX} />
          </>
        ),
      }[activeTab]
    : undefined;

  return (
    <PageContentLoadingContainer
      tabs={
        <NavigationTabs
          activeTab={activeTab}
          generateUrl={getSiteLabCostMatrixTab}
          tabs={[
            { key: 'sites', label: 'Sites' },
            {
              key: 'labs',
              label: 'Labs',
            },
          ]}
        />
      }
    >
      <FlexGridContainer>
        <SiteLabHeader activeTab={activeTab} />
        <EditSite />
        <EditLab />
        {tabContent}
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(SiteLabCostMatrixPage);
