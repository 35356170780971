import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import type { PatientJourneyRowResponse } from 'shared/lib/types';

import { useGetPatientJourneyQuery } from 'shared/api/rtkq/periods';

export function processPatientJourneyGrids(
  patientJourneyGridRows: PatientJourneyRowResponse[] | undefined,
  showExpectedVisits: boolean,
) {
  const rowData = cloneDeep(patientJourneyGridRows);

  return rowData?.map((eachRow: PatientJourneyRowResponse) => {
    const {
      visits,
      procedures,
      expected_visit_mapping: expectedVisits,
    } = eachRow;

    let updatedRow = {
      ...eachRow,
      ...visits,
      ...procedures,
    };

    if (showExpectedVisits) {
      updatedRow = {
        ...updatedRow,
        ...expectedVisits,
        visits_total:
          eachRow.visits_total + (eachRow.expected_visits_total ?? 0),
      };
    }

    return updatedRow;
  });
}

export default function usePatientJourneyGridRows(
  patientCohortTraceId: string | undefined,
  showExpectedVisits: boolean,
): PatientJourneyRowResponse[] | undefined {
  const period = useSelector(selectPeriod);
  const { currentData: patientJourneyData } = useGetPatientJourneyQuery(
    period.trace_id,
  );

  return useMemo(() => {
    if (patientJourneyData === undefined) {
      return undefined;
    }

    return processPatientJourneyGrids(
      patientJourneyData.grids?.find(
        (eachGrid) => eachGrid.cohort_id === patientCohortTraceId,
      )?.rows ?? [],
      showExpectedVisits,
    );
  }, [patientJourneyData, patientCohortTraceId, showExpectedVisits]);
}
