import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import Button from 'shared/ui/button/Button';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import { selectUser } from 'shared/state/slices/userSlice';

import { useGetPartnersQuery } from 'shared/api/rtkq/partners';

import type { PartnerResponse } from '../types';

type Props = {
  handleClick: (partner: PartnerResponse) => void;
};

function PartnerOptions(props: Props) {
  const { handleClick } = props;
  const currentUser = useSelector(selectUser);
  const partnerPermissionsEnabled = useFeatureFlag('partner_permissions');
  const { currentData: companyGroups, isLoading } = useGetPartnersQuery();
  const partnerTraceId = currentUser.partner_trace_id;
  const currentPartner = companyGroups?.find(
    (eachPartner: PartnerResponse) => eachPartner.trace_id === partnerTraceId,
  );

  // if partnerTraceId != null, then show the partner dashboard button
  // else if currentUser.is_superuser, then show the superuser options
  // else, return undefined
  return partnerPermissionsEnabled && partnerTraceId !== null ? (
    <Button
      color="primary"
      sx={{ mb: 2 }}
      testId="partner-dashboard-btn"
      variant="outlined"
      fullWidth
      onClick={() =>
        handleClick({
          trace_id: currentPartner?.trace_id,
          name: currentPartner?.name,
          companies: currentPartner?.companies,
        } as PartnerResponse)
      }
    >
      Partner Dashboard
    </Button>
  ) : currentUser.is_superuser ? (
    <Box>
      <Divider sx={{ mb: 2 }} />
      <Typography sx={{ fontWeight: 'bold' }} variant="inherit">
        PARTNERS
      </Typography>

      <List sx={{ p: 0, my: 2 }}>
        {isLoading ? (
          <Box gap={1} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Skeleton width={150} />
            <Skeleton width={120} />
            <Skeleton width={180} />
          </Box>
        ) : (
          <Box>
            {companyGroups?.map((group) => (
              <ListItem
                key={group.trace_id}
                sx={{ cursor: 'pointer', p: 0, my: 1 }}
                onClick={() =>
                  handleClick({
                    trace_id: group.trace_id,
                    name: group.name,
                    companies: group.companies,
                  })
                }
              >
                <Typography variant="inherit">{group.name}</Typography>
              </ListItem>
            ))}
          </Box>
        )}
      </List>
    </Box>
  ) : undefined;
}

export default PartnerOptions;
