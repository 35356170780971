import { useState } from 'react';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import { RecordStyle } from 'shared/lib/record-viewer/RecordViewer';
import TrialRegionsWizard from 'accruals/wizards/trial-regions/TrialRegionsWizard';
import HasPermission from 'shared/lib/permissions/HasPermission';

import EditButton from './EditButton';
import TrialRegionsCardContents from './TrialRegionsCardContents';

export default function TrialRegionsCard() {
  const [editModalOpen, setEditModalOpen] = useState(false);

  return (
    <>
      <DashboardCard
        title="Regions"
        titleAction={
          <HasPermission permissions={['canEditTrialInfo']}>
            <EditButton
              testId="edit_regions"
              onClick={() => setEditModalOpen(true)}
            />
          </HasPermission>
        }
        showDivider
      >
        <TrialRegionsCardContents recordStyle={RecordStyle.HORIZONTAL} />
      </DashboardCard>
      {editModalOpen && (
        <TrialRegionsWizard onClose={() => setEditModalOpen(false)} />
      )}
    </>
  );
}
