import type {
  LabCostGridResponse,
  TrialClosedPeriodValuesGridResponse,
  TrialFilesGridRow,
  TrialHistoricalValuesGridResponse,
  TrialRequest,
  TrialResponse,
} from '../../lib/types';
import { ADMINISTRATIVE_OR_PROCEDURE_CATEGORIES_TAG } from './administrativeorprocedurecategories';
import {
  ACCRUALS_GRID,
  BS_FLUX_GRID,
  CLOSED_PERIOD_EXPENSES,
  CRO_EXPENSE_SUMMARY,
  HISTORICAL_EXPENSES,
  JOURNAL_ENTRY_GRID,
  OCC_EXPENSE_SUMMARY,
  PATIENT_ACTIVITY_GRID,
  PERIOD_OCC_EXPENSES,
} from './apiTags';
import constructApi from './constructApi';
import {
  CONTRACT_INVESTIGATOR_FEES,
  CONTRACT_INVESTIGATOR_FEES_RECON,
  CONTRACT_RECON,
} from './contracts';
import { USERS_TAG } from './users';

const PATH = 'trials';
export const TRIAL_FILES = 'TRIAL_FILES';
export const LAB_COST_MATRIX = 'LAB_COST_MATRIX';
export const TRIALS_TAG = 'TRIALS';
const TRIALS_OTHER_TAGS = [
  LAB_COST_MATRIX,
  ADMINISTRATIVE_OR_PROCEDURE_CATEGORIES_TAG,
  CONTRACT_INVESTIGATOR_FEES,
  CONTRACT_RECON,
  CONTRACT_INVESTIGATOR_FEES_RECON,
  PERIOD_OCC_EXPENSES,
  ACCRUALS_GRID,
  JOURNAL_ENTRY_GRID,
  BS_FLUX_GRID,
  CRO_EXPENSE_SUMMARY,
  OCC_EXPENSE_SUMMARY,
  PATIENT_ACTIVITY_GRID,
  HISTORICAL_EXPENSES,
  CLOSED_PERIOD_EXPENSES,
  TRIAL_FILES,
];

type PreparerAndReviewerRequest = {
  trace_id?: string;
  preparer?: string | null;
  reviewer?: string | null;
};

const apiEndpointFactory = constructApi<TrialResponse, TrialRequest>(
  PATH,
).withTags(TRIALS_TAG, TRIALS_OTHER_TAGS);
export const api = apiEndpointFactory.inject(() => ({
  getTrial: apiEndpointFactory.get(),
  getTrialsByCompany: apiEndpointFactory.getBy('company'),
  getTrialsByPartner: apiEndpointFactory.getBy('company_group'),
  getLabCosts: apiEndpointFactory.getExtraRoute<LabCostGridResponse>(
    'lab_costs',
    [LAB_COST_MATRIX],
  ),
  getHistoricalExpenses:
    apiEndpointFactory.getExtraRoute<TrialHistoricalValuesGridResponse>(
      'historical_expenses',
      [HISTORICAL_EXPENSES],
    ),
  getClosedPeriodExpenses:
    apiEndpointFactory.getExtraRoute<TrialClosedPeriodValuesGridResponse>(
      'closed_period_expenses',
      [CLOSED_PERIOD_EXPENSES],
    ),
  getTrialFiles: apiEndpointFactory.getExtraRoute<{
    rows: TrialFilesGridRow[];
  }>('trial_files', [TRIAL_FILES]),
  updateTrial: apiEndpointFactory.update(),
  updatePreparerAndReviewer:
    apiEndpointFactory.update<PreparerAndReviewerRequest>(
      [USERS_TAG, TRIALS_TAG],
      'update_preparer_and_reviewer',
    ),
  updatePatientJourneys: apiEndpointFactory.update(
    [],
    'update_patient_journeys',
  ),
}));

export const {
  useGetTrialsByCompanyQuery,
  useGetTrialsByPartnerQuery,
  useGetHistoricalExpensesQuery,
  useGetClosedPeriodExpensesQuery,
  useGetLabCostsQuery,
  useLazyGetLabCostsQuery,
  useGetTrialFilesQuery,
  useLazyGetTrialFilesQuery,
  useUpdateTrialMutation,
  useUpdatePatientJourneysMutation,
  useUpdatePreparerAndReviewerMutation,
  useGetTrialQuery,
} = api;
