import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';

import { validateEdcVisitMapping } from 'shared/helpers/validators';
import type {
  PatientAssessment,
  PatientAssessmentEditor,
  PatientAssessmentTimelinePortionEnum,
  PatientCohort,
  PatientCohortEditor,
  PatientCohortErrors,
  TraceId,
  VisitType,
} from 'shared/lib/types';
import type { RootState } from 'shared/state/store';

type CohortNameEditor = 'edit' | 'new' | null;

type State = {
  cohortNameEditor: CohortNameEditor;
  cohort: PatientCohortEditor | null;
  cohorts: PatientCohortEditor[];
  cohortErrors: PatientCohortErrors;
};

const initialState: State = {
  cohortNameEditor: null,
  cohort: null,
  cohorts: [],
  cohortErrors: {},
};

const visitSchedulesSlice = createSlice({
  name: 'visitSchedules',
  initialState,
  reducers: {
    resetState: () => initialState,
    setCohorts: (
      state,
      action: PayloadAction<{ cohorts: PatientCohort[] }>,
    ) => {
      const cohorts: PatientCohortEditor[] = action.payload.cohorts.map(
        (cohort, cohortIndex) => {
          const isNew = !cohort.traceId;
          const visits: PatientAssessmentEditor[] =
            cohort.patientAssessments.map((visit, visitIndex) => {
              const dayOfProtocol = getDayOfProtocol(
                visit.dayOfProtocol,
                mapVisitType(visit),
              );
              return {
                ...visit,
                dayOfProtocol,
                traceId: isNew ? visitIndex.toString() : visit.traceId,
                isNew,
              };
            });

          return {
            ...cohort,
            patientAssessments: sortBy(visits, 'orderIndex'),
            traceId: isNew ? cohortIndex.toString() : cohort.traceId,
            isNew,
          };
        },
      );

      state.cohorts = sortBy(cohorts, 'orderIndex');
      if (state.cohorts.length) {
        state.cohort = cohorts[0];
      }
    },
    magicMapping: (
      state,
      action: PayloadAction<{
        uniqueEdcNames: { cohorts: string[]; visits: string[] } | undefined;
      }>,
    ) => {
      const { uniqueEdcNames } = action.payload;
      const { cohorts: copyCohorts, cohort: currentCohort } = state;
      let isModified = false;

      for (const [cohortIndex, cohort] of copyCohorts.entries()) {
        if (!cohort.mappedName) {
          const magicMappingValue = uniqueEdcNames?.cohorts.find(
            (edcCohortName) => edcCohortName === cohort.name,
          );
          if (magicMappingValue) {
            copyCohorts[cohortIndex].mappedName = magicMappingValue;
            copyCohorts[cohortIndex].isMagicMapped = true;
            isModified = true;
          }
        }

        for (const [
          visitIndex,
          patientAssessment,
        ] of cohort.patientAssessments.entries()) {
          if (!patientAssessment.mappedName) {
            const magicMappingValue = uniqueEdcNames?.visits.find(
              (edcVisitName) => edcVisitName === patientAssessment.name,
            );

            if (magicMappingValue) {
              copyCohorts[cohortIndex].patientAssessments[
                visitIndex
              ].mappedName = magicMappingValue;
              copyCohorts[cohortIndex].patientAssessments[
                visitIndex
              ].isMagicMapped = true;
              isModified = true;
            }
          }
        }
      }

      if (isModified) {
        const updatedCurrentCohort =
          currentCohort &&
          copyCohorts.find(
            (cohort) => cohort.traceId === currentCohort.traceId,
          );
        if (updatedCurrentCohort) {
          state.cohort = updatedCurrentCohort;
        }
        state.cohorts = copyCohorts;
      }
    },
    changeCohort: (
      state,
      action: PayloadAction<{ cohort: PatientCohortEditor }>,
    ) => {
      state.cohort = action.payload.cohort;
      state.cohortNameEditor = null;
    },
    updateNameInCohort: (state, action: PayloadAction<{ name: string }>) => {
      const { name } = action.payload;
      const { cohort: currentCohort } = state;
      if (!currentCohort) {
        return;
      }

      const cohort = state.cohorts.find(
        (cohort) => cohort.traceId === currentCohort.traceId,
      );
      if (cohort) {
        cohort.name = name;
        cohort.isEdited = true;
        state.cohort = cohort;
      }

      state.cohortNameEditor = null;
    },
    insertCohort(
      state,
      action: PayloadAction<{
        name: string;
        traceId: TraceId;
        trialId: TraceId;
      }>,
    ) {
      const { name, traceId, trialId } = action.payload;
      state.cohorts.push({
        traceId,
        name,
        patientAssessments: [],
        trialId,
        orderIndex: state.cohorts.length,
        isNew: false,
      });
      state.cohortNameEditor = null;
      state.cohort = state.cohorts[state.cohorts.length - 1];
    },
    removeCohort: (state) => {
      const { cohort: currentCohort } = state;

      const index = state.cohorts.findIndex(
        (cohort) => cohort.traceId === currentCohort?.traceId,
      );
      state.cohorts.splice(index, 1);
      state.cohortNameEditor = null;

      if (state.cohorts.length) {
        state.cohort = state.cohorts[Math.max(index - 1, 0)];
      } else {
        state.cohort = null;
      }
    },
    insertDuplicatedCohort: (
      state,
      action: PayloadAction<{
        sourceCohort: PatientCohortEditor;
        newCohort: PatientCohort;
      }>,
    ) => {
      const { sourceCohort, newCohort } = action.payload;
      const index = state.cohorts.findIndex(
        (cohort) => cohort.traceId === sourceCohort.traceId,
      );
      if (index === -1) {
        return;
      }

      state.cohorts.splice(index + 1, 0, {
        ...newCohort,
        isNew: false,
        patientAssessments: newCohort.patientAssessments.map((visit) => {
          const dayOfProtocol = getDayOfProtocol(
            visit.dayOfProtocol,
            mapVisitType(visit),
          );
          return {
            ...visit,
            isNew: false,
            dayOfProtocol,
          };
        }),
      });
    },
    closeCohortNameEditor: (state) => {
      state.cohortNameEditor = null;
    },
    showCohortNameEditor: (
      state,
      action: PayloadAction<{ editor: CohortNameEditor }>,
    ) => {
      state.cohortNameEditor = action.payload.editor;
    },
    insertVisit: (
      state,
      action: PayloadAction<{
        visit: PatientAssessment;
        afterVisitTraceId?: TraceId;
      }>,
    ) => {
      const { visit, afterVisitTraceId } = action.payload;
      const { cohort: currentCohort } = state;

      const cohort = state.cohorts.find(
        (cohort) => cohort.traceId === currentCohort?.traceId,
      );
      if (cohort) {
        let index = 0;
        if (afterVisitTraceId) {
          index = cohort.patientAssessments.findIndex(
            (item) => item.traceId === afterVisitTraceId,
          );
        }
        cohort.patientAssessments.splice(index + 1, 0, {
          ...visit,
          isNew: false,
        });

        for (let i = index + 1; i < cohort.patientAssessments.length; i++) {
          cohort.patientAssessments[i].orderIndex = i;
        }

        state.cohort = cohort;
      }
    },
    removeVisit: (
      state,
      action: PayloadAction<{ visit: PatientAssessmentEditor }>,
    ) => {
      const { visit } = action.payload;
      const { cohort: currentCohort } = state;

      const cohort = state.cohorts.find(
        (cohort) => cohort.traceId === currentCohort?.traceId,
      );
      if (cohort) {
        const index = cohort.patientAssessments.findIndex(
          (item) => item.traceId === visit.traceId,
        );
        cohort.patientAssessments.splice(index, 1);
        state.cohort = cohort;
      }
    },
    changeVisitType: (
      state,
      action: PayloadAction<{
        visit: PatientAssessmentEditor;
        value: VisitType;
      }>,
    ) => {
      const { visit, value } = action.payload;
      const { cohort: currentCohort } = state;

      const cohort = state.cohorts.find(
        (cohort) => cohort.traceId === currentCohort?.traceId,
      );
      if (cohort) {
        const index = cohort.patientAssessments.findIndex(
          (item) => item.traceId === visit.traceId,
        );
        const targetVisit = cohort.patientAssessments[index];
        targetVisit.isEnroll = value === 'isEnroll';
        targetVisit.isScreen = value === 'isScreen';
        targetVisit.isScreenfail = value === 'isScreenfail';
        targetVisit.isDroppedCompleted = value === 'isDroppedCompleted';
        targetVisit.dayOfProtocol = getDayOfProtocol(
          targetVisit.dayOfProtocol,
          value,
        );
        targetVisit.isEdited = true;
        state.cohort = cohort;
      }
    },
    changeVisitName: (
      state,
      action: PayloadAction<{ visit: PatientAssessmentEditor; name: string }>,
    ) => {
      const { visit, name } = action.payload;
      const { cohort: currentCohort } = state;

      const cohort = state.cohorts.find(
        (cohort) => cohort.traceId === currentCohort?.traceId,
      );
      if (cohort) {
        const index = cohort.patientAssessments.findIndex(
          (item) => item.traceId === visit.traceId,
        );
        const targetVisit = cohort.patientAssessments[index];
        targetVisit.name = name;
        targetVisit.isEdited = true;
        state.cohort = cohort;
      }
    },
    changeDayOfProtocol: (
      state,
      action: PayloadAction<{
        visit: PatientAssessmentEditor;
        dayOfProtocol: string;
      }>,
    ) => {
      const { visit, dayOfProtocol } = action.payload;
      const { cohort: currentCohort } = state;

      const cohort = state.cohorts.find(
        (cohort) => cohort.traceId === currentCohort?.traceId,
      );
      if (cohort) {
        const index = cohort.patientAssessments.findIndex(
          (item) => item.traceId === visit.traceId,
        );
        const targetVisit = cohort.patientAssessments[index];
        targetVisit.dayOfProtocol = Number.parseInt(dayOfProtocol);
        targetVisit.isEdited = true;
        state.cohort = cohort;
      }
    },
    toggleDayOfProtocol: (
      state,
      action: PayloadAction<{
        visit: PatientAssessmentEditor;
        checked: boolean;
      }>,
    ) => {
      const { visit, checked } = action.payload;
      const { cohort: currentCohort } = state;

      const cohort = state.cohorts.find(
        (cohort) => cohort.traceId === currentCohort?.traceId,
      );
      if (cohort) {
        const index = cohort.patientAssessments.findIndex(
          (item) => item.traceId === visit.traceId,
        );
        const targetVisit = cohort.patientAssessments[index];
        targetVisit.dayOfProtocol = checked ? 0 : null;
        targetVisit.isEdited = true;
        state.cohort = cohort;
      }
    },
    changeTimelinePortion: (
      state,
      action: PayloadAction<{
        visit: PatientAssessmentEditor;
        timelinePortion: PatientAssessmentTimelinePortionEnum;
      }>,
    ) => {
      const { visit, timelinePortion } = action.payload;
      const { cohort: currentCohort } = state;

      const cohort = state.cohorts.find(
        (cohort) => cohort.traceId === currentCohort?.traceId,
      );
      if (cohort) {
        const index = cohort.patientAssessments.findIndex(
          (item) => item.traceId === visit.traceId,
        );
        const targetVisit = cohort.patientAssessments[index];
        targetVisit.timelinePortion = timelinePortion;
        targetVisit.isEdited = true;
        state.cohort = cohort;
      }
    },
    changeEdcCohortMapping: (
      state,
      action: PayloadAction<{
        cohort: PatientCohortEditor;
        value: string | undefined;
      }>,
    ) => {
      const { cohort, value } = action.payload;
      const index = state.cohorts.findIndex(
        (item) => item.traceId === cohort.traceId,
      );
      state.cohorts[index] = { ...cohort, mappedName: value, isEdited: true };
      state.cohort!.mappedName = value;
    },
    changeEdcVisitMapping: (
      state,
      action: PayloadAction<{
        visit: PatientAssessmentEditor;
        value: string | undefined;
      }>,
    ) => {
      const { visit, value } = action.payload;
      const { cohort: currentCohort } = state;

      const cohort = state.cohorts.find(
        (cohort) => cohort.traceId === currentCohort?.traceId,
      );
      if (cohort) {
        const index = cohort.patientAssessments.findIndex(
          (item) => item.traceId === visit.traceId,
        );
        const targetVisit = cohort.patientAssessments[index];
        targetVisit.mappedName = value;
        targetVisit.isEdited = true;
        state.cohort = cohort;
      }
    },
    changeCohortsOrder: (
      state,
      action: PayloadAction<{ sourceIndex: number; destinationIndex: number }>,
    ) => {
      const { sourceIndex, destinationIndex } = action.payload;
      const { cohorts } = state;

      state.cohorts.forEach((_, index) => {
        if (cohorts[index].orderIndex !== index) {
          // this shouldn't happen usually, but some old cohorts may share the same order
          cohorts[index].orderIndex = index;
          cohorts[index].isEdited = true;
        }
      });

      const isAsc = sourceIndex < destinationIndex;
      const minIndex = isAsc ? sourceIndex : destinationIndex;
      const maxIndex = isAsc ? destinationIndex : sourceIndex;

      for (let index = minIndex; index <= maxIndex; index++) {
        if (sourceIndex === index) {
          cohorts[index].orderIndex = destinationIndex;
        } else {
          cohorts[index].orderIndex = isAsc ? index - 1 : index + 1;
        }
        cohorts[index].isEdited = true;
      }

      state.cohorts = sortBy(cohorts, 'orderIndex');
    },
    changeVisitsOrder: (
      state,
      action: PayloadAction<{ sourceIndex: number; destinationIndex: number }>,
    ) => {
      const { sourceIndex, destinationIndex } = action.payload;
      const { cohort: currentCohort } = state;

      const cohort = state.cohorts.find(
        (cohort) => cohort.traceId === currentCohort?.traceId,
      );
      if (!cohort) {
        return;
      }

      cohort.patientAssessments.forEach((_, index) => {
        if (cohort.patientAssessments[index].orderIndex !== index) {
          // this shouldn't happen usually, but some old visits may share the same order
          cohort.patientAssessments[index].orderIndex = index;
          cohort.patientAssessments[index].isEdited = true;
        }
      });

      const isAsc = sourceIndex < destinationIndex;
      const minIndex = isAsc ? sourceIndex : destinationIndex;
      const maxIndex = isAsc ? destinationIndex : sourceIndex;

      for (let index = minIndex; index <= maxIndex; index++) {
        if (sourceIndex === index) {
          cohort.patientAssessments[index].orderIndex = destinationIndex;
        } else {
          cohort.patientAssessments[index].orderIndex = isAsc
            ? index - 1
            : index + 1;
        }

        cohort.patientAssessments[index].isEdited = true;
      }

      cohort.patientAssessments = sortBy(
        cohort.patientAssessments,
        'orderIndex',
      );

      state.cohort = cohort;
    },
    setCohortErrors: (
      state,
      action: PayloadAction<{ errors: PatientCohortErrors }>,
    ) => {
      state.cohortErrors = action.payload.errors;
    },
  },
});

export const selectCohorts = (state: RootState) => state.visitSchedules.cohorts;

export const selectCohort = (state: RootState) => state.visitSchedules.cohort;

export const selectCohortNameEditor = (state: RootState) =>
  state.visitSchedules.cohortNameEditor;

export const selectVisitErrors =
  (visit: PatientAssessmentEditor, validEdcNames: Set<string>) =>
  (state: RootState) => {
    const { cohorts } = state.visitSchedules;
    return validateEdcVisitMapping(cohorts, visit, validEdcNames);
  };

export const selectIsCohortsValid = (state: RootState) => {
  const { cohorts } = state.visitSchedules;
  return cohorts.length > 0 && cohorts.every(isCohortValid);
};

export const selectCohortsToSave = createSelector([selectCohorts], (cohorts) =>
  cohorts
    .map((cohort) => ({
      ...cohort,
      patientAssessments: cohort.patientAssessments.filter(
        (visit) => visit.isEdited ?? visit.isMagicMapped,
      ),
    }))
    .filter(
      (cohort) => cohort.isEdited ?? cohort.patientAssessments.length > 0,
    ),
);

export const selectCohortError =
  (cohortTraceId: TraceId) => (state: RootState) => {
    const { patientAssessments, ...rest } =
      state.visitSchedules.cohortErrors[cohortTraceId] ?? {};
    return Object.keys(rest).length > 0 ? rest : undefined;
  };

export const selectVisitError =
  (visitTraceId: TraceId, cohortTraceId: TraceId) => (state: RootState) => {
    const cohortError = state.visitSchedules.cohortErrors[cohortTraceId];
    return cohortError?.patientAssessments?.[visitTraceId];
  };

export const selectIsCohortsListInitialized = (state: RootState) => {
  const { cohorts } = state.visitSchedules;
  if (cohorts.length === 0) {
    return false;
  }

  return cohorts[0].patientAssessments.length > 0;
};

export const {
  changeCohort,
  changeCohortsOrder,
  changeDayOfProtocol,
  changeEdcCohortMapping,
  changeEdcVisitMapping,
  changeVisitsOrder,
  changeVisitName,
  changeVisitType,
  closeCohortNameEditor,
  insertDuplicatedCohort,
  insertCohort,
  insertVisit,
  magicMapping,
  removeCohort,
  resetState,
  removeVisit,
  setCohorts,
  setCohortErrors,
  showCohortNameEditor,
  toggleDayOfProtocol,
  updateNameInCohort,
  changeTimelinePortion,
} = visitSchedulesSlice.actions;

export const visitSchedulesReducer = visitSchedulesSlice.reducer;

function formatName(name: string, count: number) {
  if (/ \d+$/.test(name)) {
    return name.replace(/\d+$/, count.toString());
  }
  return `${name} ${count}`;
}

export function getNewCopyName(name: string, names: string[]) {
  let count = 1;
  let formattedName = formatName(name, count);

  while (names.includes(formattedName)) {
    count++;
    formattedName = formatName(name, count);
  }

  return formattedName;
}

export function getDayOfProtocol(
  dayOfProtocol: PatientAssessment['dayOfProtocol'],
  visitType: VisitType,
) {
  if (visitType === 'isScreen') {
    return null;
  }

  if (visitType === 'isEnroll') {
    return 1;
  }

  return dayOfProtocol ?? null;
}

export function isVisitValid(visit: PatientAssessment) {
  if (!visit.name || visit.name.trim() === '') {
    return false;
  }

  const visitType = mapVisitType(visit);
  switch (visitType) {
    case 'isScreen':
      return visit.dayOfProtocol == null;
    case 'isEnroll':
      return visit.dayOfProtocol === 1;
    default:
      return visit.dayOfProtocol == null || visit.dayOfProtocol > 0;
  }
}

export function isCohortValid(cohort: PatientCohort) {
  return (
    cohort.name.trim() !== '' &&
    cohort.patientAssessments.length > 0 &&
    cohort.patientAssessments.every(isVisitValid)
  );
}

export function mapVisitType(visit: PatientAssessment): VisitType {
  if (visit.isScreen) {
    return 'isScreen';
  }

  if (visit.isEnroll) {
    return 'isEnroll';
  }

  if (visit.isScreenfail) {
    return 'isScreenfail';
  }

  if (visit.isDroppedCompleted) {
    return 'isDroppedCompleted';
  }

  return 'isRegular';
}
