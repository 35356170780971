import * as Sentry from '@sentry/react';

import type { VendorType } from './types';

type BackendActivityUnitType =
  | ''
  | 'ASIN'
  | 'MNTH'
  | 'PCTC'
  | 'PTMN'
  | 'PTNT'
  | 'PVIS'
  | 'SITE'
  | 'STMN';

export enum ActivityUnitType {
  PATIENT = 'Patient',
  PATIENT_MONTH = 'Patient Month',
  SITE = 'Site',
  SITE_MONTH = 'Site Month',
  MONTH = 'Month',
  AS_INVOICED = 'As Invoiced',
  PERCENT_COMPLETE = '% Complete',
  PATIENT_VISITS = 'Patient Visits',
  /* Default empty value needed for Select logic */
  NONE = '',
}

export enum ActivityUnitDetail /* Unit type == Site */ {
  RECRUITED = 'Recruited',
  RECRUITED_ALL = 'Recruited - all vendors',
  INITIATED = 'Initiated',
  INITIATED_ALL = 'Initiated - all vendors',
  CLOSE = 'Close',
  CLOSED_ALL = 'Closed - all vendors',
  /* Unit type == Patient */
  ENROLLED = 'Enrolled',
  ENROLLED_ALL = 'Enrolled - all vendors',
  SCREENED = 'Screened',
  SCREENED_ALL = 'Screened - all vendors',
  DROPPED_COMPLETED = 'Dropped/Completed',
  DROPPED_COMPLETED_ALL = 'Dropped/Completed - all vendors',
  /* Unit type == Month */
  STARTUP = 'Startup',
  CONDUCT = 'Conduct',
  ENROLLMENT_PERIOD = 'Enrollment Period',
  TREATMENT_PERIOD = 'Treatment Period',
  FOLLOW_UP_PERIOD = 'Follow-up Period',
  // Close (already in Site group above)
  OVERALL = 'Overall',
  CUSTOM = 'Custom',
  /* Unit type == Site Month */
  AS_INITIATED = 'As initiated',
  AS_INITIATED_ALL = 'As initiated - all vendors',
  AS_RECRUITED = 'As recruited',
  AS_RECRUITED_ALL = 'As recruited - all vendors',
  /* Default empty value needed for Select logic */
  NONE = '',
  /* Unit type == Patient Month or As Invoiced */
  AS_OCCURRED = 'As occurred',
  AS_OCCURRED_ALL = 'As occurred - all vendors',
}

export const detailChoicesForUnitType = (
  unitType: ActivityUnitType | null | undefined,
  vendorType: VendorType,
) => {
  switch (unitType) {
    case ActivityUnitType.SITE:
      return [
        ActivityUnitDetail.RECRUITED,
        ...(vendorType === 'OCC' ? [ActivityUnitDetail.RECRUITED_ALL] : []),
        ActivityUnitDetail.INITIATED,
        ...(vendorType === 'OCC' ? [ActivityUnitDetail.INITIATED_ALL] : []),
        ActivityUnitDetail.CLOSE,
        ...(vendorType === 'OCC' ? [ActivityUnitDetail.CLOSED_ALL] : []),
      ];
    case ActivityUnitType.PATIENT:
      return [
        ActivityUnitDetail.ENROLLED,
        ...(vendorType === 'OCC' ? [ActivityUnitDetail.ENROLLED_ALL] : []),
        ActivityUnitDetail.SCREENED,
        ...(vendorType === 'OCC' ? [ActivityUnitDetail.SCREENED_ALL] : []),
        ActivityUnitDetail.DROPPED_COMPLETED,
        ...(vendorType === 'OCC'
          ? [ActivityUnitDetail.DROPPED_COMPLETED_ALL]
          : []),
      ];
    case ActivityUnitType.MONTH:
      return [
        ActivityUnitDetail.STARTUP,
        ActivityUnitDetail.CONDUCT,
        ActivityUnitDetail.ENROLLMENT_PERIOD,
        ActivityUnitDetail.TREATMENT_PERIOD,
        ActivityUnitDetail.FOLLOW_UP_PERIOD,
        ActivityUnitDetail.CLOSE,
        ActivityUnitDetail.OVERALL,
        ActivityUnitDetail.CUSTOM,
      ];
    case ActivityUnitType.SITE_MONTH:
      return [
        ActivityUnitDetail.AS_INITIATED,
        ...(vendorType === 'OCC' ? [ActivityUnitDetail.AS_INITIATED_ALL] : []),
        ActivityUnitDetail.AS_RECRUITED,
        ...(vendorType === 'OCC' ? [ActivityUnitDetail.AS_RECRUITED_ALL] : []),
      ];
    case ActivityUnitType.PATIENT_MONTH:
      return [
        ActivityUnitDetail.AS_OCCURRED,
        ...(vendorType === 'OCC' ? [ActivityUnitDetail.AS_OCCURRED_ALL] : []),
      ];
    case ActivityUnitType.AS_INVOICED:
      return [ActivityUnitDetail.AS_OCCURRED];
    case ActivityUnitType.PERCENT_COMPLETE:
      return [ActivityUnitDetail.AS_OCCURRED];
    case ActivityUnitType.PATIENT_VISITS:
      return [ActivityUnitDetail.AS_OCCURRED];
    case ActivityUnitType.NONE:
    case null:
    case undefined:
      return [];
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- Will never happen as long as the type isn't violated.
      Sentry.captureMessage(`Unrecognized unit type: ${unitType}`);
      return [];
  }
};

export const backendValueForUnitType = (
  unitType?: ActivityUnitType,
): BackendActivityUnitType => {
  switch (unitType) {
    case ActivityUnitType.SITE:
      return 'SITE';
    case ActivityUnitType.PATIENT:
      return 'PTNT';
    case ActivityUnitType.MONTH:
      return 'MNTH';
    case ActivityUnitType.SITE_MONTH:
      return 'STMN';
    case ActivityUnitType.PATIENT_MONTH:
      return 'PTMN';
    case ActivityUnitType.AS_INVOICED:
      return 'ASIN';
    case ActivityUnitType.PERCENT_COMPLETE:
      return 'PCTC';
    case ActivityUnitType.PATIENT_VISITS:
      return 'PVIS';
    case ActivityUnitType.NONE:
    case undefined:
      return ActivityUnitType.NONE;
  }
};

export const unitTypeFromBackendValue = (
  backendUnitType?: string,
): ActivityUnitType => {
  const UNIT_TYPE_MAP: Record<string, ActivityUnitType> = {
    SITE: ActivityUnitType.SITE,
    PTNT: ActivityUnitType.PATIENT,
    MNTH: ActivityUnitType.MONTH,
    STMN: ActivityUnitType.SITE_MONTH,
    PTMN: ActivityUnitType.PATIENT_MONTH,
    ASIN: ActivityUnitType.AS_INVOICED,
    PCTC: ActivityUnitType.PERCENT_COMPLETE,
    PVIS: ActivityUnitType.PATIENT_VISITS,
  };
  return backendUnitType !== undefined &&
    Object.keys(UNIT_TYPE_MAP).includes(backendUnitType)
    ? UNIT_TYPE_MAP[backendUnitType]
    : ActivityUnitType.NONE;
};

export const backendValueForUnitDetail = (unitDetail?: ActivityUnitDetail) => {
  switch (unitDetail) {
    case ActivityUnitDetail.RECRUITED:
      return 'RCTD';
    case ActivityUnitDetail.RECRUITED_ALL:
      return 'RCAL';
    case ActivityUnitDetail.INITIATED:
      return 'INTD';
    case ActivityUnitDetail.INITIATED_ALL:
      return 'INAL';
    case ActivityUnitDetail.CLOSE:
      return 'CLSE';
    case ActivityUnitDetail.CLOSED_ALL:
      return 'CLAL';
    case ActivityUnitDetail.ENROLLED:
      return 'ENRL';
    case ActivityUnitDetail.ENROLLED_ALL:
      return 'ERAL';
    case ActivityUnitDetail.SCREENED:
      return 'SCND';
    case ActivityUnitDetail.SCREENED_ALL:
      return 'SCAL';
    case ActivityUnitDetail.DROPPED_COMPLETED:
      return 'DPCP';
    case ActivityUnitDetail.DROPPED_COMPLETED_ALL:
      return 'DCAL';
    case ActivityUnitDetail.STARTUP:
      return 'STUP';
    case ActivityUnitDetail.CONDUCT:
      return 'CDCT';
    case ActivityUnitDetail.ENROLLMENT_PERIOD:
      return 'ENPD';
    case ActivityUnitDetail.TREATMENT_PERIOD:
      return 'TMPD';
    case ActivityUnitDetail.FOLLOW_UP_PERIOD:
      return 'FUPD';
    case ActivityUnitDetail.OVERALL:
      return 'OVAL';
    case ActivityUnitDetail.CUSTOM:
      return 'CSTM';
    case ActivityUnitDetail.AS_INITIATED:
      return 'ASIT';
    case ActivityUnitDetail.AS_INITIATED_ALL:
      return 'ASIA';
    case ActivityUnitDetail.AS_RECRUITED:
      return 'ASRC';
    case ActivityUnitDetail.AS_RECRUITED_ALL:
      return 'ASRA';
    case ActivityUnitDetail.AS_OCCURRED:
      return 'ASOC';
    case ActivityUnitDetail.AS_OCCURRED_ALL:
      return 'ASAL';
    case ActivityUnitDetail.NONE:
    case undefined:
      return '';
  }
};

export const unitDetailFromBackendValue = (
  backendUnitDetail?: string,
): ActivityUnitDetail => {
  const UNIT_DETAIL_MAP: Record<string, ActivityUnitDetail> = {
    RCTD: ActivityUnitDetail.RECRUITED,
    RCAL: ActivityUnitDetail.RECRUITED_ALL,
    INTD: ActivityUnitDetail.INITIATED,
    INAL: ActivityUnitDetail.INITIATED_ALL,
    CLSE: ActivityUnitDetail.CLOSE,
    CLAL: ActivityUnitDetail.CLOSED_ALL,
    ENRL: ActivityUnitDetail.ENROLLED,
    ERAL: ActivityUnitDetail.ENROLLED_ALL,
    SCND: ActivityUnitDetail.SCREENED,
    SCAL: ActivityUnitDetail.SCREENED_ALL,
    DPCP: ActivityUnitDetail.DROPPED_COMPLETED,
    DCAL: ActivityUnitDetail.DROPPED_COMPLETED,
    STUP: ActivityUnitDetail.STARTUP,
    CDCT: ActivityUnitDetail.CONDUCT,
    ENPD: ActivityUnitDetail.ENROLLMENT_PERIOD,
    TMPD: ActivityUnitDetail.TREATMENT_PERIOD,
    FUPD: ActivityUnitDetail.FOLLOW_UP_PERIOD,
    OVAL: ActivityUnitDetail.OVERALL,
    CSTM: ActivityUnitDetail.CUSTOM,
    ASIT: ActivityUnitDetail.AS_INITIATED,
    ASIA: ActivityUnitDetail.AS_INITIATED_ALL,
    ASRC: ActivityUnitDetail.AS_RECRUITED,
    ASRA: ActivityUnitDetail.AS_RECRUITED_ALL,
    ASOC: ActivityUnitDetail.AS_OCCURRED,
    ASAL: ActivityUnitDetail.AS_OCCURRED_ALL,
  };
  return backendUnitDetail !== undefined &&
    Object.keys(UNIT_DETAIL_MAP).includes(backendUnitDetail)
    ? UNIT_DETAIL_MAP[backendUnitDetail]
    : ActivityUnitDetail.NONE;
};
