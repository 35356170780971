import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import SnackbarError from 'shared/components/snackbar-error/SnackbarError';
import CondorTextField from 'shared/components/text-field/CondorTextField';
import Alert from 'shared/ui/alert/Alert';
import Button from 'shared/ui/button/Button';
import Checkbox from 'shared/ui/checkbox/Checkbox';
import LoadingButton from 'shared/ui/loading-button/LoadingButton';
import Select from 'shared/ui/select/Select';

import DialogTitleWithClose from 'shared/lib/dialog/DialogTitleWithClose';
import {
  GL_ACCOUNT_LABELS,
  type GlAccountResponse,
  GlAccountType,
  type TraceId,
} from 'shared/lib/types';

import {
  useCreateGlAccountMutation,
  useGetGlAccountsByCompanyQuery,
  useUpdateGlAccountMutation,
} from 'shared/api/rtkq/glaccounts';

import { useGainLossEnabled } from '../fx-rate/hooks/useFxRateColumnDefs';

type Props = {
  companyTraceId: TraceId;
  editAccountTraceId?: TraceId;
  hideAddAnotherButton?: boolean;
  onClose: () => void;
  onSave?: (glAccount: GlAccountResponse) => void;
};

type Fields = {
  accountNumber?: string;
  accountType?: GlAccountType | null;
  description?: string;
};

const defaultValues: Fields = {
  accountNumber: '',
  accountType: GlAccountType.EXPENSE_ACCOUNT,
  description: '',
};

function AddEditGlAccountDialog(props: Props) {
  const {
    companyTraceId,
    onClose,
    editAccountTraceId,
    hideAddAnotherButton,
    onSave,
  } = props;
  const [lastSavedAccountNumber, setLastSavedAccountNumber] =
    useState<string>('');
  const [apiError, setApiError] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [addAnother, setAddAnother] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<Fields>({ ...defaultValues });
  const [createGlAccount, { isLoading: createIsLoading }] =
    useCreateGlAccountMutation();
  const [updateGlAccount, { isLoading: updateIsLoading }] =
    useUpdateGlAccountMutation();
  const {
    currentData: existingGlAccounts,
    isLoading,
    error: getGlAccountsError,
  } = useGetGlAccountsByCompanyQuery(companyTraceId);
  const gainLossEnabled = useGainLossEnabled();

  useEffect(() => {
    if (
      !isLoading &&
      existingGlAccounts?.length &&
      editAccountTraceId !== undefined
    ) {
      const existingAccount = existingGlAccounts.find(
        (account) => account.trace_id === editAccountTraceId,
      );
      if (existingAccount) {
        setFormValues({
          accountNumber: existingAccount.account_number,
          accountType: existingAccount.account_type,
          description: existingAccount.description,
        });
      }
    }
  }, [isLoading, existingGlAccounts, editAccountTraceId]);

  useEffect(() => {
    setApiError(JSON.stringify(getGlAccountsError));
  }, [getGlAccountsError]);

  function onCloseSnackbar(
    _event?: Event | React.SyntheticEvent,
    reason?: string,
  ) {
    if (reason === 'clickaway') {
      return;
    }

    setLastSavedAccountNumber('');
  }

  function validateAccountNumber(): string | undefined {
    const { accountNumber } = formValues;
    if (!accountNumber) {
      return 'Account number is required';
    }

    const alreadyExists = existingGlAccounts
      ?.filter((account) => account.trace_id !== editAccountTraceId)
      .map((account) => account.account_number)
      .includes(accountNumber);
    if (alreadyExists) {
      return 'This G/L account already exists.';
    }
  }

  function validateAccountType(): string | undefined {
    const { accountType } = formValues;
    if (accountType === null) {
      return 'Account type is required';
    }
  }

  function getFormErrors(): Fields | undefined {
    const errors = [
      { field: 'accountNumber', message: validateAccountNumber() },
      { field: 'accountType', message: validateAccountType() },
    ].filter((error) => !!error.message);

    if (!errors.length) {
      return;
    }

    return errors.reduce((acc: Record<string, string | undefined>, error) => {
      acc[error.field] = error.message;
      return acc;
    }, {});
  }

  function isAccountTypeTaken(accountType: GlAccountType) {
    if (accountType === GlAccountType.EXPENSE_ACCOUNT) {
      return false;
    }

    return !!existingGlAccounts
      ?.filter((account) => account.trace_id !== editAccountTraceId)
      .find((account) => account.account_type === accountType);
  }

  function getAccountTypeMenuItem(gl_account_type: GlAccountType) {
    const label = GL_ACCOUNT_LABELS[gl_account_type];
    if (isAccountTypeTaken(gl_account_type)) {
      return (
        <Tooltip
          key={gl_account_type}
          enterDelay={500}
          title={`A ${label} account already exists. You can only have one of this type of account.`}
          followCursor
        >
          <span>
            <MenuItem value={gl_account_type} disabled>
              {label}
            </MenuItem>
          </span>
        </Tooltip>
      );
    }

    return (
      <MenuItem key={gl_account_type} value={gl_account_type}>
        {label}
      </MenuItem>
    );
  }

  async function handleOnSave() {
    const formErrors = getFormErrors();
    if (!submitted && formErrors) {
      setSubmitted(true);
      return;
    }

    let newGlAccount: GlAccountResponse | undefined;
    try {
      if (editAccountTraceId !== undefined) {
        newGlAccount = await updateGlAccount({
          trace_id: editAccountTraceId,
          company: companyTraceId,
          account_number: formValues.accountNumber,
          account_type: formValues.accountType!,
          description: formValues.description,
        }).unwrap();
      } else {
        newGlAccount = await createGlAccount({
          company: companyTraceId,
          account_number: formValues.accountNumber,
          account_type: formValues.accountType!,
          description: formValues.description,
        }).unwrap();
      }

      if (addAnother) {
        setLastSavedAccountNumber(formValues.accountNumber ?? '');
        resetValues();
      } else {
        onClose();
      }
    } catch (error: unknown) {
      const message =
        error instanceof Error ? error.message : 'Save G/L account failed';
      setApiError(message);
    }
    if (newGlAccount?.trace_id) {
      onSave?.(newGlAccount);
    }
  }

  function resetValues() {
    setSubmitted(false);
    setFormValues({ ...defaultValues });
  }

  function onChangeAccountNumber(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setFormValues((values) => ({ ...values, accountNumber: value }));
  }

  function onChangeDescription(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setFormValues((values) => ({ ...values, description: value }));
  }

  function onChangeAccountType(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setFormValues((values) => ({
      ...values,
      accountType: value as GlAccountType,
    }));
  }

  const formErrors = submitted ? getFormErrors() : undefined;

  return (
    <Dialog maxWidth="xs" fullWidth open>
      <DialogTitleWithClose onClose={onClose}>
        <Typography variant="h6">
          {editAccountTraceId === undefined ? 'Add' : 'Edit'} G/L Account
        </Typography>
      </DialogTitleWithClose>
      <DialogContent>
        <Stack gap={3} mt={1}>
          <FormControl>
            <CondorTextField
              autoComplete="off"
              errors={formErrors?.accountNumber}
              label="Account number"
              placeholder="Enter account number"
              value={formValues.accountNumber}
              required
              onChange={onChangeAccountNumber}
            />
          </FormControl>
          <FormControl>
            <Select
              disabled={isLoading}
              errors={formErrors?.accountType !== undefined}
              helperText={formErrors?.accountType}
              label="Account type"
              value={formValues.accountType}
              required
              onChange={onChangeAccountType}
            >
              {Object.values(GlAccountType)
                .filter(
                  (gl_account_type) =>
                    gl_account_type !== GlAccountType.UNREALIZED_GAIN_LOSS ||
                    gainLossEnabled,
                )
                .map(getAccountTypeMenuItem)}
            </Select>
          </FormControl>
          <CondorTextField
            errors={formErrors?.description}
            label="Description"
            placeholder="Enter account description"
            rows={4}
            type="text"
            value={formValues.description}
            fullWidth
            multiline
            onChange={onChangeDescription}
          />
          <Snackbar
            autoHideDuration={6000}
            open={!!lastSavedAccountNumber}
            onClose={onCloseSnackbar}
          >
            <Alert
              severity="success"
              sx={{ width: '100%' }}
              onClose={onCloseSnackbar}
            >
              &ldquo;{lastSavedAccountNumber}&rdquo; was saved successfully.
            </Alert>
          </Snackbar>
          {!!apiError && <SnackbarError message={apiError} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        {editAccountTraceId === undefined && !hideAddAnotherButton ? (
          <FormControlLabel
            label="Add another"
            control={
              <Checkbox
                checked={addAnother}
                onChange={(_, checked) => setAddAnother(checked)}
              />
            }
          />
        ) : null}
        <Button testId="cancel" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          disabled={!!formErrors}
          loading={createIsLoading || updateIsLoading}
          testId="save"
          variant="contained"
          onClick={() => void handleOnSave()}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddEditGlAccountDialog;
