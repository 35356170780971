import {
  getToggleableMoneyCellConfig,
  getTrialMoneyCellConfig,
} from 'shared/components/ag-grid-cells/config';
import { toggleableGetter } from 'shared/components/ag-grid/helpers/value-getters';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import type { SharedContractExpenseSummaryRow } from 'shared/lib/types';

export default function getSharedExpenseSummaryColumnDefs(
  _isOpenPeriod: boolean,
  isCroOrBoth: boolean,
): Array<CondorColDef<SharedContractExpenseSummaryRow>> {
  return [
    {
      headerName: 'Current contract value',
      flex: 1,
      aggFunc: 'sum',
      ...getToggleableMoneyCellConfig('current_contract_value'),
      cellRendererSelector: 'currentContractValueRendererSelector',
    },
    {
      headerName: 'Current LTD expensed',
      flex: 1,
      aggFunc: 'sum',
      ...getToggleableMoneyCellConfig('current_ltd_expensed'),
    },
    {
      headerName: 'Vendor reported',
      flex: 1,
      aggFunc: 'sum',
      ...getToggleableMoneyCellConfig('vendor_reported'),
    },
    ...(isCroOrBoth
      ? ([
          {
            headerName: 'AIP LTD expensed',
            flex: 1,
            aggFunc: 'sum',
            ...getToggleableMoneyCellConfig<SharedContractExpenseSummaryRow>(
              'aip_ltd_expensed',
              {
                valueGetter: `data?.vendor_type === 'CRO' ? ${toggleableGetter('aip_ltd_expensed')} : undefined`,
              },
            ),
          },
        ] as const)
      : []),
    {
      headerName: 'Reconciled expense',
      flex: 1,
      aggFunc: 'sum',
      ...getToggleableMoneyCellConfig('reconciled_expense'),
    },
    {
      headerName: 'LTD Invoiced',
      colId: 'ltd_invoiced',
      flex: 1,
      // for CRO, it shows only the total, which can be picked from any of the children - it should be the same for all
      // for OCC, it shows the sum of all children
      valueGetter: `
      {        const getLeafValue = (node) =>
          node.childrenAfterGroup?.[0].data?.vendor_type === 'CRO'
            ? node.childrenAfterGroup[0].data?.ltd_invoiced
            : node.childrenAfterGroup?.[0].data?.vendor_type === 'OCC'
            ? node.childrenAfterGroup.reduce((acc, childNode) => acc + childNode.data.ltd_invoiced, 0)
            : 'INVALID';
        return node.footer
          ? node.leafGroup
            ? getLeafValue(node)
            : node.childrenAfterGroup?.reduce((acc, subNode) => acc + getLeafValue(subNode), 0)
          : node.data?.vendor_type === 'CRO'
          ? undefined
          : node.data?.ltd_invoiced
      }
      `,
      ...getTrialMoneyCellConfig(),
    },
  ];
}
