import { useState } from 'react';

import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';

import KabobMenu from 'shared/components/kabob-menu/KabobMenu';
import Modal from 'shared/components/modal/Modal';

import type { LabGridRow } from 'shared/lib/types';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import { openEditLab } from 'shared/state/slices/labSlice';

import { useDeleteLabContractMutation } from 'shared/api/rtkq/labcontracts';

type Props = {
  labGridRow: LabGridRow;
  onAddNewLabVersion: (labTraceId: string) => Promise<void>;
};

function LabMenu(props: Props) {
  const { labGridRow, onAddNewLabVersion } = props;
  const {
    lab_trace_id: labTraceId,
    lab_contract_trace_id: labContractTraceId,
  } = labGridRow;

  const dispatch = useDispatch();
  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);
  const isSoftDeleteEnabled = useFeatureFlag('soft_delete');
  const canDeleteTrialInfo = useHasPermission(['canDeleteTrialInfo']);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteLabContract, { isLoading: isDeletingLabContract }] =
    useDeleteLabContractMutation();

  const showDeleteMenu =
    canDeleteTrialInfo && isSoftDeleteEnabled && !!labContractTraceId;

  const handleLabContractDelete = async () => {
    await deleteLabContract(labContractTraceId!);
    setIsModalOpen(false);
  };

  const onEditLab = () => {
    dispatch(openEditLab({ traceId: labTraceId! }));
  };

  return (
    <>
      <KabobMenu
        size="small"
        options={[
          ...(canEditTrialInfo && labTraceId
            ? [
                {
                  label: 'Add new lab version',
                  onClick: () => void onAddNewLabVersion(labTraceId),
                },
                {
                  label: 'Edit lab',
                  onClick: () => onEditLab(),
                },
              ]
            : []),
          ...(showDeleteMenu
            ? [
                {
                  label: 'Delete lab',
                  onClick: () => setIsModalOpen(true),
                },
              ]
            : []),
        ]}
      />
      <Modal
        handleClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title={`Are you sure you want to delete this version of ${labGridRow.name}?`}
        ButtonProps={{
          label: 'Delete Version',
          testId: 'DeleteLabContractModal',
          onClick: () => void handleLabContractDelete(),
          sx: { backgroundColor: 'error.main' },
          loading: isDeletingLabContract,
        }}
      >
        <Typography>
          If the lab version was being used in any trial expenses, accrual and
          forecasting calculations may be affected. This action cannot be
          undone.
        </Typography>
      </Modal>
    </>
  );
}

export default LabMenu;
