import { type ReactElement, useState } from 'react';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

import CommentsButton from 'shared/components/comments/CommentsButton';
import usePageCommentsCountLoader from 'shared/components/comments/hooks/usePageCommentsCountLoader';
import Button from 'shared/ui/button/Button';

import useHasPermission from 'shared/lib/permissions/useHasPermission';
import { CommentLocationType } from 'shared/lib/types';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

import ManageExpectedVisitsWizard from '../wizards/ManageExpectedVisitsWizard';

type Props = {
  setShowExpectedVisits: (showExpectedVisits: boolean) => void;
};

function PatientJourneyHeader(props: Props): ReactElement {
  const { setShowExpectedVisits } = props;
  const isOpenPeriod = !useIsClosedPeriodVersion();
  const [showManageExpectedVisits, setShowManageExpectedVisits] =
    useState<boolean>(false);

  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);
  const commentsCount = usePageCommentsCountLoader(
    CommentLocationType.PATIENT_JOURNEY,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 1,
        pb: '8px',
        mr: 2,
      }}
    >
      {isOpenPeriod && (
        <Tooltip title="Show expected visits to the end of the period.">
          <FormControlLabel
            label="Show expected visits"
            control={
              <Switch
                onChange={(_, checked) => setShowExpectedVisits(checked)}
              />
            }
          />
        </Tooltip>
      )}
      <Button
        sx={{ display: 'block' }}
        testId="manage_expected_visits_btn"
        variant="outlined"
        onClick={() => setShowManageExpectedVisits(true)}
      >
        {isOpenPeriod && canEditTrialInfo
          ? 'Manage Expected Visits'
          : 'Show Selected Expected Visits'}
      </Button>
      {showManageExpectedVisits && (
        <ManageExpectedVisitsWizard
          onClose={() => setShowManageExpectedVisits(false)}
        />
      )}
      <CommentsButton
        commentsCount={commentsCount}
        location={CommentLocationType.PATIENT_JOURNEY}
      />
    </Box>
  );
}

export default PatientJourneyHeader;
