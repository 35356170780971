import Skeleton from '@mui/material/Skeleton';
import { useSelector } from 'react-redux';

import withPeriodSpecificRecordWrapper from 'shared/lib/periods/withPeriodSpecificRecordWrapper';
import RecordViewer, {
  RecordStyle,
} from 'shared/lib/record-viewer/RecordViewer';
import { PeriodRecordBlobType } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetRegionsMetadataQuery } from 'shared/api/rtkq/trialregions';

function TrialRegionsCardContents() {
  const trial = useSelector(selectTrial);

  const { currentData: regions, isLoading } = useGetRegionsMetadataQuery(
    trial.trace_id,
  );

  const record = regions?.map(({ name, contract_count }) => ({
    key: name,
    value: `Used in ${contract_count} contract${contract_count !== 1 ? 's' : ''}`,
  }));

  if (isLoading) {
    return <Skeleton variant="rectangular" />;
  }
  return <RecordViewer record={record} recordStyle={RecordStyle.HORIZONTAL} />;
}

export default withPeriodSpecificRecordWrapper(
  TrialRegionsCardContents,
  PeriodRecordBlobType.TRIAL,
);
