import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';

// The only reason we're using a context is because we need to store button ref in react state instead of redux store
// (we should not store non-serializable objects in the store) */

type CommentsPopupContextProps = {
  anchorEl: HTMLElement | null;
  setAnchorEl: (element: HTMLElement | null) => void;
};

const CommentsPopupContext = createContext<
  CommentsPopupContextProps | undefined
>(undefined);

export function CommentsPopupProvider({ children }: { children: ReactNode }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <CommentsPopupContext.Provider value={{ anchorEl, setAnchorEl }}>
      {children}
    </CommentsPopupContext.Provider>
  );
}

export const useCommentsPopup = (): CommentsPopupContextProps => {
  const context = useContext(CommentsPopupContext);
  if (!context) {
    return { anchorEl: null, setAnchorEl: () => null };
  }
  return context;
};
