import { useMemo } from 'react';

import type {
  NewValueParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { useTheme } from '@mui/material/styles';

import {
  getContractPoNumbersConfig,
  getNullOrDecimalConfig,
  getPercentCellConfig,
  getSelectCellConfig,
  getTextCellConfig,
  getToggleableMoneyCellConfig,
  getToggleablePercentCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import { tryGetNumericValue } from 'formatters';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import type { TraceId } from 'shared/lib/types';
import { OccAdjustmentType, OccAdjustmentTypeReverse } from 'shared/lib/types';

import { useUpsertVendorRepBlaAmountMutation } from 'shared/api/rtkq/bottomlineadjustments';
import { useUpsertOccReconAdjustmentMutation } from 'shared/api/rtkq/periods';

function normalizeValue(
  value: number | string | null | undefined,
): number | undefined {
  if (value === undefined || value === null) {
    return undefined;
  }

  if (typeof value === 'string' && value.trim() === '') {
    return undefined;
  }

  return Number.parseFloat(Number.parseFloat(value.toString()).toFixed(2));
}

export default function useOccReconGridColumnDefs(
  isOpenPeriod: boolean,
  currentPeriodTraceId: TraceId | undefined,
  isGridLocked?: boolean,
): CondorColDef[] {
  const [upsertOccReconAdjustment] = useUpsertOccReconAdjustmentMutation();
  const [upsertVendorRepBlaAmount] = useUpsertVendorRepBlaAmountMutation();

  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);
  const canEditData = isOpenPeriod && canEditTrialInfo && !isGridLocked;

  const themeMode = useTheme().palette.mode;

  return useMemo(() => {
    const upsertAdjustment = async (
      params: NewValueParams,
      contractBudgetRecordTraceId: TraceId,
      adjustmentType: OccAdjustmentType | null,
      adjustmentAmount?: number | string | null,
      reportedLtdExpense?: number | string | null,
    ) => {
      if (
        adjustmentType !== null &&
        Object.keys(OccAdjustmentType).includes(adjustmentType)
      ) {
        await upsertOccReconAdjustment({
          adjustment_type: adjustmentType,
          adjustment_amount: normalizeValue(adjustmentAmount),
          contract_budget_record: contractBudgetRecordTraceId,
          reported_ltd_expense: normalizeValue(reportedLtdExpense),
          trace_id: currentPeriodTraceId!,
        });

        // force the row that was changed to redraw so the amount can be colored correctly
        if (params.node) {
          params.api.redrawRows({ rowNodes: [params.node] });
        }
      }
    };

    const onReportedLtdExpensedChanged = (params: NewValueParams) => {
      if (params.data?.type === 'BOTTOM_LINE_ADJUSTMENT') {
        const vendorReportedBla = tryGetNumericValue(
          params.data.reportedLtdExpensedUpdated,
        );
        if (vendorReportedBla !== null) {
          void upsertVendorRepBlaAmount({
            trace_id: params.data.traceId,
            amount: vendorReportedBla,
            period_trace_id: currentPeriodTraceId,
          });
        }
      } else {
        void upsertAdjustment(
          params,
          params.data.traceId,
          params.data.adjustmentType,
          params.data.adjustmentAmountToggleable.trial,
          tryGetNumericValue(params.data.reportedLtdExpensedUpdated),
        );
      }
    };

    const onAdjustmentTypeChanged = (params: NewValueParams) => {
      void upsertAdjustment(
        params,
        params.data.traceId,
        params.newValue,
        0,
        params.data.reportedLtdExpensed,
      );
    };

    const onAdjustmentAmountChanged = (params: NewValueParams) => {
      void upsertAdjustment(
        params,
        params.data.traceId,
        params.data.adjustmentType,
        tryGetNumericValue(params.data.adjustmentAmountUpdated),
        params.data.reportedLtdExpensed,
      );
    };

    return [
      {
        headerName: '',
        children: [
          { field: 'traceId', hide: true },
          { field: 'costCategory', pinned: 'left' },
          { field: 'glAccount', headerName: 'G/L Account' },
          { field: 'region' },
          { headerName: 'PO #', ...getContractPoNumbersConfig() },
          { field: 'vendorName', rowGroup: true, hide: true },
        ],
      },
      {
        headerName: 'Contracted Expense',
        children: [
          {
            headerName: 'Contract Value',
            aggFunc: 'sum',
            ...getToggleableMoneyCellConfig('totalPriceToggleable'),
          },
          { field: 'unitType', filter: true },
          { field: 'unitDetail', filter: true },
          { field: 'startDate' },
          { field: 'endDate' },
          {
            field: 'parameterCalculatedUnitCount',
            headerName: '# of units',
            type: 'rightAligned',
            ...getNullOrDecimalConfig({ emptyValueForNodeLevel: 0 }),
          },
          {
            headerName: 'Unit price',
            type: 'rightAligned',
            ...getToggleableMoneyCellConfig('unitPrice', {
              cellRendererParams: { useEmDashInTotal: false },
            }),
          },
          {
            field: 'percentRecognized',
            headerName: '% Recognized',
            aggFunc: 'occPercentRecognizedTotalV2',
            ...getPercentCellConfig({
              useEmDash: false,
              useEmDashInGroup: false,
              useEmDashInTotal: false,
            }),
          },
          {
            headerName: 'LTD expense',
            type: 'rightAligned',
            aggFunc: 'sum',
            cellStyle: 'highlightGroup',
            renderIf: {
              colDef: getToggleableMoneyCellConfig(
                'monthlyExpenseValuesTotalToggleable',
                { cellRendererParams: { useEmDashInGroup: true } },
              ),
              condition: 'isNotTopsideAdjustment',
            },
          },
        ],
      },
      {
        headerName: 'Reported Expense',
        children: [
          {
            field: 'reportedPercentRecognized',
            headerName: '% Recognized (reported)',
            aggFunc: 'sum',
            ...getToggleablePercentCellConfig('reportedPercentRecognized'),
          },
          {
            field: 'reportedLtdExpensedUpdated',
            headerName: 'LTD Expensed (reported)',
            aggFunc: 'sum',
            ...getToggleableMoneyCellConfig('reportedLtdExpensedToggleable', {
              cellRendererParams: {
                useEmDash: false,
                useEmDashInTotal: false,
              },
            }),
            ...(canEditData && {
              ...makeEditableIf(
                ({ node, context }) =>
                  context.currencyViewMode === 'native' && !node.group,
                themeMode,
              ),
              onCellValueChanged: onReportedLtdExpensedChanged,
            }),
          },
          {
            headerName: 'Variance from contracted',
            aggFunc: null,
            filter: 'AgGridVarianceFilter',
            ...getToggleableMoneyCellConfig('varianceFromContractedToggleable'),
          },
        ],
      },
      {
        headerName: 'Reconciliation',
        children: [
          {
            field: 'adjustmentType',
            width: 200,
            headerName: 'Adjustment Type',
            refData: OccAdjustmentType,
            filter: true,
            filterParams: {
              values: Object.keys(OccAdjustmentType).filter(
                (adjustmentType) =>
                  adjustmentType !==
                  OccAdjustmentTypeReverse[OccAdjustmentType.AMIP],
              ),
            },
            ...getTextCellConfig({
              useEmDash: false,
              useEmDashInGroup: false,
              useEmDashInTotal: false,
            }),
            ...(canEditData && {
              ...getSelectCellConfig({
                useEmDash: false,
                useEmDashInGroup: false,
                useEmDashInTotal: false,
              }),
              cellEditorParams: (params: ValueGetterParams) => ({
                values: Object.keys(OccAdjustmentType).filter(
                  (adjustmentType) =>
                    adjustmentType !==
                      OccAdjustmentTypeReverse[OccAdjustmentType.AMIP] &&
                    (adjustmentType !==
                      OccAdjustmentTypeReverse[OccAdjustmentType.CROR] ||
                      params.data.reportedLtdExpensed != null),
                ),
              }),
              ...makeEditableIf(
                ({ data, node }) =>
                  !node.group && data?.type !== 'BOTTOM_LINE_ADJUSTMENT',
                themeMode,
              ),
              onCellValueChanged: onAdjustmentTypeChanged,
            }),
          },
          {
            headerName: 'Adjustment Amount',
            field: 'adjustmentAmountUpdated',
            type: 'rightAligned',
            aggFunc: 'sum',
            ...getToggleableMoneyCellConfig('adjustmentAmountToggleable', {
              cellRendererParams: {
                useEmDash: false,
                useEmDashInGroup: false,
                useEmDashInTotal: false,
              },
            }),
            ...(canEditData && {
              ...makeEditableIf(
                ({ data, context }) =>
                  context.currencyViewMode === 'native' &&
                  data?.type !== 'BOTTOM_LINE_ADJUSTMENT' &&
                  data?.adjustmentType ===
                    OccAdjustmentTypeReverse[OccAdjustmentType.OTHR],
                themeMode,
              ),
              onCellValueChanged: onAdjustmentAmountChanged,
            }),
          },
          {
            headerName: 'Final Reconciled Expense',
            type: 'rightAligned',
            aggFunc: 'sum',
            ...getToggleableMoneyCellConfig('finalReconciledExpenseToggleable'),
          },
        ],
      },
    ];
  }, [
    currentPeriodTraceId,
    upsertVendorRepBlaAmount,
    upsertOccReconAdjustment,
    themeMode,
    canEditData,
  ]);
}
