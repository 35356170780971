import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import { getRowsForVersionHistory } from 'accruals/pages/clinical-expenses/shared/helpers';
import type {
  ContractContainerPeriodMenuItem,
  ContractContainerResponse,
  ContractVersionResponse,
  VersionHistoryRow,
} from 'shared/lib/types';

export function processOccVersionHistoryRows(
  contractContainers: ContractContainerResponse[] | undefined,
  allContractVersions: ContractVersionResponse[] | undefined,
  contractContainerPeriodMenuItems?: ContractContainerPeriodMenuItem[],
) {
  const clonedContractContainers = cloneDeep(contractContainers ?? []);
  clonedContractContainers.sort((contractContainerA, contractContainerB) =>
    (contractContainerA.vendor_name ?? '').localeCompare(
      contractContainerB.vendor_name ?? '',
    ),
  );

  return clonedContractContainers.flatMap((contractContainer) => {
    const contractVersions = allContractVersions?.filter((contract) =>
      contractContainer.contract_versions.includes(contract.trace_id),
    );

    const contractContainerPeriodMenuItem =
      contractContainerPeriodMenuItems?.find(
        (menuItem) =>
          menuItem.contractContainer.trace_id === contractContainer.trace_id,
      );

    return (
      getRowsForVersionHistory(
        contractContainer,
        contractVersions,
        contractContainerPeriodMenuItem,
      ) ?? []
    );
  });
}

export default function useOccVersionHistoryRows(
  contractContainers: ContractContainerResponse[] | undefined,
  allContractVersions: ContractVersionResponse[] | undefined,
): VersionHistoryRow[] | undefined {
  return useMemo(
    () => processOccVersionHistoryRows(contractContainers, allContractVersions),
    [allContractVersions, contractContainers],
  );
}
