import type {
  CommentRequest,
  CommentResponse,
  CommentsCountsGroupedByRowId,
} from 'shared/lib/types';

import constructApi from './constructApi';
import { PERIOD_FILES } from './periods';

export const COMMENTS = 'COMMENTS';

const apiEndpointFactory = constructApi<CommentResponse, CommentRequest>(
  'comments',
).withTags(COMMENTS);
const api = apiEndpointFactory.inject(() => ({
  getCommentsWithFilters: apiEndpointFactory.getWithFilters(),
  getCommentsCountGroupedByRowId:
    apiEndpointFactory.getPathWithFilters<CommentsCountsGroupedByRowId>(
      'get_comment_counts',
    ),
  createComment: apiEndpointFactory.create<FormData>([PERIOD_FILES]),
  deleteComment: apiEndpointFactory.delete(),
}));

export const {
  useGetCommentsWithFiltersQuery,
  useGetCommentsCountGroupedByRowIdQuery,
  useCreateCommentMutation,
  useDeleteCommentMutation,
} = api;
