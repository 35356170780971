import Grid from '@mui/material/Grid';

import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import TrialRecordCard from './components/TrialRecordCard';
import TrialRegionsCard from './components/TrialRegionsCard';
function TrialSettingsPage() {
  const trialRegionsEnabled = useFeatureFlag('trial_regions');

  return (
    <PageContentLoadingContainer>
      <Grid spacing={3} container>
        <Grid xs={6} item>
          <TrialRecordCard />
        </Grid>
        {trialRegionsEnabled && (
          <Grid xs={6} item>
            <TrialRegionsCard />
          </Grid>
        )}
      </Grid>
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(TrialSettingsPage);
