import type { ChangeEvent } from 'react';
import { useMemo, useState } from 'react';

import type { GridApi } from '@ag-grid-community/core';

import UserAccessHeader from 'shared/components/user-permissions/components/UserAccessHeader';

import PartnerUserGrid from 'partner/grids/PartnerUserGrid';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import withPartnerRequired from 'shared/api/hocs/withPartnerRequired';

function UserManagement() {
  const [showActiveUsers, setShowActiveUsers] = useState(false);
  const [partnerUserGridApi, setPartnerUserGridApi] = useState<GridApi | null>(
    null,
  );
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);

  function handleFilterTextboxChanged(event: ChangeEvent<HTMLInputElement>) {
    partnerUserGridApi?.setGridOption('quickFilterText', event.target.value);
  }

  return (
    <PageContentLoadingContainer>
      <FlexGridContainer>
        <UserAccessHeader
          handleFilterTextboxChanged={handleFilterTextboxChanged}
          setShowActiveUsers={setShowActiveUsers}
          setShowAddUserDialog={setShowAddUserDialog}
          showActiveUsers={showActiveUsers}
          showAddUserDialog={showAddUserDialog}
        />
        <PartnerUserGrid
          overlayNoRowsTemplate="No Data."
          setPartnerUserGridApi={setPartnerUserGridApi}
          setShowAddUserDialog={setShowAddUserDialog}
          showActiveUsers={showActiveUsers}
          sx={useMemo(() => ({ height: '100%' }), [])}
        />
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default withPartnerRequired(UserManagement);
