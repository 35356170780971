import { useState } from 'react';

import type {
  ICellRendererParams,
  IGroupCellRendererParams,
} from '@ag-grid-community/core';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import KabobMenu from 'shared/components/kabob-menu/KabobMenu';
import Modal from 'shared/components/modal/Modal';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import type { ContractContainerResponse } from 'shared/lib/types';
// eslint-disable-next-line import/no-restricted-paths -- the cell renderer is used in OCC only
import ContractVersionWizard from 'accruals/wizards/contract-version-wizard/ContractVersionWizard';

import { useDeleteContractContainerMutation } from 'shared/api/rtkq/contractcontainers';

// this appears to be mis-typed in ag-grid. IGroupCellRendererParams has all
// the properties of ICellRendererParams, but the type doesn't think so
function AgGridOccContractContainerGroupCellRenderer(
  params: ICellRendererParams & IGroupCellRendererParams,
) {
  const { innerRendererParams, value } = params;
  const { contractContainers, isOpenPeriod } = innerRendererParams as {
    contractContainers: ContractContainerResponse[];
    isOpenPeriod: boolean;
  };

  const { trace_id: traceId, vendor_name: vendorName } =
    contractContainers.find(
      (contractContainer) => contractContainer.trace_id === value,
    ) ?? {};

  const isSoftDeleteEnabled = useFeatureFlag('soft_delete');
  const canDeleteTrialInfo = useHasPermission(['canDeleteTrialInfo']);
  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);
  const [deleteContractContainer, { isLoading: isDeletingContractContainer }] =
    useDeleteContractContainerMutation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openContractVersionWizard, setOpenContractVersionWizard] =
    useState(false);

  if (!traceId) {
    return null;
  }

  const handleCloseModal = () => setModalIsOpen(false);
  const handleContractContainerDelete = async () => {
    await deleteContractContainer(traceId);
    handleCloseModal();
  };

  const showDeleteMenu = isSoftDeleteEnabled && canDeleteTrialInfo;

  return (
    <Box>
      <Typography variant="body2">{vendorName}</Typography>
      {isOpenPeriod && (showDeleteMenu || canEditTrialInfo) && (
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 50,
          }}
        >
          <KabobMenu
            size="small"
            options={[
              ...(canEditTrialInfo
                ? [
                    {
                      label: 'New version',
                      onClick: () => setOpenContractVersionWizard(true),
                    },
                  ]
                : []),
              ...(showDeleteMenu
                ? [
                    {
                      label: 'Delete contract',
                      onClick: () => setModalIsOpen(true),
                    },
                  ]
                : []),
            ]}
          />
        </Box>
      )}
      <Modal
        handleClose={handleCloseModal}
        isOpen={modalIsOpen}
        title={`Are you sure you want to delete the OCC contract for ${vendorName}?`}
        ButtonProps={{
          label: 'Delete Contract',
          testId: 'DeleteOCCContractContainerModal',
          onClick: () => void handleContractContainerDelete(),
          sx: { backgroundColor: 'error.main' },
          loading: isDeletingContractContainer,
        }}
      >
        <Typography>
          This will delete all contract versions, files, budgets, and any
          expenses that were being used in the trial&apos;s accrual and
          forecasting calculations. This action cannot be undone.
        </Typography>
      </Modal>
      {openContractVersionWizard && (
        <ContractVersionWizard
          contractContainerId={traceId}
          onClose={() => {
            setOpenContractVersionWizard(false);
          }}
        />
      )}
    </Box>
  );
}

export default AgGridOccContractContainerGroupCellRenderer;
