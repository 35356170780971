import { useSelector } from 'react-redux';

import {
  missingPeriodVersion,
  selectPeriodVersion,
} from 'accruals/state/slices/periodVersionSlice'; // eslint-disable-line import/no-restricted-paths -- this files knows how to handle this empty correctly
import type { CommentLocationType } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetCommentsCountGroupedByRowIdQuery } from 'shared/api/rtkq/comments';

export type CommentQuery = {
  location_type: CommentLocationType;
  location_slug?: string;
  // NOTE: Its ALWAYS only one of period_version or company or period_lte, never more than one
  period_version?: string;
  company?: string;
  period_lte?: string;
};

export function buildCommentQuery(
  location: CommentLocationType,
  locationSlug: string | undefined,
) {
  const currentPeriodVersion = useSelector(selectPeriodVersion);
  const currentCompany = useSelector(selectCompany);

  const commentQuery: CommentQuery = {
    location_type: location,
    location_slug: locationSlug?.trim().length ? locationSlug : undefined,
  };

  if (currentPeriodVersion !== missingPeriodVersion) {
    commentQuery.period_version = currentPeriodVersion.trace_id;
  } else {
    commentQuery.company = currentCompany.trace_id;
  }
  return commentQuery;
}

export default function usePageCommentsCountLoader(
  location: CommentLocationType,
  locationSlug?: string,
) {
  const commentQuery: CommentQuery = buildCommentQuery(location, locationSlug);
  const { currentData } = useGetCommentsCountGroupedByRowIdQuery(commentQuery);

  // we show row level comments in the page-level comments popup, so the count should include row level comments
  return Object.values(currentData ?? {}).reduce(
    (acc: number, val: number) => acc + val,
    0,
  );
}
