import { useState } from 'react';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import TrialSetupWizard from 'accruals/wizards/trial-setup/TrialSetupWizard';
import HasPermission from 'shared/lib/permissions/HasPermission';
import { RecordStyle } from 'shared/lib/record-viewer/RecordViewer';

import EditButton from './EditButton';
import TrialRecordCardContents from './TrialRecordCardContents';

export default function TrialRecordCard() {
  const [editModalOpen, setEditModalOpen] = useState(false);

  return (
    <>
      <DashboardCard
        title="Trial Record"
        titleAction={
          <HasPermission permissions={['canEditTrialInfo']}>
            <EditButton
              testId="edit_trial"
              onClick={() => setEditModalOpen(true)}
            />
          </HasPermission>
        }
        showDivider
      >
        <TrialRecordCardContents recordStyle={RecordStyle.STACKED} />
      </DashboardCard>
      {editModalOpen && (
        <TrialSetupWizard onClose={() => setEditModalOpen(false)} />
      )}
    </>
  );
}
