import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetAdministrativeOrProcedureCategoriesByTrialQuery } from 'shared/api/rtkq/administrativeorprocedurecategories';
import { useGetContractContainersByTrialQuery } from 'shared/api/rtkq/contractcontainers';
import { useGetPatientCohortsByTrialQuery } from 'shared/api/rtkq/patientcohorts';
import { useGetRegionsByTrialQuery } from 'shared/api/rtkq/regions';

import { getLabDefs, getSiteDefs } from './getSiteLabCostMatrixColumnDefs';
import useShowRowMenuColumn from './useShowRowMenuColumn';

export default function useSiteLabCostMatrixColumnDefs(
  isOpenPeriod: boolean,
  type: 'LAB' | 'SITE',
  isGridLocked?: boolean,
): Array<CondorColDef | CondorColGroupDef> {
  const trial = useSelector(selectTrial);
  const { currentData: contractContainers } =
    useGetContractContainersByTrialQuery(trial.trace_id);
  const { currentData: regions } = useGetRegionsByTrialQuery(trial.trace_id);
  const { currentData: adminOrProcedureCosts } =
    useGetAdministrativeOrProcedureCategoriesByTrialQuery(trial.trace_id);
  const { currentData: patientCohorts } = useGetPatientCohortsByTrialQuery(
    trial.trace_id,
  );
  const themeMode = useTheme().palette.mode;
  const isSoftDeleteEnabled = useFeatureFlag('soft_delete');
  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);
  const canDeleteTrialInfo = useHasPermission(['canDeleteTrialInfo']);
  const showRowMenuColumn = useShowRowMenuColumn();

  return useMemo(() => {
    if (type === 'SITE') {
      const invoiceableProcedureCosts = adminOrProcedureCosts?.filter(
        (category) => category.category_type === 'INV',
      );

      return getSiteDefs(
        isOpenPeriod,
        contractContainers,
        regions,
        invoiceableProcedureCosts,
        patientCohorts,
        themeMode,
        canEditTrialInfo && !isGridLocked,
        showRowMenuColumn,
      );
    }

    return getLabDefs(
      isOpenPeriod,
      contractContainers,
      patientCohorts,
      canEditTrialInfo && !isGridLocked,
      showRowMenuColumn,
    );
  }, [
    type,
    isOpenPeriod,
    isGridLocked,
    contractContainers,
    adminOrProcedureCosts,
    patientCohorts,
    regions,
    themeMode,
    canEditTrialInfo,
    canDeleteTrialInfo,
    isSoftDeleteEnabled,
  ]);
}
