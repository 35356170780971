import type { ChangeEvent, ReactElement, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import { useSelector } from 'react-redux';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import Autocomplete from 'shared/ui/autocomplete/Autocomplete';
import Button from 'shared/ui/button/Button';
import IconButton from 'shared/ui/icon-button/IconButton';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import { getDropdownOptionsFromArray } from 'shared/helpers/helpers';
import type {
  AdministrativeOrProcedureCategoryRequest,
  DropdownOption,
} from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';
import WizardStep from 'shared/wizards/steps/WizardStep';

import {
  useCreateAdministrativeOrProcedureCategoryMutation,
  useDeleteAdministrativeOrProcedureCategoryMutation,
  useGetAdministrativeOrProcedureCategoriesByTrialQuery,
  useUpdateAdministrativeOrProcedureCategoryMutation,
} from 'shared/api/rtkq/administrativeorprocedurecategories';
import { useGetUniqueEdcProcedureNamesQuery } from 'shared/api/rtkq/periods';

type ProcedureCategoryWrapper = {
  isMagicMapped: boolean;
  options: string[];
  procedureCategory: AdministrativeOrProcedureCategoryRequest;
};
export default function ProcedureCategoriesStep(): ReactElement {
  const trial = useSelector(selectTrial);
  const { currentData: existingProcedureCategories } =
    useGetAdministrativeOrProcedureCategoriesByTrialQuery(trial.trace_id);
  const period = useSelector(selectPeriod);
  const { currentData: categoryMappingOptionsDict } =
    useGetUniqueEdcProcedureNamesQuery(period.trace_id);
  const [createProcedure] =
    useCreateAdministrativeOrProcedureCategoryMutation();
  const [updateProcedure] =
    useUpdateAdministrativeOrProcedureCategoryMutation();
  const [deleteProcedure, { isLoading: deleteIsLoading }] =
    useDeleteAdministrativeOrProcedureCategoryMutation();
  const [procedureCategoryWrappers, setProcedureCategoryWrappers] = useState<
    ProcedureCategoryWrapper[]
  >([]);
  const [invalidProcedure, setInvalidProcedure] = useState<string[]>([]);

  useEffect(() => {
    if (existingProcedureCategories !== undefined) {
      setProcedureCategoryWrappers(
        existingProcedureCategories
          .filter((category) => category.category_type === 'INV')
          .map((procedureCategoryResponse) => ({
            isMagicMapped: false,
            options: getMappingOptions(procedureCategoryResponse),
            procedureCategory: {
              trace_id: procedureCategoryResponse.trace_id,
              trial: procedureCategoryResponse.trial,
              name: procedureCategoryResponse.name,
              category_type: procedureCategoryResponse.category_type,
              mapped_edc_name: procedureCategoryResponse.mapped_edc_name,
              order_index: procedureCategoryResponse.order_index,
            },
          })),
      );
    }
  }, [existingProcedureCategories]);

  useEffect(() => {
    const newProcedureCategoryWrappers = [...procedureCategoryWrappers];
    let isModified = false;
    for (const wrapper of newProcedureCategoryWrappers) {
      if (!wrapper.procedureCategory.mapped_edc_name) {
        const magicMappingValue = getMappingOptions(
          wrapper.procedureCategory,
        ).find(
          (edcProcedureName) =>
            edcProcedureName === wrapper.procedureCategory.name,
        );
        if (magicMappingValue) {
          wrapper.procedureCategory.mapped_edc_name = magicMappingValue;
          wrapper.isMagicMapped = true;
          isModified = true;
        }
      }
    }
    if (isModified) {
      setProcedureCategoryWrappers(newProcedureCategoryWrappers);
    }
    validateUniqueProcedure();
  }, [procedureCategoryWrappers, categoryMappingOptionsDict]);

  const addNewProcedureCategory = () => {
    let fakeId = procedureCategoryWrappers.length.toString(); // We need a unique identifier even though we haven't saved to the database yet.
    const existingTraceIds = new Set(
      procedureCategoryWrappers.map(
        (wrapper) => wrapper.procedureCategory.trace_id,
      ),
    );
    while (existingTraceIds.has(fakeId)) {
      fakeId = `${fakeId}${fakeId}`; // If we get a duplicate number due to repeated add/delete, double up the string to avoid collisions.
    }
    setProcedureCategoryWrappers([
      ...procedureCategoryWrappers,
      {
        isMagicMapped: false,
        options: categoryMappingOptionsDict?.ALL ?? [],
        procedureCategory: {
          trial: trial.trace_id,
          trace_id: fakeId,
          name: '',
          category_type: 'INV', // "Invoiceable" was the old name for "procedure"
          mapped_edc_name: null,
          order_index: 0,
        },
      },
    ]);
  };

  const onSave = async () => {
    await Promise.all(
      procedureCategoryWrappers.map(async (wrapper, index: number) => {
        const { procedureCategory } = wrapper;
        const procedureCategoryWithOrderIndex = {
          ...procedureCategory,
          order_index: index,
        };
        const { trace_id, ...rest } = procedureCategoryWithOrderIndex;
        return trace_id?.startsWith('cc_')
          ? updateProcedure(procedureCategoryWithOrderIndex)
          : createProcedure({ ...rest });
      }),
    );
  };

  const validateUniqueProcedure = () => {
    const duplicates = procedureCategoryWrappers.reduce<
      ProcedureCategoryWrapper[]
    >((acc, wrapper, index, array) => {
      const hasDuplicate = array.some(
        (_, i) =>
          i !== index &&
          wrapper.procedureCategory.name === array[i].procedureCategory.name &&
          wrapper.procedureCategory.mapped_edc_name ===
            array[i].procedureCategory.mapped_edc_name,
      );
      if (hasDuplicate) {
        acc.push(wrapper);
      }
      return acc;
    }, []);

    const errors: string[] = [];
    procedureCategoryWrappers.forEach((wrapper, index) => {
      errors[index] = duplicates.some(
        (duplicate) =>
          duplicate.procedureCategory.trace_id ===
          wrapper.procedureCategory.trace_id,
      )
        ? 'Procedure category must be unique'
        : '';
    });

    setInvalidProcedure(errors);
  };

  const handleProcedureCategoryNameChange =
    (procedureIndex: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const newProcedureCategoryWrappers = [...procedureCategoryWrappers];
      newProcedureCategoryWrappers[procedureIndex].procedureCategory.name =
        event.target.value;
      setProcedureCategoryWrappers(newProcedureCategoryWrappers);
    };

  const handleProcedureCategoryChange =
    (procedureIndex: number) =>
    (_e: SyntheticEvent, value: DropdownOption<string> | null) => {
      const newProcedureCategoryWrappers = [...procedureCategoryWrappers];
      newProcedureCategoryWrappers[
        procedureIndex
      ].procedureCategory.mapped_edc_name = value?.value ?? '';
      setProcedureCategoryWrappers(newProcedureCategoryWrappers);
    };

  const getSelectedEdcProcedureName = (index: number) => {
    const { procedureCategory } = procedureCategoryWrappers[index];
    const isMappedName = (option: DropdownOption<string>) =>
      option.value === procedureCategory.mapped_edc_name;
    // TODO: temp disable for EY demo
    // const isMagicMapping = (option: DropdownOption<string>) => option.value === procedureCategories[index].name;
    return (
      getDropdownOptionsFromArray(getMappingOptions(procedureCategory)).find(
        isMappedName,
      ) ??
      // TODO: temp disable for EY demo // ?? edcProcedureNameOptions.find(isMagicMapping)
      null
    );
  };

  const onRemoveProcedure = async (
    procedure: AdministrativeOrProcedureCategoryRequest,
  ) => {
    if (procedure.trace_id?.startsWith('cc_')) {
      await deleteProcedure(procedure.trace_id);
    }

    const wrappers = procedureCategoryWrappers.filter(
      (wrapper) => wrapper.procedureCategory.trace_id !== procedure.trace_id,
    );
    setProcedureCategoryWrappers(wrappers);
  };

  const isSaveDisabled =
    procedureCategoryWrappers.length === 0 ||
    invalidProcedure.some((error) => !!error) ||
    deleteIsLoading;

  return (
    <WizardStep
      description="You do not have to map every EDC category."
      disableNextButton={isSaveDisabled}
      header="Fill in procedure categories"
      onNextAsync={onSave}
    >
      {procedureCategoryWrappers.map((wrapper, i) => {
        const { procedureCategory } = wrapper;
        return (
          <div key={procedureCategory.trace_id}>
            <FormControl sx={{ my: 2, flexDirection: 'row' }}>
              <CondorTextField
                InputLabelProps={{ shrink: true }}
                errors={invalidProcedure[i]}
                label="Procedure category name"
                placeholder="Enter name"
                size="small"
                sx={{ mr: 1, width: '300px' }}
                value={procedureCategory.name}
                onChange={handleProcedureCategoryNameChange(i)}
              />
              <Autocomplete
                errorMsg={invalidProcedure[i]}
                label="EDC procedure name"
                value={getSelectedEdcProcedureName(i)}
                options={getDropdownOptionsFromArray(
                  getMappingOptions(procedureCategory),
                )}
                sx={{
                  ml: 1,
                  width: '300px',
                  bgcolor: wrapper.isMagicMapped ? 'success.light' : undefined,
                }}
                onChange={handleProcedureCategoryChange(i)}
              />
              <IconButton
                disabled={deleteIsLoading}
                edge="end"
                size="small"
                sx={{ ml: 1, mt: 1, alignSelf: 'baseline' }}
                onClick={() => void onRemoveProcedure(procedureCategory)}
              >
                <DeleteIcon />
              </IconButton>
            </FormControl>
          </div>
        );
      })}
      <Button
        disabled={invalidProcedure.some((error) => !!error)}
        testId="add_procedure"
        variant="text"
        onClick={addNewProcedureCategory}
      >
        + Add Procedure
      </Button>
    </WizardStep>
  );

  function getMappingOptions(
    procedureCategory: AdministrativeOrProcedureCategoryRequest,
  ): string[] {
    return (
      categoryMappingOptionsDict?.[procedureCategory.name] ??
      categoryMappingOptionsDict?.ALL ??
      []
    );
  }
}
