import type { EditableCallbackParams } from '@ag-grid-community/core';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';

import { EM_DASH_PLACEHOLDER } from 'shared/components/ag-grid/helpers/shared';
import type { CustomCellRendererParams } from 'shared/components/ag-grid/types';

import { percentFormatter } from 'formatters';
import type { CurrencyViewMode } from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';

import { getIcon, getTextColor } from '../ag-grid-custom-cell-renderer/helpers';

function AgGridPercentCellRenderer(params: CustomCellRendererParams) {
  const {
    colDef,
    startIcon,
    node,
    endIcon,
    newRowText,
    tooltipMessage,
    value,
    severity,
    forecast = false,
    context,
    useEmDash,
    useEmDashInGroup,
    useEmDashInTotal,
  } = params;

  const currencyViewMode: CurrencyViewMode | undefined =
    context?.currencyViewMode;

  const materialTheme = useTheme();

  const percentValue =
    currencyViewMode && typeof value === 'object'
      ? value?.[currencyViewMode]
      : value;
  let finalValue = percentFormatter(percentValue);

  const isEditable =
    typeof colDef?.editable === 'function'
      ? colDef.editable(params as EditableCallbackParams) // the params are "close enough" to the same thing that we can treat them the same as far as TS is concerned
      : colDef?.editable === true;

  if (!isEditable && (useEmDash || useEmDashInGroup || useEmDashInTotal)) {
    const isEmptyValue = !value;
    const isGroup = node.group;
    const isTotal = node.footer;

    let shouldUseEmDash = false;
    if (useEmDashInTotal && isTotal && isEmptyValue) {
      shouldUseEmDash = true;
    } else if (useEmDashInGroup && isGroup && isEmptyValue) {
      shouldUseEmDash = true;
    } else if (useEmDash && isEmptyValue && !isGroup && !isTotal) {
      shouldUseEmDash = true;
    }

    if (shouldUseEmDash) {
      finalValue = EM_DASH_PLACEHOLDER;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row',
        height: '100%',
      }}
    >
      {getIcon(
        severity,
        materialTheme,
        node,
        startIcon,
        finalValue,
        newRowText,
      )}
      <Box
        sx={{
          flex: 1,
          ml: 1,
          color: getTextColor(materialTheme, severity, value, forecast, false),
        }}
      >
        {tooltipMessage !== undefined ? (
          <Tooltip
            title={tooltipMessage}
            PopperProps={{
              sx: {
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: (theme) => theme.palette.common.black,
                },
              },
            }}
          >
            <span>{finalValue}</span>
          </Tooltip>
        ) : (
          finalValue
        )}
      </Box>
      {getIcon(severity, materialTheme, node, endIcon, finalValue, newRowText)}
    </Box>
  );
}

export default AgGridPercentCellRenderer;
