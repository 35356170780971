import { useState } from 'react';

import AddCircle from '@mui/icons-material/AddCircle';
import Edit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import {
  getInputLabelUtilityClasses,
  inputLabelClasses,
} from '@mui/material/InputLabel';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import AddEditGlAccountDialog from 'shared/components/gl-accounts/AddEditGlAccountDialog';
import CondorTextField from 'shared/components/text-field/CondorTextField';
import Button from 'shared/ui/button/Button';

import { currencyFormatter, getCurrencySymbol } from 'formatters';
import { humanizeCostCategory } from 'shared/helpers/helpers';
import type {
  BottomLineAdjustment,
  ContractContainerResponse,
  ContractInfo,
  DropdownOption,
  GlAccountResponse,
  VendorType,
} from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useDeleteContractCostCategoryInfoMutation } from 'shared/api/rtkq/contractcostcategoryinfo';

import BottomLineAdjustmentDialog from './BottomLineAdjustmentDialog';
import GlAccountSelector from './GlAccountSelector';
import type { ContractVersionErrors } from './types';
import { mapBlaValidations } from './validators';

import styles from './ContractVersionWizard.module.scss';

type Props = {
  contractContainer: ContractContainerResponse;
  contractInfo: ContractInfo;
  existingGlAccounts: GlAccountResponse[];
  fieldErrors: ContractVersionErrors;
  setContractInfo: (contractInfo: ContractInfo) => void;
};

function AccountingInfoForm(props: Props) {
  const {
    contractInfo,
    setContractInfo,
    fieldErrors,
    existingGlAccounts,
    contractContainer,
  } = props;
  const [bottomLineAdjustmentsDialogOpen, setBottomLineAdjustmentsDialogOpen] =
    useState<boolean>(false);
  const [bottomLineAdjustmentIndexToEdit, setBottomLineAdjustmentIndexToEdit] =
    useState<number | null>(null);
  const [showNewGlAccountDialogFor, setShowNewGlAccountDialogFor] = useState<
    string | null
  >(null);
  const currentCompany = useSelector(selectCompany);
  const [
    deleteContractCostCategoryInfo,
    { isLoading: _isDeleteContractCostCategoryInfoLoading },
  ] = useDeleteContractCostCategoryInfoMutation();

  const localeStringConfig: Intl.NumberFormatOptions = {
    currency: contractContainer.currency,
    currencyDisplay: 'narrowSymbol',
    currencySign: 'standard',
  };

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAccountInfo = {
      ...contractInfo.accountInfo,
      [event.target.name]: event.target.value,
    };
    setContractInfo({ ...contractInfo, accountInfo: newAccountInfo });
  };

  function onGrossValueChange(value: string, index: number) {
    const newAccountInfo = { ...contractInfo.accountInfo };
    const objectToUpdate = newAccountInfo.costCategoryInfos?.[index];
    if (objectToUpdate) {
      objectToUpdate.gross_value = Number.parseFloat(value);
    }
    setContractInfo({ ...contractInfo, accountInfo: newAccountInfo });
  }

  function onGlAccountChange(
    value: DropdownOption<string> | null,
    index: number,
  ) {
    const newAccountInfo = { ...contractInfo.accountInfo };
    const objectToUpdate = newAccountInfo.costCategoryInfos?.[index];
    if (objectToUpdate) {
      objectToUpdate.gl_account = value?.value;
    }
    setContractInfo({
      ...contractInfo,
      accountInfo: {
        ...newAccountInfo,
        costCategoryInfos: [...(newAccountInfo.costCategoryInfos ?? [])],
      },
    });
  }

  const { totalGrossValue, totalNetValue } = calculateContractValues(
    contractInfo,
    contractContainer.vendor_type,
  );

  function openEditBottomLineAdjustmentDialog(index: number) {
    setBottomLineAdjustmentIndexToEdit(index);
    setBottomLineAdjustmentsDialogOpen(true);
  }

  function onCostCategoryChange(value: string, index: number) {
    const newAccountInfo = { ...contractInfo.accountInfo };
    const objectToUpdate = newAccountInfo.costCategoryInfos?.[index];
    if (objectToUpdate) {
      objectToUpdate.category = value;
    }
    setContractInfo({ ...contractInfo, accountInfo: newAccountInfo });
  }

  function addNewCostCategory() {
    const newAccountInfo = { ...contractInfo.accountInfo };
    newAccountInfo.costCategoryInfos?.push({
      category: '',
      gross_value: 0,
      gl_account: '',
    });
    setContractInfo({ ...contractInfo, accountInfo: newAccountInfo });
  }

  function removeCostCategory(index: number) {
    const newAccountInfo = { ...contractInfo.accountInfo };
    const removedRecords = newAccountInfo.costCategoryInfos?.splice(index, 1);
    setContractInfo({ ...contractInfo, accountInfo: newAccountInfo });
    for (const removedRecord of removedRecords ?? []) {
      if (removedRecord.trace_id) {
        void deleteContractCostCategoryInfo(removedRecord.trace_id);
      }
    }
  }

  const glAccountDropdownOptions = existingGlAccounts.map((glAccount) => ({
    value: glAccount.trace_id,
    label: `${glAccount.account_number} - ${glAccount.description}`,
  }));
  glAccountDropdownOptions.push({
    value: '',
    label: NEW_GL_ACCOUNT_OPTION_VALUE,
  });

  const { bottomLineAdjustments } = contractInfo.accountInfo;

  return (
    <>
      <BottomLineAdjustmentDialog
        bottomLineAdjustmentIndexToEdit={bottomLineAdjustmentIndexToEdit}
        contractInfo={contractInfo}
        isOpen={bottomLineAdjustmentsDialogOpen}
        setBottomLineAdjustmentIndexToEdit={setBottomLineAdjustmentIndexToEdit}
        setContractInfo={setContractInfo}
        onClose={() => setBottomLineAdjustmentsDialogOpen(false)}
      />
      <Box
        sx={{
          width: 750,
          margin: (theme) => theme.spacing(2.5, 'auto'),
          padding: (theme) => theme.spacing(2.5, 0),
          borderBottom: '1px solid rgb(0 0 0 / 12%)',
        }}
      >
        <Typography fontSize="16px" fontWeight="400" lineHeight="133%">
          Contract values<sup style={{ color: 'red', fontSize: 16 }}>*</sup>
        </Typography>
        <Box sx={{ marginTop: 1, color: 'grey.700' }}>
          Enter the values as shown on the contract.
        </Box>
        <Grid spacing={2} sx={{ marginTop: 1 }} container>
          {contractInfo.accountInfo.costCategoryInfos?.map(
            (costCategoryInfo, i) => (
              <Grid
                key={costCategoryInfo.trace_id ?? `new-cost-category-${i}`}
                xs={3}
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'grey.700',
                  paddingBottom: 2,
                }}
                item
              >
                {contractContainer.vendor_type === 'CRO' ? (
                  <Typography sx={{ marginBottom: 2 }} variant="subtitle2">
                    {humanizeCostCategory(costCategoryInfo.category)}
                  </Typography>
                ) : (
                  <FormControl
                    className={styles.formInput}
                    sx={{ marginBottom: 2 }}
                  >
                    <CondorTextField
                      inputProps={{ maxLength: 255 }}
                      label="Cost Category"
                      name={costCategoryInfo.category}
                      size="small"
                      value={costCategoryInfo.category}
                      errors={
                        fieldErrors.gl_accounts_per_category?.[i]?.category
                      }
                      onChange={(event) =>
                        onCostCategoryChange(event.target.value, i)
                      }
                    />
                  </FormControl>
                )}
                <FormControl
                  className={styles.formInput}
                  sx={{ marginBottom: 2, width: 175 }}
                >
                  <GlAccountSelector
                    categoryType={costCategoryInfo.category}
                    feesInfo={costCategoryInfo}
                    glAccountDropdownOptions={glAccountDropdownOptions}
                    setShowNewGlAccountDialogFor={setShowNewGlAccountDialogFor}
                    errors={
                      fieldErrors.gl_accounts_per_category?.[i]?.gl_account
                    }
                    onGlAccountChange={(_e, value) =>
                      onGlAccountChange(value, i)
                    }
                  />
                </FormControl>
                <FormControl className={styles.inputField}>
                  <CondorTextField
                    label="Gross Value"
                    size="small"
                    type="number"
                    value={costCategoryInfo.gross_value}
                    errors={
                      fieldErrors.gl_accounts_per_category?.[i]?.gross_value
                    }
                    onChange={(event) =>
                      onGrossValueChange(event.target.value, i)
                    }
                  />
                </FormControl>
                {contractContainer.vendor_type === 'OCC' && (
                  <FormControl className={styles.inputField}>
                    <Button
                      color="error"
                      size="small"
                      sx={{ marginTop: 1 }}
                      testId="delete"
                      variant="outlined"
                      disabled={
                        contractInfo.accountInfo.costCategoryInfos?.length === 1
                      }
                      onClick={() => removeCostCategory(i)}
                    >
                      Delete
                    </Button>
                  </FormControl>
                )}
              </Grid>
            ),
          )}
          <Grid xs={3} item>
            <Typography sx={{ marginBottom: 2 }} variant="subtitle2">
              Total
            </Typography>
            <Box sx={{ height: 38, marginBottom: 2 }} />
            <FormControl className={styles.inputField}>
              <CondorTextField
                label="Total Gross Value"
                size="small"
                value={currencyFormatter(totalGrossValue, localeStringConfig)}
                disabled
              />
            </FormControl>
          </Grid>
        </Grid>
        {contractContainer.vendor_type === 'OCC' && (
          <Box sx={{ marginTop: 2 }}>
            <Button
              size="small"
              testId="add_cost_category"
              variant="outlined"
              onClick={addNewCostCategory}
            >
              Add cost category
            </Button>
          </Box>
        )}
        {bottomLineAdjustments.length > 0 ? (
          <Box>
            {bottomLineAdjustments.map((adjustment, index) => (
              <Box key={adjustment.traceId} sx={{ marginTop: 3 }}>
                <FormControl className={styles.inputField}>
                  <CondorTextField
                    label={adjustment.adjustmentType}
                    size="small"
                    value={
                      adjustment.amountType === 'ABSOLUTE'
                        ? adjustment.amount
                        : adjustment.amountPercentage
                    }
                    disabled
                    {...(adjustment.amountType === 'PERCENT'
                      ? { startAdornment: '%' }
                      : {
                          startAdornment: getCurrencySymbol(
                            contractContainer.currency,
                          ),
                        })}
                    endAdornment={<Edit />}
                    errors={mapBlaValidations(
                      fieldErrors.adjustmentErrors?.[index],
                    )}
                    sx={{
                      [`& .${outlinedInputClasses.root}`]: {
                        cursor: 'pointer',
                      },
                      [`& .${inputLabelClasses.root}.${getInputLabelUtilityClasses('disabled')}`]:
                        {
                          color: 'grey.700',
                        },
                    }}
                    onClick={() => openEditBottomLineAdjustmentDialog(index)}
                  />
                </FormControl>
                {index === bottomLineAdjustments.length - 1 ? (
                  <Box
                    sx={{
                      cursor: 'pointer',
                      height: '100%',
                      marginLeft: 2,
                      marginTop: 1,
                      display: 'inline-block',
                    }}
                    onClick={() => setBottomLineAdjustmentsDialogOpen(true)}
                  >
                    <AddCircle />
                  </Box>
                ) : (
                  <br />
                )}
              </Box>
            ))}
          </Box>
        ) : (
          <Box sx={{ marginTop: 4 }}>
            <FormControl className={styles.inputField}>
              <CondorTextField
                endAdornment={<AddCircle />}
                label="Bottom Line Adjustment"
                size="small"
                type="text"
                value="Add adjustment"
                sx={{
                  [`& .${outlinedInputClasses.root}`]: { cursor: 'pointer' },
                  [`& .${outlinedInputClasses.input}:disabled`]: {
                    cursor: 'pointer',
                  },
                  [`& .${inputLabelClasses.root}.${getInputLabelUtilityClasses('disabled')}`]:
                    { color: 'grey.700' },
                }}
                disabled
                onClick={() => setBottomLineAdjustmentsDialogOpen(true)}
              />
            </FormControl>
          </Box>
        )}
        <Grid
          spacing={1}
          sx={{
            marginTop: 3,
            backgroundColor: 'grey.100',
            padding: 1,
            borderTop: '1px solid',
            borderColor: 'grey.500',
          }}
          container
        >
          {contractInfo.accountInfo.costCategoryInfos?.map(
            (costCategoryInfo, i) => (
              <Grid
                key={costCategoryInfo.trace_id ?? `new-cost-category-${i}`}
                xs={3}
                item
              >
                <Typography color="grey.700" fontSize={12}>
                  {humanizeCostCategory(costCategoryInfo.category)} net value
                </Typography>
                <Typography color="black" fontSize={14} marginY={1}>
                  {currencyFormatter(
                    costCategoryInfo.category === 'DIRECT_FEES'
                      ? applyAdjustments(
                          contractInfo.accountInfo.costCategoryInfos?.[i]
                            ?.gross_value ?? 0,
                          bottomLineAdjustments,
                        )
                      : (contractInfo.accountInfo.costCategoryInfos?.[i]
                          ?.gross_value ?? 0),
                    localeStringConfig,
                  )}
                </Typography>
              </Grid>
            ),
          )}
          <Grid xs={2} item>
            <Typography color="grey.700" fontSize={12}>
              Total net value
            </Typography>
            <Typography
              color="black"
              fontSize={14}
              fontWeight={700}
              marginY={1}
            >
              {currencyFormatter(totalNetValue, localeStringConfig)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <div className={styles.formRow}>
        <div className={styles.formLabel}>Bonus & Penalties</div>
        <div className={styles.rightPanel}>
          <FormControl className={styles.textAreaField}>
            <CondorTextField
              errors={fieldErrors.bonus_and_penalties}
              maxRows={4}
              minRows={2}
              name="bonusesAndPenalties"
              placeholder="Add any notes about bonuses or penalties associated with this contract"
              value={contractInfo.accountInfo.bonusesAndPenalties ?? ''}
              multiline
              onChange={onFieldChange}
            />
          </FormControl>
        </div>
      </div>
      {showNewGlAccountDialogFor !== null && (
        <AddEditGlAccountDialog
          companyTraceId={currentCompany.trace_id}
          onClose={() => setShowNewGlAccountDialogFor(null)}
          onSave={(glAccount) => {
            const newCostCategoryInfos =
              contractInfo.accountInfo.costCategoryInfos?.map((record) => {
                if (record.category === showNewGlAccountDialogFor) {
                  return { ...record, gl_account: glAccount.trace_id };
                }
                return record;
              });

            setContractInfo({
              ...contractInfo,
              accountInfo: {
                ...contractInfo.accountInfo,
                costCategoryInfos: newCostCategoryInfos,
              },
            });
          }}
        />
      )}
    </>
  );
}

function roundDecimal(value: number) {
  return Math.round(value * 100) / 100;
}

export const NEW_GL_ACCOUNT_OPTION_VALUE = 'new_gl_account';

export default AccountingInfoForm;

export function calculateContractValues(
  contractInfo: ContractInfo,
  vendorType: VendorType,
) {
  const totalGrossValue =
    contractInfo.accountInfo.costCategoryInfos?.reduce(
      (acc, record) => acc + (record.gross_value ?? 0),
      0,
    ) ?? 0;

  const getTotalNetValueForCRO = () => {
    const directFeesInfo = contractInfo.accountInfo.costCategoryInfos?.find(
      (record) => record.category === 'DIRECT_FEES',
    );
    return (
      applyAdjustments(
        directFeesInfo?.gross_value ?? 0,
        contractInfo.accountInfo.bottomLineAdjustments,
      ) +
      totalGrossValue -
      (directFeesInfo?.gross_value ?? 0)
    );
  };

  const getTotalNetValueForOCC = () =>
    applyAdjustments(
      totalGrossValue,
      contractInfo.accountInfo.bottomLineAdjustments,
    );

  const totalNetValue =
    vendorType === 'CRO' ? getTotalNetValueForCRO() : getTotalNetValueForOCC();

  return {
    totalGrossValue: roundDecimal(totalGrossValue),
    totalNetValue: roundDecimal(totalNetValue),
  };
}

export const applyAdjustments = (
  grossValue: number,
  adjustments: BottomLineAdjustment[],
) => {
  let result = grossValue;
  for (const adjustment of adjustments) {
    switch (adjustment.adjustmentType) {
      case 'DISCOUNT':
      case 'REBATE': {
        if (
          adjustment.amountType === 'PERCENT' &&
          adjustment.amountPercentage
        ) {
          const sign = -1;
          const netEffect =
            (adjustment.amountPercentage / 100) * sign * grossValue;
          result += netEffect;
        } else if (adjustment.amount) {
          result -= adjustment.amount;
        }
        break;
      }
      case 'INFLATION':
      case 'TAX_RATE': {
        if (
          adjustment.amountType === 'PERCENT' &&
          adjustment.amountPercentage
        ) {
          const sign = 1;
          const netEffect =
            (adjustment.amountPercentage / 100) * sign * grossValue;
          result += netEffect;
        } else if (adjustment.amount) {
          result += adjustment.amount;
        }
        break;
      }
      default:
        break;
    }
  }

  return Math.round(result * 100) / 100;
};
