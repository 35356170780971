import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import type {
  FxRateStatusItem,
  UploadStatus,
  UploadStatusItem,
} from 'shared/lib/types';
import { FinancialCloseTaskType } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import FxRateTaskStatus from './FxRateTaskStatus';
import UploadTaskStatus from './UploadTaskStatus';

function UploadTaskStatusGroup(props: UploadStatus) {
  const { title, items, taskType } = props;
  const currentTrial = useSelector(selectTrial);

  // FX rates are not "upload items" so we need to handle them differently
  // but we can safely assume that if not an fx rate, they are correctly UploadStatusItem[]
  return (
    <>
      {!!title && <Typography variant="body2">{title}</Typography>}
      {taskType === FinancialCloseTaskType.UPLOAD_FX_RATES ? (
        currentTrial.calculate_fx_gain_loss ? (
          <Grid spacing={1} container>
            <Grid xs={6} item>
              <Typography variant="body2">Currency</Typography>
            </Grid>
            <Grid sx={{ textAlign: 'right' }} xs={3} item>
              <Typography variant="body2">Avg rate</Typography>
            </Grid>
            <Grid sx={{ textAlign: 'right' }} xs={3} item>
              <Typography variant="body2">Month close rate</Typography>
            </Grid>
            <Grid xs={12} item>
              <Divider />
            </Grid>
            {(items as FxRateStatusItem[]).map((item) => (
              <FxRateTaskStatus
                key={item.currency_from + item.currency_to}
                contractVersionCount={item.contract_version_count}
                currency_from={item.currency_from}
                currency_to={item.currency_to}
                expectedFxRateCount={item.expected_fx_rate_count}
                fxRateCount={item.fx_rate_count}
                invoiceCount={item.invoice_count}
                labCount={item.lab_count}
                monthCloseFxAdded={item.month_close_fx_added}
                poCount={item.po_count}
                siteCount={item.site_count}
              />
            ))}
          </Grid>
        ) : (
          (items as FxRateStatusItem[]).map((item) => (
            <FxRateTaskStatus
              key={item.currency_from + item.currency_to}
              contractVersionCount={item.contract_version_count}
              currency_from={item.currency_from}
              currency_to={item.currency_to}
              expectedFxRateCount={item.expected_fx_rate_count}
              fxRateCount={item.fx_rate_count}
              invoiceCount={item.invoice_count}
              labCount={item.lab_count}
              poCount={item.po_count}
              siteCount={item.site_count}
            />
          ))
        )
      ) : (
        (items as UploadStatusItem[]).map((item) => (
          <UploadTaskStatus
            key={item.title}
            files={item.files}
            labelVariant={title ? 'caption' : 'body2'}
            status={item.status === 'NOT_UPLOADED' ? 'notUploaded' : 'uploaded'}
            sx={{ mt: title ? 1 : 0 }}
            title={item.title}
          />
        ))
      )}
    </>
  );
}

export default UploadTaskStatusGroup;
