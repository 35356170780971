import { useMemo } from 'react';

import CommentsButton from 'shared/components/comments/CommentsButton';
import usePageCommentsCountLoader from 'shared/components/comments/hooks/usePageCommentsCountLoader';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import { CommentLocationType } from 'shared/lib/types';

import BsFluxGrid from './grids/BsFluxGrid';

function BsFluxTabContent() {
  const commentsCount = usePageCommentsCountLoader(CommentLocationType.BS_FLUX);
  return (
    <>
      <FlexGridContainer>
        <BsFluxGrid sx={useMemo(() => ({ height: '100%' }), [])} />
      </FlexGridContainer>
      <CommentsButton
        commentsCount={commentsCount}
        location={CommentLocationType.BS_FLUX}
        commentsForPage
      />
    </>
  );
}

export default BsFluxTabContent;
