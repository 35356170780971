import { useDispatch, useSelector } from 'react-redux';

import AddLabWizard from 'accruals/wizards/site-or-lab-costs/add-lab-wizard/AddLabWizard';
import {
  closeEditLab,
  selectEditLabTraceId,
} from 'shared/state/slices/labSlice';

function EditLab() {
  const dispatch = useDispatch();
  const editLabIsOpen = useSelector(selectEditLabTraceId);

  function onClose() {
    dispatch(closeEditLab());
  }

  if (!editLabIsOpen) {
    return null;
  }

  return <AddLabWizard title="Edit lab" onClose={onClose} />;
}

export default EditLab;
