import type { LabRequest, LabResponse } from 'shared/lib/types';

import constructApi from './constructApi';
import { LAB_COST_MATRIX } from './trials';

const PATH = 'labs';

const apiEndpointFactory = constructApi<LabResponse, LabRequest>(PATH).withTags(
  'LABS',
);
export const api = apiEndpointFactory.inject(() => ({
  createLab: apiEndpointFactory.create(),
  getLab: apiEndpointFactory.get(),
  getLabsByTrial: apiEndpointFactory.getBy('trial'),
  updateLab: apiEndpointFactory.update([LAB_COST_MATRIX]),
}));

export const { useCreateLabMutation, useGetLabQuery, useUpdateLabMutation } =
  api;
