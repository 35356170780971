import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { CommentLocationType } from 'shared/lib/types';
import type { RootState } from 'shared/state/store';

type Draft = { location: string; text?: string };

type State = {
  isOpen: boolean;
  location: CommentLocationType;
  locationSlug: string;
  drafts: Record<Draft['location'], Pick<Draft, 'text'> | undefined>;
  commentsNumber: Record<Draft['location'], number>;
  activeLocation: string | null;
  showPriorPeriodComments: boolean;
  header?: string | null;
  title?: string | null;
};

const initialState: State = {
  isOpen: false,
  location: CommentLocationType.HOME,
  locationSlug: '',
  drafts: {},
  commentsNumber: {},
  activeLocation: null,
  header: undefined,
  title: undefined,
  showPriorPeriodComments: false,
};

const getFullLocation = (location: string, locationSlug: string) =>
  location + (locationSlug ? `/${locationSlug}` : '');

const commentSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    updateIsOpen: (state, action: PayloadAction<{ isOpen: boolean }>) => {
      const { isOpen } = action.payload;
      state.isOpen = isOpen;
    },
    updateLocation: (
      state,
      action: PayloadAction<{ location: CommentLocationType }>,
    ) => {
      const { location } = action.payload;
      state.location = location;
    },
    updateLocationSlug: (
      state,
      action: PayloadAction<{ locationSlug: string }>,
    ) => {
      const { locationSlug } = action.payload;
      state.locationSlug = locationSlug;
    },
    updateDraftText: (state, action: PayloadAction<{ text: string }>) => {
      const { text } = action.payload;
      const location = getFullLocation(state.location, state.locationSlug);
      state.drafts[location] = { ...state.drafts[location], text };
    },
    deleteDraft: (state) => {
      delete state.drafts[getFullLocation(state.location, state.locationSlug)];
    },
    updateHeader: (state, action: PayloadAction<{ header: string | null }>) => {
      const { header } = action.payload;
      state.header = header;
    },
    updateTitle: (state, action: PayloadAction<{ title: string | null }>) => {
      const { title } = action.payload;
      state.title = title;
    },
    updateShowPriorPeriodComments: (
      state,
      action: PayloadAction<{ showPriorPeriodComments: boolean }>,
    ) => {
      const { showPriorPeriodComments } = action.payload;
      state.showPriorPeriodComments = showPriorPeriodComments;
    },
  },
});

export const selectDraftInLocation = (state: RootState) =>
  state.comment.drafts[
    getFullLocation(state.comment.location, state.comment.locationSlug)
  ];
export const selectCommentsHeader = (state: RootState) => state.comment.header;
export const selectCommentsTitle = (state: RootState) => state.comment.title;
export const selectShowPriorPeriodComments = (state: RootState) =>
  state.comment.showPriorPeriodComments;
export const selectIsOpen = (state: RootState) => state.comment.isOpen;
export const selectLocation = (state: RootState) => state.comment.location;
export const selectLocationSlug = (state: RootState) =>
  state.comment.locationSlug;

export const {
  updateIsOpen,
  deleteDraft,
  updateDraftText,
  updateHeader,
  updateTitle,
  updateShowPriorPeriodComments,
  updateLocation,
  updateLocationSlug,
} = commentSlice.actions;

export const commentReducer = commentSlice.reducer;
