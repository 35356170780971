import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  getGenericCellConfig,
  getNumberCellConfig,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import useHasPermission from 'shared/lib/permissions/useHasPermission';
import type { CurrencyCode } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';

export const MONTH_CLOSE_COLUMN_ID = '_month_close';

export function processFxRateColumnDefs(
  isOpenPeriod: boolean,
  canEditCompanyLevelInfo: boolean,
  showMonthEndRates: boolean,
  inputCurrencies: CurrencyCode[],
): CondorColDef[] {
  const addCurrencyColumn = (field: string, headerName: string) => ({
    headerName,
    field,
    ...(isOpenPeriod && canEditCompanyLevelInfo
      ? {
          ...getNumberCellConfig(),
          editable: true,
        }
      : { ...getGenericCellConfig() }),
  });

  return [
    {
      headerName: 'Period',
      field: 'end_date',
      pinned: 'left',
      valueFormatter: 'humanizePeriodDate',
    },
    ...inputCurrencies.map((currency) => ({
      headerName: currency,
      headerClass: showMonthEndRates ? 'ag-header-cell-center' : undefined,
      children: [
        addCurrencyColumn(currency, 'Avg'),
        showMonthEndRates
          ? addCurrencyColumn(
              `${currency}${MONTH_CLOSE_COLUMN_ID}`,
              'Month close',
            )
          : undefined,
      ].filter((child) => child !== undefined),
    })),
  ];
}

export default function useFxRateColumnDefs(
  isOpenPeriod: boolean,
  inputCurrencies: CurrencyCode[],
): CondorColDef[] {
  const canEditCompanyLevelInfo = useHasPermission(['canEditCompanyLevelInfo']);
  const showMonthEndRates = useGainLossEnabled();
  return useMemo(
    () =>
      processFxRateColumnDefs(
        isOpenPeriod,
        canEditCompanyLevelInfo,
        showMonthEndRates,
        inputCurrencies,
      ),
    [isOpenPeriod, canEditCompanyLevelInfo, showMonthEndRates, inputCurrencies],
  );
}
export function useGainLossEnabled() {
  const company = useSelector(selectCompany);
  const { currentData: trials } = useGetTrialsByCompanyQuery(company.trace_id);
  return (
    trials?.some((trial) => trial.calculate_fx_gain_loss === true) === true
  );
}
