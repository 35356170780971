import type { FormControlLabelProps } from '@mui/material/FormControlLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useRadioGroup } from '@mui/material/RadioGroup';

type StyledFormControlLabelProps = FormControlLabelProps & {
  checked?: boolean;
};

export default function RadioFormControlLabel(
  props: StyledFormControlLabelProps,
) {
  const { value } = props;
  const radioGroup = useRadioGroup();
  const checked = radioGroup ? radioGroup.value === value : false;
  return <FormControlLabel {...props} checked={checked} />;
}
