import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import type { UserRow } from 'shared/lib/types';
import { selectPartner } from 'shared/state/slices/partnerSlice';

import { useGetUsersByPartnerQuery } from 'shared/api/rtkq/partners';

export function processPartnerUserRows(
  userAccessGrid?: { rows: UserRow[] } | undefined,
  showActiveUsers?: boolean,
) {
  return userAccessGrid
    ? showActiveUsers
      ? cloneDeep(userAccessGrid.rows).filter((row) => row.status === 'Active')
      : cloneDeep(userAccessGrid.rows)
    : undefined;
}

export default function usePartnerUserDataRows(showActiveUsers: boolean) {
  const partner = useSelector(selectPartner);
  const { currentData: userData, refetch: usersRefetch } =
    useGetUsersByPartnerQuery(partner.trace_id);

  return useMemo(() => {
    if (userData === undefined) {
      return {
        rowData: undefined,
        usersRefetch,
      };
    }

    return {
      rowData: processPartnerUserRows({ rows: userData }, showActiveUsers),
      usersRefetch,
    };
  }, [userData, showActiveUsers, usersRefetch]);
}
