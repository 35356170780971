import type { ReactElement } from 'react';

import Box from '@mui/material/Box';

import CommentsButton from 'shared/components/comments/CommentsButton';
import usePageCommentsCountLoader from 'shared/components/comments/hooks/usePageCommentsCountLoader';
import LockGridButton from 'shared/components/lock-grid/LockGridButton';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import { CommentLocationType, LockGrid } from 'shared/lib/types';

import AddSiteLabDropdown from '../buttons/AddSiteLabDropdown';
import ManageVisitsAndProceduresDropdown from '../buttons/ManageVisitsAndProceduresDropdown';

type Props = {
  activeTab: string | undefined;
};

function SiteLabHeader(props: Props): ReactElement {
  const { activeTab } = props;
  const location =
    activeTab === 'sites'
      ? CommentLocationType.SITE_COST_MATRIX
      : CommentLocationType.LAB_COST_MATRIX;
  const commentsCount = usePageCommentsCountLoader(location);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 1,
        mb: 2,
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      <ManageVisitsAndProceduresDropdown />
      <AddSiteLabDropdown />
      <LockGridButton
        grid={
          activeTab === 'sites'
            ? LockGrid.SITE_COST_MATRIX
            : LockGrid.LAB_COST_MATRIX
        }
      />
      <CommentsButton commentsCount={commentsCount} location={location} />
    </Box>
  );
}

export default withPeriodSpecificGenericWrapper(SiteLabHeader);
