import type { ChangeEvent, Dispatch, ReactNode, SetStateAction } from 'react';
import { memo, useEffect } from 'react';

import Cancel from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import IconButton from 'shared/ui/icon-button/IconButton';
import LoadingButton from 'shared/ui/loading-button/LoadingButton';

import { getPartnerPermissions } from 'routes';
import type { TrialCheckbox, TrialResponse } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';
import { selectPartner } from 'shared/state/slices/partnerSlice';

import {
  useGetTrialsByCompanyQuery,
  useGetTrialsByPartnerQuery,
} from 'shared/api/rtkq/trials';
import { useUpdateUserAccessMutation } from 'shared/api/rtkq/users';

import RadioFormControlLabel from './RadioFormControlLabel';

type Props = {
  children: ReactNode;
  handleClose: () => void;
  isOpen: boolean;
  name: string;
  setCheckBoxDisabled: (checked: boolean) => void;
  setSpecificTrials: Dispatch<SetStateAction<boolean>>;
  setTrialCheckboxes: Dispatch<SetStateAction<TrialCheckbox[]>>;
  specificTrials: boolean;
  title: string;
  trialCheckboxes: TrialCheckbox[];
  trialsByProgram: Record<string, TrialResponse[]>;
  userId: string;
  userPermissions: Record<string, boolean>;
  userTrials: string[];
};

function TrialAccessDialog(props: Props) {
  const {
    handleClose,
    isOpen,
    name,
    title,
    userId,
    userTrials,
    userPermissions,
    setCheckBoxDisabled,
    children,
    trialCheckboxes,
    setSpecificTrials,
    specificTrials,
    setTrialCheckboxes,
    trialsByProgram,
  } = props;
  const currentCompany = useSelector(selectCompany);
  const currentPartner = useSelector(selectPartner);

  const partnerPage = useMatch(getPartnerPermissions());
  const { data: trialsByCompany } = useGetTrialsByCompanyQuery(
    partnerPage ? skipToken : currentCompany.trace_id,
  );
  const { data: trialsByPartner } = useGetTrialsByPartnerQuery(
    partnerPage ? currentPartner.trace_id : skipToken,
  );

  const trialsData = partnerPage ? trialsByPartner : trialsByCompany;
  const [updateUserAccess, { isLoading: updateUserAccessLoading }] =
    useUpdateUserAccessMutation();

  useEffect(() => {
    if (isOpen) {
      const initialTrialChecked: TrialCheckbox[] =
        trialsData?.map((trial) => ({
          trialTraceId: trial.trace_id,
          programTraceId: trial.program.trace_id,
          companyTraceId: trial.company,
          checked:
            userTrials.includes(trial.trace_id) ||
            userTrials.includes('All Trials'),
        })) ?? [];

      setTrialCheckboxes(initialTrialChecked);
    }
  }, [isOpen, trialsData, userTrials, setTrialCheckboxes]);

  const selectAllCurrentAndFutureChecked = trialCheckboxes.every(
    (trial) => trial.checked,
  );

  const handleTrialCheckboxOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    trialTraceId: string,
  ) => {
    setTrialCheckboxes((prev) =>
      prev.map((trial) =>
        trial.trialTraceId === trialTraceId
          ? { ...trial, checked: event.target.checked }
          : trial,
      ),
    );
  };

  const handleProgramCheckboxOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    programTraceId: string,
  ) => {
    trialsByProgram[programTraceId].map((trial) =>
      handleTrialCheckboxOnChange(event, trial.trace_id),
    );
  };

  const handleRadioGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'all_current_and_future_trials') {
      setCheckBoxDisabled(true);
      setSpecificTrials(false);
      Object.entries(trialsByProgram).map(([programTraceId, _trials]) =>
        handleProgramCheckboxOnChange(event, programTraceId),
      );
    }

    if (event.target.value === 'existing_trials') {
      setCheckBoxDisabled(false);
      setSpecificTrials(true);
    }
  };

  const handleUpdateTrialAccess = () => {
    void (async () => {
      const trials_list = trialCheckboxes
        .filter((trial) => trial.checked)
        .map((trial) => trial.trialTraceId);

      const trialsToUpdate = selectAllCurrentAndFutureChecked
        ? specificTrials
          ? trials_list
          : []
        : trials_list;

      await updateUserAccess({ trace_id: userId, trials: trialsToUpdate });
      handleClose();
    })();
  };

  return (
    <Dialog maxWidth="sm" open={isOpen} fullWidth onClose={handleClose}>
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pb: 0 }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            color={(theme) => theme.palette.grey[800]}
            variant="body1"
          >
            {name.toUpperCase()}
          </Typography>
          <DialogContent sx={{ pl: 0, pt: 1 }}>{title}</DialogContent>
        </Box>
        <IconButton size="small" onClick={handleClose}>
          <Cancel />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ py: 3, minHeight: '250px' }}>
        <Typography sx={{ fontWeight: 'bold' }} variant="body1">
          {partnerPage
            ? 'Select which companies and trials the selected user should have access to.'
            : 'Select which trials the selected user should have access to.'}
        </Typography>
        <RadioGroup
          name="some-radio-group"
          defaultValue={() => {
            if (
              userTrials.includes('All Trials') ||
              userPermissions.canEditCompanyUsersAndPermissions
            ) {
              setCheckBoxDisabled(true);
              return 'all_current_and_future_trials';
            }

            return 'existing_trials';
          }}
          onChange={handleRadioGroupChange}
        >
          <RadioFormControlLabel
            control={<Radio />}
            value="all_current_and_future_trials"
            label={
              partnerPage
                ? 'Give user access to all current & future trials across all companies'
                : 'Give user access to all current & future trials'
            }
          />
          <RadioFormControlLabel
            control={<Radio />}
            disabled={userPermissions.canEditCompanyUsersAndPermissions}
            value="existing_trials"
            label={
              partnerPage
                ? 'Select specific companies and their trials'
                : 'Select specific trials'
            }
          />
        </RadioGroup>

        {userPermissions.canEditCompanyUsersAndPermissions ? (
          <Box>
            <Box sx={{ height: '20px' }} />
            <Box
              sx={{
                background: (theme) => theme.palette.grey[300],
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                gap: '15px',
                px: '20px',
                py: '10px',
              }}
            >
              <Box>
                <InfoOutlinedIcon color="secondary" fontSize="small" />
              </Box>
              <Box sx={{ flexDirection: 'column' }}>
                <Typography fontWeight="bold">
                  This user has the permission to manage other users and
                  permissions, so they have access to all companies and trials.
                </Typography>
                <Typography variant="body1">
                  If this user should not have access to all companies and
                  trials, you must revoke their permission to manage other
                  users.
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions>
        {!userPermissions.canEditCompanyUsersAndPermissions && (
          <LoadingButton
            disabled={trialCheckboxes.every((tcb) => !tcb.checked)}
            loading={updateUserAccessLoading}
            testId="test_update_trial_access"
            variant="contained"
            onClick={handleUpdateTrialAccess}
          >
            Update Trial Access
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default memo(TrialAccessDialog);
