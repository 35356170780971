import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { TraceId } from 'shared/lib/types';
import type { RootState } from 'shared/state/store';

type State = { editLabTraceId: TraceId | null };

const initialState: State = { editLabTraceId: null };

const siteSlice = createSlice({
  name: 'lab',
  initialState,
  reducers: {
    closeEditLab: (state) => {
      state.editLabTraceId = null;
    },
    openEditLab: (state, action: PayloadAction<{ traceId: TraceId }>) => {
      state.editLabTraceId = action.payload.traceId;
    },
  },
});

export const selectEditLabTraceId = (state: RootState) =>
  state.lab.editLabTraceId;

export const { closeEditLab, openEditLab } = siteSlice.actions;

export const labReducer = siteSlice.reducer;
