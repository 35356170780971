import type {
  PartnerResponse,
  ThirdPartyCompanyOptions,
  UserRow,
} from 'shared/lib/types';

import constructApi from './constructApi';
import { USERS_TAG } from './users';

export const PARTNERS_TAG = 'PARTNERS';

const apiEndpointFactory =
  constructApi<PartnerResponse>('companygroups').withTags(PARTNERS_TAG);
const api = apiEndpointFactory.inject(() => ({
  getPartners: apiEndpointFactory.getAll(),
  getUsersByPartner: apiEndpointFactory.getExtraRoute<UserRow[]>(
    'get_users_by_company_group',
    [USERS_TAG],
  ),
  getThirdPartyCompaniesByPartner: apiEndpointFactory.getExtraRoute<
    ThirdPartyCompanyOptions[]
  >('get_third_party_companies', [USERS_TAG]),
}));

export const {
  useGetPartnersQuery,
  useGetThirdPartyCompaniesByPartnerQuery,
  useGetUsersByPartnerQuery,
} = api;
