import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';

import Chip from 'shared/ui/chip/Chip';

type Props = {
  contractVersionCount: number;
  currency_from: string;
  currency_to: string;
  expectedFxRateCount: number;
  fxRateCount: number;
  invoiceCount: number;
  labCount: number;
  monthCloseFxAdded?: boolean;
  poCount: number;
  siteCount: number;
};

function FxRateTaskStatus(props: Props) {
  const {
    currency_from,
    currency_to,
    contractVersionCount,
    expectedFxRateCount,
    fxRateCount,
    monthCloseFxAdded,
    siteCount,
    labCount,
    poCount,
    invoiceCount,
  } = props;

  const countsMatch = expectedFxRateCount === fxRateCount;
  const avgRateColor = countsMatch ? 'success' : 'error';
  const avgRateIcon = countsMatch ? <CheckCircleIcon /> : <ErrorIcon />;
  const avgRateLabel = `${fxRateCount} / ${expectedFxRateCount} month(s)`;

  const monthCloseColor = monthCloseFxAdded ? 'success' : 'error';
  const monthCloseIcon = monthCloseFxAdded ? (
    <CheckCircleIcon />
  ) : (
    <ErrorIcon />
  );
  const monthCloseLabel = monthCloseFxAdded ? 'Added' : 'Not added';

  const labels = [];
  if (contractVersionCount > 0) {
    labels.push(`${contractVersionCount} contract(s)`);
  }
  if (siteCount > 0) {
    labels.push(`${siteCount} site(s)`);
  }
  if (labCount > 0) {
    labels.push(`${labCount} lab(s)`);
  }
  if (poCount > 0) {
    labels.push('PO listing');
  }
  if (invoiceCount > 0) {
    labels.push('Invoice listing');
  }

  return monthCloseFxAdded !== undefined ? (
    <>
      <Grid xs={6} item>
        <Typography component="span" variant="body2">
          {currency_from} → {currency_to}:
        </Typography>
        <Typography component="span" sx={{ pl: 0.5 }} variant="caption">
          {labels.join(', ')}
        </Typography>
      </Grid>
      <Grid sx={{ textAlign: 'right' }} xs={3} item>
        <Chip
          color={avgRateColor}
          icon={avgRateIcon}
          label={avgRateLabel}
          size="small"
        />
      </Grid>
      <Grid sx={{ textAlign: 'right' }} xs={3} item>
        <Chip
          color={monthCloseColor}
          icon={monthCloseIcon}
          label={monthCloseLabel}
          size="small"
        />
      </Grid>
    </>
  ) : (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      sx={{ mb: 0.5 }}
    >
      <Box>
        <Typography component="span" variant="body2">
          {currency_from} → {currency_to}:
        </Typography>
        <Typography component="span" sx={{ pl: 0.5 }} variant="caption">
          {labels.join(', ')}
        </Typography>
      </Box>
      <Chip
        color={avgRateColor}
        icon={avgRateIcon}
        label={avgRateLabel}
        size="small"
      />
    </Box>
  );
}

export default FxRateTaskStatus;
