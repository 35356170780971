import type {
  ICellEditorParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';

import {
  getCurrencyCellConfig,
  getDateCellConfig,
  getGenericCellConfig,
  getMoneyCellConfig,
  getSelectCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import type {
  AdministrativeOrProcedureCategoryResponse,
  ContractContainerResponse,
  LabGridRow,
  PatientCohortResponse,
  RegionResponse,
  SiteGridRow,
  TraceId,
} from 'shared/lib/types';
import { CostType } from 'shared/lib/types';

import LabMenu from '../components/LabMenu';
import SiteMenu from '../components/SiteMenu';
import type { SiteCostMatrixGridContext } from './useSiteCostMatrixGridOptions';
import { type LabCostMatrixGridContext } from './useLabCostMatrixGridOptions';

const REGION_WIDTH = 150;
const NUMBER_WIDTH = 100;
const NAME_WIDTH = 200;
const VENDOR_WIDTH = 300;
const COUNTRY_WIDTH = 200;
const CURRENCY_WIDTH = 120;
const PI_WIDTH = 200;
const STATUS_WIDTH = 150;

const DATE_WIDTH = 200;
const COST_WIDTH = 150;

const regionIdsForVendor = (
  vendor: TraceId,
  regions: RegionResponse[] | undefined,
): string[] => {
  const values = regions
    ?.filter((region) => region.contract_container.vendor === vendor)
    .map((region) => region.trace_id);

  return values?.length ? values : ['Unknown'];
};

const computeInvoiceableCostCategoryColDefs = (
  isOpenPeriod: boolean,
  invoiceableCostCategories:
    | AdministrativeOrProcedureCategoryResponse[]
    | undefined,
  type: 'LAB' | 'SITE',
  canEditTrialInfo: boolean,
) => {
  const invoiceableColumns =
    invoiceableCostCategories?.flatMap<CondorColDef>((category) => [
      {
        headerName: category.name,
        field: `procedure_${category.trace_id}`,
        width: COST_WIDTH,
        cellStyle: canEditTrialInfo ? 'highlightCell' : undefined,
        ...(isOpenPeriod
          ? {
              ...(type === 'SITE' ? getMoneyCellConfig() : {}),
              ...makeEditableIf(
                ({ data }) =>
                  data?.cost_type === CostType.MANUAL && canEditTrialInfo,
              ),
              cellEditorParams: { trace_id: category.trace_id },
            }
          : { ...getMoneyCellConfig() }),
      },
    ]) ?? [];

  invoiceableColumns.push({
    headerName: 'Total',
    field: 'procedure_total',
    width: COST_WIDTH,
    ...(isOpenPeriod
      ? { ...(type === 'SITE' ? getMoneyCellConfig({ useEmDash: false }) : {}) }
      : { ...getMoneyCellConfig({ useEmDash: false }) }),
  });

  return invoiceableColumns;
};

const computeVisitCostColDefs = (
  isOpenPeriod: boolean,
  patientCohorts: PatientCohortResponse[] | undefined,
  canEditTrialInfo: boolean,
) =>
  patientCohorts?.map<CondorColGroupDef>((cohort) => ({
    headerName: cohort.name,
    children: [
      ...cohort.patient_assessments.flatMap<CondorColDef>((assessment) => {
        const nameStart = `visit_cost_${cohort.trace_id}_${assessment.trace_id}`;
        return [
          {
            headerName: assessment.name,
            field: nameStart,
            width: COST_WIDTH,
            cellStyle: canEditTrialInfo ? 'highlightCell' : undefined,
            ...getMoneyCellConfig(),
            ...(isOpenPeriod && {
              ...makeEditableIf(
                ({ data }) =>
                  data?.cost_type === CostType.MANUAL && canEditTrialInfo,
              ),
              cellEditorParams: { trace_id: assessment.trace_id },
            }),
          },
        ];
      }),
      {
        headerName: 'Total patient cost',
        field: `visit_cost_${cohort.trace_id}_total`,
        width: COST_WIDTH,
        ...getMoneyCellConfig(),
      },
    ],
  })) ?? [];

export const getSiteDefs = (
  isOpenPeriod: boolean,
  contractContainers: ContractContainerResponse[] | undefined,
  regions: RegionResponse[] | undefined,
  invoiceableCostCategories:
    | AdministrativeOrProcedureCategoryResponse[]
    | undefined,
  patientCohorts: PatientCohortResponse[] | undefined,
  themeMode: 'dark' | 'light' | undefined,
  canEditTrialInfo: boolean,
  showRowMenuColumn: boolean,
): CondorColGroupDef[] => {
  // https://www.ag-grid.com/javascript-data-grid/reference-data/#using-the-refdata-property
  const containerContainerMappings = contractContainers?.reduce<{
    [key: string]: string;
  }>((acc, container) => {
    let name: string;
    if (container.vendor_name) {
      name = `${container.vendor_name}${container.po_numbers.length ? ` (PO #'s ${container.po_numbers.join(', ')})` : ''}`;
    } else {
      name = 'Unknown Vendor';
    }

    acc[container.trace_id] = name;
    return acc;
  }, {});

  const regionMappings = regions?.reduce<{ [key: string]: string }>(
    (acc, region) => {
      acc[region.trace_id] = region.name;
      return acc;
    },
    { Unknown: 'Unknown' },
  );

  const colDefs = [
    {
      headerName: '',
      children: [
        ...(isOpenPeriod && showRowMenuColumn
          ? [
              {
                sortable: false,
                resizable: false,
                width: 50,
                pinned: 'left',
                suppressHeaderMenuButton: true,
                cellClass: 'ag-cell-button',
                cellRenderer: ({
                  data,
                  context,
                }: { context: SiteCostMatrixGridContext; data: SiteGridRow }) =>
                  SiteMenu({
                    siteGridRow: data,
                    onAddNewSiteVersion: context.onAddNewSiteVersion,
                  }),
              },
            ]
          : []),
        {
          headerName: 'Site #',
          field: 'number',
          width: NUMBER_WIDTH,
          pinned: 'left',
          filter: true,
        },
        {
          headerName: 'Site name',
          field: 'name',
          width: NAME_WIDTH,
          pinned: 'left',
        },
        { field: 'site_trace_id', hide: true },
        { field: 'site_contract_trace_id', hide: true },
        {
          headerName: 'CRO / Vendor',
          field: 'contract_container',
          width: VENDOR_WIDTH,
          refData: containerContainerMappings,
          cellStyle: canEditTrialInfo ? 'highlightCell' : undefined,
          ...(isOpenPeriod
            ? {
                editable: canEditTrialInfo,
                ...getSelectCellConfig(),
                cellEditorParams: {
                  values: contractContainers?.map(
                    (container) => container.trace_id,
                  ),
                },
              }
            : { ...getGenericCellConfig() }),
        },
        {
          headerName: 'Region',
          field: 'region',
          width: REGION_WIDTH,
          refData: regionMappings,
          cellStyle: canEditTrialInfo ? 'highlightCell' : undefined,
          ...(isOpenPeriod
            ? {
                editable: canEditTrialInfo,
                ...getSelectCellConfig(),
                cellEditorParams: (params: ICellEditorParams) => ({
                  values: regionIdsForVendor(params.data.vendor, regions),
                }),
              }
            : { ...getGenericCellConfig() }),
        },
        { headerName: 'Country', field: 'country', width: COUNTRY_WIDTH },
        {
          headerName: 'Currency',
          field: 'currency',
          width: CURRENCY_WIDTH,
          cellStyle: canEditTrialInfo ? 'highlightCell' : undefined,
          ...(isOpenPeriod
            ? { editable: canEditTrialInfo, ...getCurrencyCellConfig() }
            : { ...getGenericCellConfig() }),
        },
        { headerName: 'PI name', field: 'pi_name', width: PI_WIDTH },
        { headerName: 'Status', field: 'status', width: STATUS_WIDTH },
      ],
    },
    {
      headerName: 'Cost overview',
      children: [
        {
          headerName: 'Pricing type',
          field: 'cost_type',
          width: COST_WIDTH,
          cellStyle: canEditTrialInfo ? 'highlightCell' : undefined,
          ...(isOpenPeriod
            ? {
                editable: canEditTrialInfo,
                ...getSelectCellConfig(),
                cellEditorParams(params: ICellEditorParams) {
                  return {
                    options: [
                      { value: 'M', label: 'Site' },
                      {
                        value: 'AVGCC',
                        label: 'Country/Currency',
                        disabled: params.data.can_cost_type_average === false,
                      },
                    ],
                  };
                },
                valueFormatter: (params: ValueFormatterParams) =>
                  params.value === 'M' ? 'Site' : 'Country/Currency',
              }
            : {
                ...getGenericCellConfig(),
                refData: { M: 'Site', AVGCC: 'Country/Currency' },
              }),
        },
      ],
    },
    {
      headerName: 'Site dates',
      children: [
        {
          headerName: 'Recruited',
          field: 'recruited_date',
          width: DATE_WIDTH,
          ...(isOpenPeriod
            ? { editable: canEditTrialInfo, ...getDateCellConfig() }
            : { ...getGenericCellConfig() }),
        },
        {
          headerName: 'Initiated',
          field: 'initiated_date',
          width: DATE_WIDTH,
          ...(isOpenPeriod
            ? { editable: canEditTrialInfo, ...getDateCellConfig() }
            : { ...getGenericCellConfig() }),
        },
        {
          headerName: 'Closed',
          field: 'closed_date',
          width: DATE_WIDTH,
          ...(isOpenPeriod
            ? { editable: canEditTrialInfo, ...getDateCellConfig() }
            : { ...getGenericCellConfig() }),
        },
      ],
    },
    {
      headerName: 'Contract dates',
      children: [
        {
          headerName: 'Start date',
          field: 'start_date',
          width: DATE_WIDTH,
          cellRendererSelector: 'siteMatrixContractDateRendererSelector',
          ...(isOpenPeriod
            ? {
                ...makeEditableIf(
                  ({ data }) =>
                    data?.allow_change_contract_date && canEditTrialInfo,
                  themeMode,
                ),
                ...getDateCellConfig(),
              }
            : { ...getGenericCellConfig() }),
        },
        {
          headerName: 'End date',
          field: 'end_date',
          width: DATE_WIDTH,
          ...(isOpenPeriod
            ? {
                cellRendererSelector: 'siteMatrixContractDateRendererSelector',
                ...makeEditableIf(
                  ({ data }) =>
                    data?.allow_change_contract_date && canEditTrialInfo,
                  themeMode,
                ),
                ...getDateCellConfig(),
              }
            : { ...getGenericCellConfig() }),
        },
      ],
    },
    ...computeVisitCostColDefs(isOpenPeriod, patientCohorts, canEditTrialInfo),
    {
      headerName: 'Admin Costs',
      children: [
        { headerName: '', field: 'admin_startup_category_cost', hide: true },
        { headerName: '', field: 'admin_startup_cost_category', hide: true },
        {
          headerName: 'Startup',
          field: 'admin_startup',
          width: COST_WIDTH,
          ...getMoneyCellConfig(),
          cellStyle: canEditTrialInfo ? 'highlightCell' : undefined,
          ...(isOpenPeriod && {
            ...makeEditableIf(
              ({ data }) =>
                data?.cost_type === CostType.MANUAL && canEditTrialInfo,
            ),
          }),
        },
        { headerName: '', field: 'admin_close_category_cost', hide: true },
        { headerName: '', field: 'admin_close_cost_category', hide: true },
        {
          headerName: 'Close',
          field: 'admin_close',
          width: COST_WIDTH,
          ...getMoneyCellConfig(),
          cellStyle: canEditTrialInfo ? 'highlightCell' : undefined,
          ...(isOpenPeriod && {
            ...makeEditableIf(
              ({ data }) =>
                data?.cost_type === CostType.MANUAL && canEditTrialInfo,
            ),
          }),
        },
        { headerName: '', field: 'admin_annual_category_cost', hide: true },
        { headerName: '', field: 'admin_annual_cost_category', hide: true },
        {
          headerName: 'Annual',
          field: 'admin_annual',
          width: COST_WIDTH,
          ...getMoneyCellConfig(),
          cellStyle: canEditTrialInfo ? 'highlightCell' : undefined,
          ...(isOpenPeriod && {
            ...makeEditableIf(
              ({ data }) =>
                data?.cost_type === CostType.MANUAL && canEditTrialInfo,
            ),
          }),
        },
        { headerName: '', field: 'admin_quarterly_category_cost', hide: true },
        { headerName: '', field: 'admin_quarterly_cost_category', hide: true },
        {
          headerName: 'Quarterly',
          field: 'admin_quarterly',
          width: COST_WIDTH,
          ...getMoneyCellConfig(),
          cellStyle: canEditTrialInfo ? 'highlightCell' : undefined,
          ...(isOpenPeriod && {
            ...makeEditableIf(
              ({ data }) =>
                data?.cost_type === CostType.MANUAL && canEditTrialInfo,
            ),
          }),
        },
        { headerName: '', field: 'admin_monthly_category_cost', hide: true },
        { headerName: '', field: 'admin_monthly_cost_category', hide: true },
        {
          headerName: 'Monthly',
          field: 'admin_monthly',
          width: COST_WIDTH,
          ...getMoneyCellConfig(),
          cellStyle: canEditTrialInfo ? 'highlightCell' : undefined,
          ...(isOpenPeriod && {
            ...makeEditableIf(
              ({ data }) =>
                data?.cost_type === CostType.MANUAL && canEditTrialInfo,
            ),
          }),
        },
        {
          headerName: 'Total',
          field: 'admin_total',
          width: COST_WIDTH,
          ...getMoneyCellConfig(),
        },
      ],
    },
  ];

  if (invoiceableCostCategories?.length) {
    colDefs.push({
      headerName: 'Procedure Costs',
      children: computeInvoiceableCostCategoryColDefs(
        isOpenPeriod,
        invoiceableCostCategories,
        'SITE',
        canEditTrialInfo,
      ),
    });
  }

  return colDefs as CondorColGroupDef[];
};

export const getLabDefs = (
  isOpenPeriod: boolean,
  contractContainers: ContractContainerResponse[] | undefined,
  patientCohorts: PatientCohortResponse[] | undefined,
  canEditTrialInfo: boolean,
  showRowMenuColumn: boolean,
): CondorColGroupDef[] => {
  // https://www.ag-grid.com/javascript-data-grid/reference-data/#using-the-refdata-property
  const containerContainerMappings = contractContainers?.reduce<{
    [key: string]: string;
  }>((acc, container) => {
    const name = container.vendor_name
      ? `${container.vendor_name}${container.po_numbers.length ? ` (PO #'s ${container.po_numbers.join(', ')})` : ''}`
      : 'Unknown Vendor';

    acc[container.trace_id] = name;
    return acc;
  }, {});

  return [
    {
      headerName: '',
      children: [
        ...(isOpenPeriod && showRowMenuColumn
          ? [
              {
                sortable: false,
                resizable: false,
                width: 50,
                pinned: true,
                suppressHeaderMenuButton: true,
                cellClass: 'ag-cell-button',
                cellRenderer: ({
                  data,
                  context,
                }: { context: LabCostMatrixGridContext; data: LabGridRow }) =>
                  LabMenu({
                    labGridRow: data,
                    onAddNewLabVersion: context.onAddNewLabVersion,
                  }),
              },
            ]
          : []),
        {
          headerName: 'Lab name',
          field: 'name',
          width: NAME_WIDTH,
          pinned: 'left',
        },
        { field: 'site_trace_id', hide: true },
        { field: 'lab_contract_trace_id', hide: true },
        {
          headerName: 'Vendor',
          field: 'contract_container',
          width: VENDOR_WIDTH,
          refData: containerContainerMappings,
          cellStyle: canEditTrialInfo ? 'highlightCell' : undefined,
          ...(isOpenPeriod
            ? {
                editable: canEditTrialInfo,
                ...getSelectCellConfig(),
                cellEditorParams: {
                  values: contractContainers?.map(
                    (container) => container.trace_id,
                  ),
                },
              }
            : { ...getGenericCellConfig() }),
        },
        {
          headerName: 'Currency',
          field: 'currency',
          width: CURRENCY_WIDTH,
          cellStyle: canEditTrialInfo ? 'highlightCell' : undefined,
          ...(isOpenPeriod
            ? { editable: canEditTrialInfo, ...getCurrencyCellConfig() }
            : { ...getGenericCellConfig() }),
        },
      ],
    },
    {
      headerName: 'Cost overview',
      children: [
        {
          headerName: 'Pricing type',
          field: 'cost_type',
          width: COST_WIDTH,
          // force anything that could show here to be blank as
          // all the costs are currently wired up to look for
          // a value of M and it's easier to hide the data than
          // change all the costs to conditionally look for M
          // OR figure out it's part of the lab grid
          refData: { M: '', AVGCC: '' },
          ...(!isOpenPeriod && { ...getGenericCellConfig() }),
        },
      ],
    },
    {
      headerName: 'Contract dates',
      children: [
        {
          headerName: 'Start date',
          field: 'start_date',
          width: DATE_WIDTH,
          ...(isOpenPeriod
            ? { editable: canEditTrialInfo, ...getDateCellConfig() }
            : { ...getGenericCellConfig() }),
        },
        {
          headerName: 'End date',
          field: 'end_date',
          width: DATE_WIDTH,
          ...(isOpenPeriod
            ? { editable: canEditTrialInfo, ...getDateCellConfig() }
            : { ...getGenericCellConfig() }),
        },
      ],
    },
    ...computeVisitCostColDefs(isOpenPeriod, patientCohorts, canEditTrialInfo),
  ];
};
