import type { ChangeEvent } from 'react';
import { useEffect } from 'react';

import Box from '@mui/material/Box';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import Autocomplete from 'shared/ui/autocomplete/Autocomplete';

import { getPartnerPermissions } from 'routes';
import type {
  DropdownOption,
  ThirdPartyCompanyOptions,
  UserAddForm,
} from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';
import { selectPartner } from 'shared/state/slices/partnerSlice';

import { useGetThirdPartyCompaniesByCompanyQuery } from 'shared/api/rtkq/companies';
import { useGetThirdPartyCompaniesByPartnerQuery } from 'shared/api/rtkq/partners';

type Props = {
  companyOptions: ThirdPartyCompanyOptions[];
  handleEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleGenericFieldOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleThirdPartyCompanyChange: (
    value: DropdownOption<string>,
    fieldName: string,
  ) => void;
  inputErrors: Record<string, boolean | string>;
  setCompanyOptions: (companyOptions: ThirdPartyCompanyOptions[]) => void;
  user: UserAddForm;
  onAddNewThirdPartyCompany: (companyName: string) => void;
};

function UserInviteForm(propsData: Props) {
  const {
    inputErrors,
    user,
    handleGenericFieldOnChange,
    handleEmailChange,
    handleThirdPartyCompanyChange,
    companyOptions,
    setCompanyOptions,
    onAddNewThirdPartyCompany,
  } = propsData;

  const currentCompany = useSelector(selectCompany);
  const currentPartner = useSelector(selectPartner);
  const partnerLevelPage = useMatch(getPartnerPermissions());
  const { currentData: companyData } = useGetThirdPartyCompaniesByCompanyQuery(
    partnerLevelPage ? skipToken : currentCompany.trace_id,
  );
  const { currentData: partnerData } = useGetThirdPartyCompaniesByPartnerQuery(
    partnerLevelPage ? currentPartner.trace_id : skipToken,
  );
  const thirdPartyCompaniesData = partnerLevelPage ? partnerData : companyData;
  const thirdPartyCompanyLabel = partnerLevelPage ? 'Organization' : 'Company';

  useEffect(() => {
    if (thirdPartyCompaniesData !== undefined) {
      setCompanyOptions(
        thirdPartyCompaniesData.map((thirdPartyCompany) => ({
          value: thirdPartyCompany.value,
          label: thirdPartyCompany.label,
        })),
      );
    }
  }, [setCompanyOptions, thirdPartyCompaniesData]);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.value.length > 0) {
      onAddNewThirdPartyCompany(target.value);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        gap: '24px',
        py: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          gap: '16px',
        }}
      >
        <CondorTextField
          autoComplete="off"
          label="First Name"
          name="first_name"
          size="small"
          type="text"
          value={user.first_name}
          fullWidth
          required
          onChange={handleGenericFieldOnChange}
        />
        <CondorTextField
          autoComplete="off"
          label="Last Name"
          name="last_name"
          size="small"
          type="text"
          value={user.last_name}
          fullWidth
          required
          onChange={handleGenericFieldOnChange}
        />
      </Box>
      <CondorTextField
        disabled={user.traceId !== undefined}
        errors={inputErrors.email}
        label="Email"
        size="small"
        type="email"
        value={user.email}
        variant="outlined"
        required
        onChange={handleEmailChange}
      />
      <CondorTextField
        autoComplete="off"
        label="Title"
        name="title"
        size="small"
        type="text"
        value={user.title}
        variant="outlined"
        onChange={handleGenericFieldOnChange}
      />
      <Autocomplete
        label={thirdPartyCompanyLabel}
        noOptionsText="Hit enter to add company"
        size="small"
        sx={{ flexGrow: 1 }}
        value={user.third_party_company}
        isOptionEqualToValue={(
          option: DropdownOption<string>,
          value: DropdownOption<string>,
        ) => option.value === value.value}
        options={companyOptions.map((companyOption) => ({
          value: companyOption.value,
          label: companyOption.label,
        }))}
        renderInput={(params) => (
          <CondorTextField
            {...params}
            label={thirdPartyCompanyLabel}
            size="small"
            required
          />
        )}
        filterSelectedOptions
        freeSolo
        required
        selectOnFocus
        onBlur={onBlur}
        onChange={(_, value) => {
          if (value !== null && typeof value !== 'string') {
            handleThirdPartyCompanyChange(value, 'third_party_company');
          }
        }}
      />
    </Box>
  );
}

export default UserInviteForm;
