import { type ReactElement, useState } from 'react';

import { useSelector } from 'react-redux';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Box from '@mui/material/Box';

import Button from 'shared/ui/button/Button';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';
import { selectCompany } from 'shared/state/slices/companySlice';
import PurchaseOrdersUploadWizard from 'shared/wizards/purchase-order/PurchaseOrdersUploadWizard';

function HistoricPurchaseOrdersHeader(): ReactElement {
  const currentCompany = useSelector(selectCompany);
  const [showDialog, setShowDialog] = useState(false);

  return (
    <HasPermission permissions={['canEditCompanyLevelInfo']}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 1,
          mb: 2,
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <Button
          startIcon={<FileUploadOutlinedIcon />}
          sx={{ alignSelf: 'flex-end' }}
          testId="upload_historic_purchase_orders"
          variant="contained"
          onClick={() => setShowDialog(true)}
        >
          Upload historic
        </Button>
        {showDialog && (
          <PurchaseOrdersUploadWizard
            companyTraceId={currentCompany.trace_id}
            onClose={() => setShowDialog(false)}
          />
        )}
      </Box>
    </HasPermission>
  );
}

export default withPeriodSpecificGenericWrapper(HistoricPurchaseOrdersHeader);
