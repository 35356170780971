import { useMemo, useState } from 'react';

import Box from '@mui/material/Box';

import CommentsButton from 'shared/components/comments/CommentsButton';
import usePageCommentsCountLoader from 'shared/components/comments/hooks/usePageCommentsCountLoader';
import LockGridButton from 'shared/components/lock-grid/LockGridButton';
import LockGridReminder from 'shared/components/lock-grid/LockGridReminder';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import type { CurrencyViewMode } from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import CurrencyToggleGroup from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import { CommentLocationType, LockGrid } from 'shared/lib/types';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import OccTabs from './OccTabs';
import OccActivityDriverCounts from './components/OccActivityDriverCounts';
import OccExpenseGrid from './grids/OccExpenseGrid';

function OccExpensesTab() {
  const [currencyViewMode, setCurrencyViewMode] =
    useState<CurrencyViewMode>('trial');
  const userDisplayOptions = useMemo(
    () => ({
      currencyViewMode,
    }),
    [currencyViewMode],
  );
  const commentsCount = usePageCommentsCountLoader(
    CommentLocationType.OCC_EXPENSE,
  );

  return (
    <PageContentLoadingContainer tabs={<OccTabs />}>
      <FlexGridContainer>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <OccActivityDriverCounts />
          <CurrencyToggleGroup
            setValue={setCurrencyViewMode}
            testId="OCCExpenseGridToggle"
            value={currencyViewMode}
          />
          <CommentsButton
            commentsCount={commentsCount}
            location={CommentLocationType.OCC_EXPENSE}
          />
          <LockGridButton grid={LockGrid.OCC_EXPENSE_GRID} />
        </Box>
        <OccExpenseGrid
          sx={useMemo(() => ({ height: '100%' }), [])}
          userDisplayOptions={userDisplayOptions}
        />
        <LockGridReminder grid={LockGrid.OCC_EXPENSE_GRID} />
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(OccExpensesTab);
