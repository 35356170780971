import type { ReactNode } from 'react';

import Box from '@mui/material/Box';

function ChecklistGridRow({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns:
          'minmax(0, 3fr) minmax(0, 4fr) minmax(0, 3fr) minmax(0, 1fr)',
        gap: '1px',
        '& > *': {
          px: 2,
          py: 2,
          backgroundColor: (theme) => theme.palette.background.paper,
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        },
        '&:nth-of-type(even) > *': {
          backgroundColor: (theme) => theme.palette.grey[100],
          backgroundImage: 'none',
        },
      }}
    >
      {children}
    </Box>
  );
}

export default ChecklistGridRow;
