import { type ReactElement, useMemo, useState } from 'react';

import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';

import HorizontalInput from 'shared/components/horizontal-input/HorizontalInput';
import CondorTextField from 'shared/components/text-field/CondorTextField';
import Select from 'shared/ui/select/Select';

import currencies from 'currencies';
import type { TrialRequest } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';
import { selectTrial, useChangeTrial } from 'shared/state/slices/trialSlice';
import WizardStep from 'shared/wizards/steps/WizardStep';

import {
  useGetTrialsByCompanyQuery,
  useUpdateTrialMutation,
} from 'shared/api/rtkq/trials';

type InputRow = {
  label: string;
  helperText?: string;
  component: React.ReactNode;
  required?: boolean;
};

export default function TrialRecordEditStep(): ReactElement {
  const currentCompany = useSelector(selectCompany);
  const trial = useSelector(selectTrial);

  const { data: trialsByCompany } = useGetTrialsByCompanyQuery(
    currentCompany.trace_id,
  );
  const [updateTrial] = useUpdateTrialMutation();
  const changeTrial = useChangeTrial();
  const [trialRecord, setTrialRecord] = useState<TrialRequest>({
    ...trial,
    program: trial.program.trace_id,
  });
  const { refetch: refetchAllTrials } = useGetTrialsByCompanyQuery(
    currentCompany.trace_id,
  );

  const isValid = !!(
    trialRecord.study_id &&
    trialRecord.program &&
    trialRecord.phase &&
    trialRecord.indication &&
    trialRecord.currency
  );

  const programs = useMemo(
    () =>
      orderBy(
        uniqBy(
          trialsByCompany?.map(({ program }) => program),
          'trace_id',
        ),
        [(po) => po.name.toLowerCase()],
      ),
    [trialsByCompany],
  );

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRecord = {
      ...trialRecord,
      [event.target.name]: event.target.value,
    };
    setTrialRecord(newRecord);
  };

  const inputFields: InputRow[] = [
    {
      label: 'Trial ID',
      required: true,
      component: (
        <CondorTextField
          key="trialId"
          name="study_id"
          size="small"
          value={trialRecord.study_id ?? ''}
          disabled
          fullWidth
        />
      ),
    },
    {
      label: 'Trial name',
      component: (
        <CondorTextField
          name="name"
          size="small"
          value={trialRecord.name ?? ''}
          fullWidth
          onChange={onFieldChange}
        />
      ),
    },
    {
      label: 'Program',
      required: true,
      component: (
        <Select
          name="program"
          size="small"
          value={trialRecord.program ?? ''}
          fullWidth
          required
          onChange={onFieldChange}
        >
          {programs.map(({ trace_id, name }) => (
            <MenuItem key={trace_id} value={trace_id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      label: 'Phase',
      required: true,
      component: (
        <Select
          name="phase"
          size="small"
          value={trialRecord.phase?.toString() ?? ''}
          fullWidth
          required
          onChange={onFieldChange}
        >
          <MenuItem value="1">Phase 1</MenuItem>
          <MenuItem value="1/2">Phase 1/2</MenuItem>
          <MenuItem value="2">Phase 2</MenuItem>
          <MenuItem value="2/3">Phase 2/3</MenuItem>
          <MenuItem value="3">Phase 3</MenuItem>
          <MenuItem value="4">Phase 4</MenuItem>
        </Select>
      ),
    },
    {
      label: 'Indication',
      required: true,
      component: (
        <CondorTextField
          name="indication"
          placeholder="Add indication"
          size="small"
          value={trialRecord.indication ?? ''}
          fullWidth
          required
          onChange={onFieldChange}
        />
      ),
    },
    {
      label: 'Default trial currency',
      required: true,
      component: (
        <Select
          name="currency"
          size="small"
          value={trialRecord.currency ?? ''}
          fullWidth
          required
          onChange={onFieldChange}
        >
          {currencies.map((currency) => (
            <MenuItem key={currency} value={currency}>
              {currency}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      label: 'ClinicalTrials.gov ID',
      component: (
        <Stack spacing={1}>
          <CondorTextField
            name="national_clinical_trial_number"
            size="small"
            value={trialRecord.national_clinical_trial_number ?? ''}
            onChange={onFieldChange}
          />
        </Stack>
      ),
    },
  ];

  async function onSave() {
    const updatedTrial = await updateTrial({
      trace_id: trialRecord.trace_id,
      program: trialRecord.program,
      ...trialRecord,
    }).unwrap();

    changeTrial(updatedTrial);
    void refetchAllTrials();
  }

  return (
    <WizardStep
      disableNextButton={!isValid}
      contentSx={{
        mt: 4,
      }}
      onNextAsync={onSave}
    >
      <form>
        <Container maxWidth="sm">
          <Stack spacing={3}>
            {inputFields.map(({ label, component, required, helperText }) => (
              <HorizontalInput
                key={label}
                helperText={helperText}
                label={label}
                required={required}
              >
                {component}
              </HorizontalInput>
            ))}
          </Stack>
        </Container>
      </form>
    </WizardStep>
  );
}
