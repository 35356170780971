import type {
  TrialRegionMetadataResponse,
  TrialRegionResponse,
} from 'shared/lib/types';

import constructApi from './constructApi';

const REGIONS = 'TRIAL_REGIONS';
const REGIONS_METADATA = 'TRIAL_REGIONS_METADATA';

const apiEndpointFactory =
  constructApi<TrialRegionResponse>('trialregions').withTags(REGIONS);
const api = apiEndpointFactory.inject(() => ({
  getRegionsWithFilters: apiEndpointFactory.getWithFilters(),
  getRegionsMetadata: apiEndpointFactory.getExtraRoute<
    TrialRegionMetadataResponse[]
  >('regions_metadata', [REGIONS_METADATA]),
  createRegion: apiEndpointFactory.create([REGIONS_METADATA]),
  updateRegion: apiEndpointFactory.update([REGIONS_METADATA]),
}));

export const {
  useGetRegionsWithFiltersQuery,
  useGetRegionsMetadataQuery,
  useCreateRegionMutation,
  useUpdateRegionMutation,
} = api;
