import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import createToast from 'shared/components/toast/createToast';

/* eslint-disable import/no-restricted-paths -- this is where we setup the global state */
import { periodReducer } from 'accruals/state/slices/periodSlice';
import { periodVersionReducer } from 'accruals/state/slices/periodVersionSlice';
import { forecastReducer } from 'forecasting/state/slices/forecastSlice';
/* eslint-enable import/no-restricted-paths */
import { activeWizardOnLoadReducer } from 'shared/state/slices/activeWizardOnLoadSlice';
import { companyReducer } from 'shared/state/slices/companySlice';
import { modalsReducer } from 'shared/state/slices/modalsSlice';
import { sidebarReducer } from 'shared/state/slices/sidebarSlice';
import { trialReducer } from 'shared/state/slices/trialSlice';
import { visitSchedulesReducer } from 'shared/state/slices/visitSchedulesSlice';

import baseApi from 'shared/api/baseApi';

import { commentReducer } from './slices/commentSlice';
import { partnerReducer } from './slices/partnerSlice';
import { siteReducer } from './slices/siteSlice';
import { labReducer } from './slices/labSlice';
import { userManagementReducer } from './slices/userManagementSlice';
import { userHasAnyPermission, userReducer } from './slices/userSlice';

const rtkQueryErrorLogger: Middleware =
  (store: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action) && userHasAnyPermission(store.getState())) {
      toast.error(createToast(action));
    }

    return next(action);
  };

// please keep alphasorted
const reducer = {
  activeWizardOnLoad: activeWizardOnLoadReducer,
  api: baseApi.reducer,
  comment: commentReducer,
  company: companyReducer,
  partner: partnerReducer,
  forecast: forecastReducer,
  modals: modalsReducer,
  period: periodReducer,
  periodVersion: periodVersionReducer,
  sidebar: sidebarReducer,
  site: siteReducer,
  lab: labReducer,
  user: userReducer,
  userManagement: userManagementReducer,
  trial: trialReducer,
  visitSchedules: visitSchedulesReducer,
};

// This ended up creating messages that were too big to send to Sentry, but keeping here "in case" so we know what would need to get done if we want to selectively send data
// https://develop.sentry.dev/sdk/envelopes/#size-limits
// const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    // oxlint-disable-next-line prefer-spread -- https://github.com/oxc-project/oxc/issues/6034
    getDefaultMiddleware().concat(baseApi.middleware, rtkQueryErrorLogger),
  // This ended up creating messages that were too big to send to Sentry, but keeping here "in case" so we know what would need to get done if we want to selectively send data
  // enhancers: [sentryReduxEnhancer],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export default store;
