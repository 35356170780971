import Edit from '@mui/icons-material/EditOutlined';

import DashboardButton from 'shared/ui/dashboard-button/DashboardButton';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';

type Props = { testId: string; onClick?: () => void };

function EditButton(props: Props) {
  const { testId, onClick } = props;

  return (
    <DashboardButton startIcon={<Edit />} testId={testId} onClick={onClick}>
      Edit
    </DashboardButton>
  );
}

export default withPeriodSpecificGenericWrapper(EditButton);
