import { useMemo } from 'react';

import PurchaseOrdersHeader from './components/PurchaseOrdersHeader';
import PurchaseOrdersGrid from './grids/PurchaseOrdersGrid';

function PurchaseOrders() {
  return (
    <>
      <PurchaseOrdersHeader />
      <PurchaseOrdersGrid
        overlayNoRowsTemplate="No purchase orders."
        sx={useMemo(() => ({ height: '100%' }), [])}
      />
    </>
  );
}

export default PurchaseOrders;
