import { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query/react';

import type { LabRequest } from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';
import { selectTrial } from 'shared/state/slices/trialSlice';
import { selectEditLabTraceId } from 'shared/state/slices/labSlice';

import useAddLab from 'shared/api/hooks/useAddLab';
import { useGetLabQuery, useUpdateLabMutation } from 'shared/api/rtkq/labs';

import LabInfoStep from './steps/LabInfoStep';

type Props = { title?: string; onClose: () => void; onSave?: () => void };

function AddLabWizard(props: Props) {
  const trial = useSelector(selectTrial);
  const { title, onClose, onSave } = props;
  const editLabIsOpen = useSelector(selectEditLabTraceId);

  const { currentData: labToEdit } = useGetLabQuery(editLabIsOpen ?? skipToken);
  const [labRecord, setLabRecord] = useState<LabRequest>({});
  const [addLab, { isLoading }] = useAddLab();
  const [updateLab, { isLoading: isUpdating }] = useUpdateLabMutation();

  useEffect(() => {
    setLabRecord({ ...labToEdit });
  }, [labToEdit]);

  async function saveLab() {
    if (labRecord.trace_id) {
      await updateLab(labRecord);
    } else {
      await addLab({ trial: trial.trace_id, ...labRecord });
    }
    onSave?.();
  }

  return (
    <Dialog open>
      <Wizard title={title ?? 'New lab'} onClose={onClose}>
        <LabInfoStep
          disableNextButton={isLoading || isUpdating}
          labRecord={labRecord}
          setLabRecord={setLabRecord}
          onSave={() => void saveLab()}
        />
      </Wizard>
    </Dialog>
  );
}

export default AddLabWizard;
