import type { ChangeEvent } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel, {
  formControlLabelClasses,
} from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import Checkbox from 'shared/ui/checkbox/Checkbox';

import type { TrialResponse } from 'shared/lib/types';

type TrialCheckbox = {
  checked: boolean;
  trialTraceId: string;
  programTraceId: string;
};

type TrialAccessCheckboxComponentProps = {
  allowScroll: boolean;
  checkBoxDisabled: boolean;
  handleProgramCheckboxOnChange: (
    event: ChangeEvent<HTMLInputElement>,
    programTraceId: string,
  ) => void;
  handleTrialCheckboxOnChange: (
    event: ChangeEvent<HTMLInputElement>,
    trialTraceId: string,
  ) => void;
  moveRight: boolean;
  trialCheckboxes: TrialCheckbox[];
  trialsByProgram: Array<{
    programTraceId: string;
    programName: string;
    trials: TrialResponse[];
  }>;
};

export default function TrialAccessCheckboxComponent(
  props: TrialAccessCheckboxComponentProps,
) {
  const {
    moveRight,
    allowScroll,
    checkBoxDisabled,
    handleProgramCheckboxOnChange,
    handleTrialCheckboxOnChange,
    trialCheckboxes,
    trialsByProgram,
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pl: moveRight ? 4 : undefined,
        overflowY: allowScroll ? 'scroll' : undefined,
        maxHeight: allowScroll ? '500px' : undefined,
      }}
    >
      {trialsByProgram.map(({ programTraceId, programName, trials }) => {
        const trialCheckboxesForProgram = trialCheckboxes.filter(
          (trial) => trial.programTraceId === programTraceId,
        );
        const programChecked = trialCheckboxesForProgram.every(
          (trial) => trial.checked,
        );
        const programIndeterminate =
          !programChecked &&
          trialCheckboxesForProgram.some((trial) => trial.checked);

        return (
          <Box key={programTraceId}>
            <Divider
              sx={{
                paddingTop: '4px',
                borderBottom: '1px lightgrey solid',
              }}
            />
            <FormControlLabel
              label={programName}
              control={
                <Checkbox
                  checked={programChecked}
                  disabled={checkBoxDisabled}
                  indeterminate={programIndeterminate}
                  onChange={(event) =>
                    handleProgramCheckboxOnChange(event, programTraceId)
                  }
                />
              }
              sx={{
                [`.${formControlLabelClasses.label}`]: { fontWeight: 'bold' },
              }}
            />
            {trials.map((trial) => (
              <Box
                key={trial.trace_id}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}>
                  <FormControlLabel
                    label={trial.study_id}
                    control={
                      <Checkbox
                        disabled={checkBoxDisabled}
                        checked={
                          trialCheckboxes.find(
                            (trialCheckbox) =>
                              trialCheckbox.trialTraceId === trial.trace_id,
                          )?.checked ?? false
                        }
                        onChange={(event) =>
                          handleTrialCheckboxOnChange(event, trial.trace_id)
                        }
                      />
                    }
                  />
                  <Typography
                    sx={{
                      pl: 4,
                      color: (theme) =>
                        checkBoxDisabled
                          ? theme.palette.action.disabled
                          : theme.palette.secondary.main,
                    }}
                  >
                    {trial.indication}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    borderRadius: 1,
                    backgroundColor: (theme) => theme.palette.grey[100],
                    p: 0.5,
                  }}
                >
                  Phase {trial.phase}
                </Typography>
              </Box>
            ))}
          </Box>
        );
      })}
    </Box>
  );
}
