import { cloneElement, useState } from 'react';

import { getPopperUtilityClass } from '@mui/base/Popper';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import type { PopperPlacementType } from '@mui/material/Popper';
import MuiPopper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';

import { useCommentsPopup } from './CommentsPopupContext';

type Props = {
  arrow?: boolean;
  children?: React.ReactElement;
  content: React.ReactElement;
  open: boolean;
  placement?: PopperPlacementType;
  onClose?: () => void;
};

const Popper = styled(MuiPopper)(({ theme }) => ({
  zIndex: 1,
  '& > div': { position: 'relative' },
  '&[data-popper-placement*="left"]': {
    '& > div': { paddingBottom: 30 },
    [`& .${getPopperUtilityClass('arrow')}`]: {
      right: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
}));

const Arrow = styled('div')({
  position: 'absolute',
  fontSize: 7,
  width: '3em',
  height: '3em',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
  },
});

function CommentsPopper(props: Props) {
  const {
    placement = 'left',
    arrow = true,
    open,
    onClose = () => ({}),
    content,
    children,
  } = props;
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [childNode, setChildNode] = useState<HTMLElement | null>(null);
  const anchorElement = useCommentsPopup().anchorEl;

  return (
    <>
      {children &&
        cloneElement(children, { ...children.props, ref: setChildNode })}
      <Popper
        anchorEl={anchorElement ?? childNode}
        open={open}
        placement={placement}
        modifiers={[
          { name: 'offset', options: { offset: [0, 10] } },
          { name: 'arrow', enabled: true, options: { element: arrowRef } },
        ]}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <div>
              {arrow ? (
                <Arrow
                  ref={setArrowRef}
                  className={getPopperUtilityClass('arrow')}
                />
              ) : null}
              <Paper elevation={10}>
                <ClickAwayListener onClickAway={onClose}>
                  <Paper>
                    <Box>{content}</Box>
                  </Paper>
                </ClickAwayListener>
              </Paper>
            </div>
          </Fade>
        )}
      </Popper>
    </>
  );
}

export default CommentsPopper;
