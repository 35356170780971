import type { PatientAssessment, PatientCohort } from 'shared/lib/types';

const MAPPING_ERROR =
  'You selected a different EDC visit name for the same visit name in a different cohort';

const isCohortNameUnique = (
  cohorts: PatientCohort[],
  name: string,
  index: number,
) =>
  cohorts.find((cohort, i) => cohort.name === name && i < index) === undefined;

export const validatePatientCohortName = (
  cohorts: PatientCohort[],
  name: string,
  index: number,
) => {
  if (!name) {
    return 'Cohort name is required';
  }

  if (!isCohortNameUnique(cohorts, name, index)) {
    return 'Cohort name is already in use';
  }
};

export const validateEdcVisitMapping = (
  patientCohorts: PatientCohort[],
  visit: PatientAssessment,
  validEdcNames: Set<string>,
) => {
  if (visit.pizzaName && !validEdcNames.has(visit.pizzaName)) {
    return `Unrecognized mapping (${visit.pizzaName}) not found in EDC visits`;
  }
  if (
    patientCohorts.some((cohort) =>
      cohort.patientAssessments.some(
        (otherVisit) =>
          otherVisit.name === visit.name &&
          otherVisit.mappedName &&
          visit.mappedName && // We are intentionally comparing these to both empty and undefined
          otherVisit.mappedName !== visit.mappedName,
      ),
    )
  ) {
    return MAPPING_ERROR;
  }
  return '';
};
