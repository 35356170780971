import { useRef } from 'react';

import Box from '@mui/system/Box';
import { useDispatch } from 'react-redux';

import type { CommentLocationType } from 'shared/lib/types';
import {
  updateHeader,
  updateIsOpen,
  updateLocation,
  updateLocationSlug,
  updateTitle,
} from 'shared/state/slices/commentSlice';

import { useCommentsPopup } from './CommentsPopupContext';
import CommentsWidgetIcon from './CommentsWidgetIcon';

type Props = {
  commentsCount: number;
  commentsForPage?: boolean;
  header?: string;
  location: CommentLocationType;
  locationSlug?: string;
  title?: string;
};

function CommentsButton(props: Props) {
  const {
    location,
    commentsForPage,
    locationSlug = '',
    header = 'Comments',
    title = null,
    commentsCount,
  } = props;
  const dispatch = useDispatch();
  const buttonRef = useRef<HTMLElement>(null);
  const { setAnchorEl } = useCommentsPopup();
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(updateHeader({ header }));
    dispatch(updateTitle({ title }));
    dispatch(updateLocation({ location }));
    dispatch(updateLocationSlug({ locationSlug }));
    setAnchorEl(buttonRef.current);
    dispatch(updateIsOpen({ isOpen: true }));
  };

  return (
    <Box
      ref={buttonRef}
      sx={
        commentsForPage
          ? { position: 'fixed', right: '35px', bottom: '25px' }
          : null
      }
    >
      <CommentsWidgetIcon commentsCount={commentsCount} onClick={onClick} />
    </Box>
  );
}

export default CommentsButton;
