import { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';

import KabobMenu from 'shared/components/kabob-menu/KabobMenu';
import Modal from 'shared/components/modal/Modal';
import NavigationTabs, {
  NavigationTabsStyle,
} from 'shared/components/tabs/NavigationTabs';

import {
  getClinicalExpenses,
  getClinicalExpensesSubTabByContractContainer,
  getClinicalExpensesTabByContractContainer,
} from 'routes';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import { CRO_CONTRACT_TABS } from 'shared/helpers/helpers';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';
import { useDeleteContractContainerMutation } from 'shared/api/rtkq/contractcontainers';

import useContractAndPeriodWithVersions from '../shared/hooks/useContractAndPeriodWithVersions';

function CroContractTabs() {
  const navigate = useNavigate();
  const { contractContainerId, activeTab, activeSubTab } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isSoftDeleteEnabled = useFeatureFlag('soft_delete');
  const canDeleteTrialInfo = useHasPermission(['canDeleteTrialInfo']);
  const isClosedPeriod = useIsClosedPeriodVersion();

  const [deleteContractContainer, { isLoading: isDeletingContractContainer }] =
    useDeleteContractContainerMutation();
  const { contractContainerTraceId, contractContainer } =
    useContractAndPeriodWithVersions();

  const handleContainerDelete = async () => {
    if (contractContainerTraceId) {
      await deleteContractContainer(contractContainerTraceId);
      navigate(getClinicalExpenses());
    }
    setIsModalOpen(false);
  };

  const croContractTabs = [
    {
      key: CRO_CONTRACT_TABS.OVERVIEW,
      devLabel: 'FRANCE-CRO',
      label: 'Overview',
    },
    {
      key: CRO_CONTRACT_TABS.DIRECT_FEES,
      devLabel: 'ODIN',
      label: 'Direct Fees',
    },
    {
      key: CRO_CONTRACT_TABS.PASS_THROUGHS,
      devLabel: 'THOR',
      label: 'Pass-Throughs',
    },
    {
      key: CRO_CONTRACT_TABS.INVESTIGATOR_FEES,
      devLabel: 'SHERLOCK',
      label: 'Investigator Fees',
    },
    {
      key: CRO_CONTRACT_TABS.CURRENT_CONTRACT,
      devLabel: 'PARIS-CRO',
      label: 'Current Contract',
    },
    {
      key: CRO_CONTRACT_TABS.AMENDMENT_IN_PROGRESS,
      devLabel: 'HAMILTON-new',
      label: 'Amendment-In-Progress',
    },
  ];

  return (
    <Box sx={{ display: 'flex', alignItems: 'stretch' }}>
      <NavigationTabs
        activeTab={activeTab}
        tabs={croContractTabs}
        variant="scrollable"
        generateUrl={(newTab) =>
          [
            CRO_CONTRACT_TABS.DIRECT_FEES,
            CRO_CONTRACT_TABS.PASS_THROUGHS,
            CRO_CONTRACT_TABS.INVESTIGATOR_FEES,
          ].includes(newTab)
            ? getClinicalExpensesSubTabByContractContainer(
                contractContainerId,
                newTab,
                activeSubTab ?? 'expense',
              )
            : getClinicalExpensesTabByContractContainer(
                contractContainerId,
                newTab,
              )
        }
      />
      <Box
        sx={{
          ...NavigationTabsStyle,
          display: 'flex',
          pr: 3,
          flexGrow: 1,
          justifyContent: 'right',
        }}
      >
        {!isClosedPeriod && isSoftDeleteEnabled && canDeleteTrialInfo && (
          <KabobMenu
            sx={{ width: 48 }} // offset the padding so it looks like a circle correctly
            options={[
              {
                label: 'Delete contract',
                onClick: () => setIsModalOpen(true),
              },
            ]}
          />
        )}
      </Box>
      {isModalOpen && (
        <Modal
          handleClose={() => setIsModalOpen(false)}
          title={`Are you sure you want to delete the CRO contract for ${contractContainer?.vendor_name}?`}
          ButtonProps={{
            label: 'Delete Contract',
            testId: 'DeleteCROContractContainerModal',
            onClick: () => void handleContainerDelete(),
            sx: { backgroundColor: 'error.main' },
            loading: isDeletingContractContainer,
          }}
          isOpen
        >
          <Typography>
            {`This will delete all contract versions, files, budgets, and any
            expenses that were being used in the trial's accrual and forecasting
            calculations. This action cannot be undone.`}
          </Typography>
        </Modal>
      )}
    </Box>
  );
}

export default CroContractTabs;
