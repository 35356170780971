import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';
import useEditCondorUserStatus from 'shared/components/user-permissions/hooks/useEditCondorUserStatus';

import type { UserManagementGridPopupData } from 'shared/lib/types';

export default function usePartnerUserGridOptions(
  isOpenPeriod: boolean,
  setShowPopup: (showPopup: UserManagementGridPopupData) => void,
): CondorGridOptions {
  const [onCellValueChanged] = useEditCondorUserStatus(setShowPopup);

  return useMemo(
    () => ({
      groupDisplayType: 'groupRows',
      getRowStyle: 'highlightRow',
      suppressAggFuncInHeader: true,
      ...(isOpenPeriod && { onCellValueChanged }),
    }),
    [isOpenPeriod, onCellValueChanged],
  );
}
