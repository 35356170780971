import Typography from '@mui/material/Typography';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import IconButton from 'shared/ui/icon-button/IconButton';
import { EM_DASH_PLACEHOLDER } from 'shared/components/ag-grid/helpers/shared';

import type { UploadStatusItem } from 'shared/lib/types';

import LinkAndRowsColumn from './LinkAndRowsColumn';
import UploadDateAndUserColumn from './UploadDateAndUserColumn';

type Props = {
  primaryText: string;
  uploadStatus?: UploadStatusItem;
  onUpload?: () => void;
};

function TrialUploadsListItem(props: Props) {
  const { primaryText, onUpload, uploadStatus } = props;

  return (
    <Box
      component="li"
      sx={{
        alignItems: 'center',
        display: 'flex',
        px: 2,
        py: 1.5,
        borderRadius: 2,
        '&:nth-of-type(even)': {
          backgroundColor: ({ palette }) => palette.secondary.light,
        },
      }}
    >
      <Box flex={2}>
        <Typography variant="subtitle2">{primaryText}</Typography>
        {!!uploadStatus && <LinkAndRowsColumn files={uploadStatus.files} />}
        {!uploadStatus?.files.length && (
          <Typography variant="caption">{EM_DASH_PLACEHOLDER}</Typography>
        )}
      </Box>
      <Box display="flex" flex={1}>
        <Box
          alignItems="center"
          display="flex"
          padding="3px 6px"
          bgcolor={
            uploadStatus?.files.length ? 'success.light' : 'warning.light'
          }
        >
          {!uploadStatus?.files.length ? (
            <>
              <WarningAmberOutlinedIcon sx={{ fontSize: '12px' }} />
              <Typography ml={1} variant="caption">
                Not uploaded
              </Typography>
            </>
          ) : (
            <>
              <CheckCircleIcon sx={{ fontSize: '12px' }} />
              <Typography ml={1} variant="caption">
                Uploaded
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <Box flex={1}>
        {!!uploadStatus?.files.length && (
          <UploadDateAndUserColumn files={uploadStatus.files} />
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Tooltip placement="bottom" title="Upload" arrow>
          <span>
            <IconButton onClick={onUpload}>
              <UploadIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default TrialUploadsListItem;
