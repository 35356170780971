import groupBy from 'lodash/groupBy';

import { getNumberCellConfig } from 'shared/components/ag-grid-cells/config';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import type {
  AdministrativeOrProcedureCategoryResponse,
  PatientAssessmentResponse,
  PatientCohortResponse,
} from 'shared/lib/types';

const PATIENT_ID_WIDTH = 150;
const SITE_NUMBER_WIDTH = 150;
const PROCEDURE_COUNT_WIDTH = 110;
const COUNT_WIDTH = 100;
const NAME_WIDTH = 250;
const STATUS_WIDTH = 100;

const getPatientJourneyColumnDefs = (
  procedureCategories: AdministrativeOrProcedureCategoryResponse[] | undefined,
  patientCohorts: PatientCohortResponse[] | undefined,
  cohortTraceId?: string | undefined,
): CondorColGroupDef[] => [
  {
    headerName: '',
    children: [
      {
        headerName: 'Patient ID',
        field: 'patient_subject_id',
        width: PATIENT_ID_WIDTH,
        cellRenderer: 'AgGridGroupCellRenderer',
        pinned: 'left',
        filter: true,
      },
      {
        headerName: 'Patient Journey ID',
        field: 'patient_journey_id',
        width: PATIENT_ID_WIDTH,
        cellRenderer: 'AgGridGroupCellRenderer',
        pinned: 'left',
        hide: true,
      },
    ],
  },
  {
    headerName: 'PATIENT DETAILS',
    children: [
      {
        headerName: 'Site #',
        field: 'patient_site_no',
        width: SITE_NUMBER_WIDTH,
        filter: true,
      },
      {
        headerName: 'Site name',
        field: 'patient_site_name',
        width: NAME_WIDTH,
      },
      {
        headerName: 'Status',
        field: 'patient_status',
        width: STATUS_WIDTH,
        filter: true,
      },
    ],
  },
  ...Object.entries(
    groupBy(
      [...(patientCohorts ?? [])].sort((cohortA, cohortB) =>
        cohortA.name.localeCompare(cohortB.name),
      ),
      (cohort) => cohort.pizza_name ?? cohort.name,
    ),
  ).map((cohortGrouping) => ({
    headerName: 'VISIT SCHEDULE',
    children: Object.entries(
      groupBy(
        cohortGrouping[1].flatMap((cohort) =>
          cohort.trace_id === cohortTraceId ? cohort.patient_assessments : [],
        ),
        (visit) => visit.trace_id,
      ),
    )
      .map((visitGrouping) => {
        visitGrouping[1].sort(
          (visitA, visitB) => visitA.order_index - visitB.order_index,
        );
        return visitGrouping;
      })
      .sort(
        (visitGroupingA, visitGroupingB) =>
          getVisitSortIndex(visitGroupingA) - getVisitSortIndex(visitGroupingB),
      )
      .map<CondorColDef>((visitGrouping) => ({
        headerName: pizzaJoinForPatientAssessment(visitGrouping),
        field: visitGrouping[0],
        cellStyle: 'patientJourneyExpectedVisitV2',
        cellRenderer: 'AgGridCustomCellRenderer',
        cellRendererParams: {
          formatter: 'multiple_visits' as const,
          useEmDash: true,
        },
        width: COUNT_WIDTH,
        aggFunc: 'sum' as const,
      })),
  })),
  {
    headerName: '',
    children: [
      {
        headerName: 'Total visits',
        field: 'visits_total',
        ...getNumberCellConfig({ useEmDash: true }),
        width: COUNT_WIDTH,
        aggFunc: 'sum',
      },
    ],
  },
  {
    headerName: 'PROCEDURES',
    children: [
      ...Object.entries(
        groupBy(procedureCategories, (category) => category.trace_id),
      )
        .map((categoryGrouping) => {
          categoryGrouping[1].sort((visitA, visitB) =>
            visitA.name.localeCompare(visitB.name),
          );
          return categoryGrouping;
        })
        .sort((categoryGroupingA, categoryGroupingB) =>
          categoryGroupingA[0].localeCompare(categoryGroupingB[0]),
        )
        .map((categoryGrouping) => ({
          headerName: pizzaJoinForAdminOrProcedureCategory(categoryGrouping),
          field: categoryGrouping[0],
          cellRenderer: 'AgGridCustomCellRenderer' as const,
          cellRendererParams: {
            formatter: 'multiple_visits' as const,
            useEmDash: true,
          },
          width: PROCEDURE_COUNT_WIDTH,
          aggFunc: 'sum' as const,
        })),
      {
        headerName: 'Total procedures',
        field: 'procedures_total',
        ...getNumberCellConfig({ useEmDash: true }),
        width: COUNT_WIDTH,
        aggFunc: 'sum',
      },
    ],
  },
];

const pizzaJoinForPatientAssessment = (
  objects: [string, PatientAssessmentResponse[]],
) =>
  `${objects[1].map((object) => object.name).join(',')} (${
    objects[1].find((object) => object.trace_id === objects[0])?.pizza_name
  })`;

const pizzaJoinForAdminOrProcedureCategory = (
  objects: [string, AdministrativeOrProcedureCategoryResponse[]],
) =>
  `${objects[1].map((object) => object.name).join(',')} (${
    objects[1].find((object) => object.trace_id === objects[0])?.mapped_edc_name
  })`;

const getVisitSortIndex = (
  visitGrouping: [string, PatientAssessmentResponse[]],
) => visitGrouping[1].slice(-1)[0].order_index; // * Since we already sorted the visits within the grouping, we get the max by looking at the final element of the array

export default getPatientJourneyColumnDefs;
