import type { HTMLAttributes } from 'react';

import { formHelperTextClasses } from '@mui/material/FormHelperText';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { useDispatch, useSelector } from 'react-redux';

import Autocomplete from 'shared/ui/autocomplete/Autocomplete';

import type { DropdownOption, PatientAssessmentEditor } from 'shared/lib/types';
import {
  changeEdcVisitMapping,
  selectVisitErrors,
} from 'shared/state/slices/visitSchedulesSlice';

import EdcMappingOption from './EdcMappingOption';

type Props = {
  options: Array<DropdownOption<string>>;
  visit: PatientAssessmentEditor;
};

function EdcMappingSelect(props: Props) {
  const { options, visit } = props;
  const visitErrors = useSelector(
    selectVisitErrors(visit, new Set(options.map((option) => option.value))),
  );
  const dispatch = useDispatch();
  function renderOption(
    renderProps: HTMLAttributes<HTMLLIElement>,
    option: DropdownOption<string | undefined>,
  ) {
    return (
      <EdcMappingOption
        key={option.value}
        option={option}
        renderProps={renderProps}
      />
    );
  }

  const getSelectedEdcVisitName = (mappedName: string | undefined) => {
    const isMappedName = (option: DropdownOption<string>) =>
      option.value === mappedName;
    return options.find(isMappedName) ?? null;
  };

  return (
    <Autocomplete
      errorMsg={visitErrors}
      label="EDC visit name"
      options={options}
      renderOption={renderOption}
      value={getSelectedEdcVisitName(visit.mappedName)}
      sx={{
        backgroundColor: (theme) =>
          visit.isMagicMapped ? theme.palette.success.light : undefined,
        [`.${outlinedInputClasses.error}`]: {
          [`.${outlinedInputClasses.notchedOutline}`]: {
            borderColor: (theme) => theme.palette.grey[500],
          },
        },
        [`.${inputLabelClasses.root}.${inputLabelClasses.error}`]: {
          color: (theme) => theme.palette.grey[700],
        },
        [`.${formHelperTextClasses.root}.${formHelperTextClasses.error}`]: {
          color: (theme) => theme.palette.grey[700],
        },
      }}
      onChange={(_, option) =>
        dispatch(changeEdcVisitMapping({ visit, value: option?.value }))
      }
    />
  );
}

export default EdcMappingSelect;
