import type { ChangeEvent, SyntheticEvent } from 'react';

import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns/format';
import { lastDayOfMonth } from 'date-fns/lastDayOfMonth';
import { useSelector } from 'react-redux';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import Autocomplete from 'shared/ui/autocomplete/Autocomplete';

import type {
  BackendContractStatus,
  BackendContractVersion,
  ContractContainerResponse,
  ContractInfo,
  DropdownOption,
} from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetRegionsWithFiltersQuery } from 'shared/api/rtkq/trialregions';

import type { ContractVersionErrors } from './types';

import styles from './ContractVersionWizard.module.scss';

type Props = {
  contractContainer?: ContractContainerResponse;
  contractInfo: ContractInfo;
  fieldErrors: ContractVersionErrors;
  isEdit: boolean;
  isForAip: boolean;
  poNumberIsUsedInAnotherTrial: boolean;
  setContractInfo: (contractInfo: ContractInfo) => void;
  showVoidedDate?: boolean;
};

function ContractInfoForm(props: Props) {
  const {
    contractInfo,
    setContractInfo,
    fieldErrors,
    contractContainer,
    poNumberIsUsedInAnotherTrial,
    isForAip = false,
    showVoidedDate = false,
    isEdit = false,
  } = props;
  const vendorType = contractContainer?.vendor_type;
  const trial = useSelector(selectTrial);
  const { currentData: regions } = useGetRegionsWithFiltersQuery({
    trial: trial.trace_id,
    is_hidden: 'False',
  });

  const CONTRACT_VERSIONS: Array<DropdownOption<BackendContractVersion>> = [
    ...(vendorType === 'CRO'
      ? [{ value: 'LOI' as BackendContractVersion, label: 'LOI' }]
      : []),
    { value: 'OG_WORK_ORDER', label: 'Original Work Order' },
    { value: 'AMENDMENT', label: 'Amendment' },
  ];
  const CONTRACT_STATUSES: Array<DropdownOption<BackendContractStatus>> = [
    { value: 'CURRENT', label: 'Current contract' },
    { value: 'SUPERSEDED', label: 'Superseded' },
    { value: 'VOIDED', label: 'Voided' },
  ];

  const selectedContractVersion =
    CONTRACT_VERSIONS.find(
      (versionOption) => versionOption.value === contractInfo.contractVersion,
    ) ?? null;

  function handleContractVersionChange(
    _event: SyntheticEvent,
    value: DropdownOption<BackendContractVersion> | null,
  ) {
    const selectedVersion = value?.value ?? '';
    setContractInfo({
      ...contractInfo,
      contractVersion: selectedVersion as BackendContractVersion,
      amendmentNumber: '',
    });
  }

  function handleVersionNameChange(event: ChangeEvent<HTMLInputElement>) {
    setContractInfo({ ...contractInfo, versionName: event.target.value });
  }

  const selectedContractStatus = CONTRACT_STATUSES.find(
    (statusOption) => statusOption.value === contractInfo.contractStatus,
  );

  function handleContractStatusChange(
    event: SyntheticEvent,
    value: DropdownOption<BackendContractStatus> | null,
  ) {
    event.preventDefault();
    if (value) {
      setContractInfo({ ...contractInfo, contractStatus: value.value });
    } else {
      throw new Error(
        'Contract must have a Status. No value selected in dropdown.',
      );
    }
  }
  const po_number_options =
    contractContainer?.po_numbers.map((po_number) => ({
      value: po_number,
      label: po_number,
    })) ?? [];

  if (contractInfo.poNumber) {
    po_number_options.push({
      value: contractInfo.poNumber,
      label: contractInfo.poNumber,
    });
  }

  const selectedPoNumber =
    po_number_options.find(
      (po_number) => po_number.value === contractInfo.poNumber,
    ) ?? null;

  function handleAmendmentNumberChange(event: ChangeEvent<HTMLInputElement>) {
    setContractInfo({ ...contractInfo, amendmentNumber: event.target.value });
  }

  const selectedRegions =
    regions?.filter(({ trace_id }) =>
      contractInfo.regions?.includes(trace_id),
    ) ?? [];

  function handleContractRegionChange(
    _event: SyntheticEvent,
    updatedRegions: Array<DropdownOption<string>>,
  ) {
    setContractInfo({
      ...contractInfo,
      regions: updatedRegions.map(({ value }) => value),
    });
  }

  return (
    <>
      <div className={styles.formRow}>
        <div className={`${styles.formLabel} ${styles.required}`}>Version</div>
        <div className={styles.rightPanel}>
          <FormControl className={styles.formInput}>
            <Autocomplete
              errorMsg={fieldErrors.version}
              label="Version"
              options={CONTRACT_VERSIONS}
              value={selectedContractVersion}
              onChange={handleContractVersionChange}
            />
          </FormControl>
          {contractInfo.contractVersion === 'AMENDMENT' && (
            <>
              <Typography variant="body1">
                If this is an amendment, specify what amendment number it is.
              </Typography>
              <FormControl className={styles.inputField}>
                <CondorTextField
                  errors={fieldErrors.amendment_number}
                  label="Amendment #"
                  size="small"
                  value={contractInfo.amendmentNumber}
                  onChange={handleAmendmentNumberChange}
                />
              </FormControl>
            </>
          )}
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.formLabel}>Version name</div>
        <div className={styles.rightPanel}>
          <FormControl className={styles.formInput}>
            <CondorTextField
              errors={fieldErrors.version_name}
              label="Add a name"
              size="small"
              value={contractInfo.versionName}
              onChange={handleVersionNameChange}
            />
          </FormControl>
        </div>
      </div>
      {vendorType === 'OCC' ? (
        <div className={styles.formRow}>
          <div className={`${styles.formLabel} ${styles.required}`}>
            Version status
          </div>
          <div className={styles.rightPanel}>
            <FormControl className={styles.formInput}>
              <Autocomplete
                errorMsg={fieldErrors.status_for_period}
                label="Status"
                options={CONTRACT_STATUSES}
                value={selectedContractStatus}
                disableClearable
                onChange={handleContractStatusChange}
              />
            </FormControl>
          </div>
        </div>
      ) : null}
      <div className={styles.formRow}>
        <div className={String(styles.formLabel)}>
          <div className={styles.required}>Contract Dates</div>
          <div
            className={`${styles.formLabelDescription} ${fieldErrors.effective_date || fieldErrors.aip_effective_date ? styles.error : ''}`}
          >
            At least one of these dates is required.
          </div>
        </div>
        <div className={styles.rightPanel}>
          {(isForAip || (vendorType === 'CRO' && isEdit)) && (
            <>
              <div className={String(styles.formLabel)}>
                <div>AIP Effective Month</div>
                <div className={String(styles.formLabelDescription)}>
                  The month this contract version did or will become the
                  amendment-in-progress.
                </div>
              </div>
              <MonthYearDateInput
                value={
                  contractInfo.aipEffectiveDate
                    ? new Date(contractInfo.aipEffectiveDate)
                    : null
                }
                onChange={(value) => {
                  setContractInfo({
                    ...contractInfo,
                    aipEffectiveDate:
                      value && value.toString() !== 'Invalid Date'
                        ? format(lastDayOfMonth(value), 'yyyy-MM-dd')
                        : undefined,
                  });
                }}
              />
            </>
          )}
          {(!isForAip || isEdit) && (
            <>
              <div className={String(styles.formLabel)}>
                <div>Effective Month</div>
                <div className={String(styles.formLabelDescription)}>
                  The month this contract version did or will become the current
                  (executed) contract.
                </div>
              </div>
              <MonthYearDateInput
                value={
                  contractInfo.effectiveDate
                    ? new Date(contractInfo.effectiveDate)
                    : null
                }
                onChange={(value) => {
                  setContractInfo({
                    ...contractInfo,
                    effectiveDate:
                      value && value.toString() !== 'Invalid Date'
                        ? format(lastDayOfMonth(value), 'yyyy-MM-dd')
                        : undefined,
                  });
                }}
              />
            </>
          )}
        </div>
      </div>

      {(showVoidedDate || isEdit) && (
        <MonthYearDateInput
          error={fieldErrors.voided_date}
          label="Void Month"
          value={
            contractInfo.voidedDate ? new Date(contractInfo.voidedDate) : null
          }
          onChange={(value) => {
            setContractInfo({
              ...contractInfo,
              voidedDate:
                value && value.toString() !== 'Invalid Date'
                  ? format(lastDayOfMonth(value), 'yyyy-MM-dd')
                  : undefined,
            });
          }}
        />
      )}
      <div className={styles.formRow}>
        <div className={`${styles.formLabel} ${styles.required}`}>
          PO Number
        </div>
        <div className={styles.rightPanel}>
          <FormControl className={styles.formInput}>
            <Autocomplete
              errorMsg={fieldErrors.po_number}
              label="PO Number"
              options={po_number_options}
              value={selectedPoNumber}
              autoSelect
              freeSolo
              onChange={(_e, value) =>
                setContractInfo({
                  ...contractInfo,
                  poNumber: typeof value === 'object' ? value?.value : value,
                })
              }
            />
            {!!contractInfo.poNumber &&
              !contractContainer?.po_numbers.includes(
                contractInfo.poNumber,
              ) && (
                <div
                  style={{
                    fontSize: 11,
                    color: 'grey.700',
                    marginLeft: 3,
                    marginTop: 3,
                  }}
                >
                  This PO # was not in your PO listing. Please make sure to
                  review your PO listing to make sure it’s correct.
                </div>
              )}
            {poNumberIsUsedInAnotherTrial && (
              <div
                style={{
                  fontSize: 11,
                  color: 'grey.700',
                  marginLeft: 3,
                  marginTop: 3,
                }}
              >
                This PO # is being used in another trial. Please make sure the
                PO #s are correct in both trials.
              </div>
            )}
          </FormControl>
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={String(styles.formLabel)}>
          <div className={styles.required}>Regions</div>
          <div
            className={`${styles.formLabelDescription} ${fieldErrors.regions ? styles.error : ''}`}
          >
            Select at least one region.
          </div>
        </div>
        <div className={styles.rightPanel}>
          <FormControl className={styles.formInput}>
            <Autocomplete
              label=""
              placeholder={selectedRegions.length === 0 ? 'Select regions' : ''}
              options={
                regions?.map(({ name, trace_id }) => ({
                  value: trace_id,
                  label: name,
                })) ?? []
              }
              value={selectedRegions.map(({ name, trace_id }) => ({
                value: trace_id,
                label: name,
              }))}
              multiple
              onChange={handleContractRegionChange}
            />
          </FormControl>
        </div>
      </div>
    </>
  );
}

function MonthYearDateInput(props: {
  error?: string | undefined;
  isRequired?: boolean;
  label?: string;
  value: Date | null;
  onChange: (value: Date | null) => void;
}) {
  const { value, error, onChange, label, isRequired } = props;
  const formInput = (
    <FormControl className={styles.formInput}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Month / Year"
          openTo="month"
          value={value}
          views={['month', 'year']}
          slotProps={{
            textField: { error: !!error, helperText: error },
          }}
          onChange={onChange}
        />
      </LocalizationProvider>
    </FormControl>
  );

  if (!label) {
    return formInput;
  }
  return (
    <div className={styles.formRow}>
      <div
        className={`${styles.formLabel} ${isRequired ? styles.required : ''}`}
      >
        {label}
      </div>
      <div className={styles.rightPanel}>{formInput}</div>
    </div>
  );
}

export default ContractInfoForm;
